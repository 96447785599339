import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ImageUpload from "../../../components/ImageUpload/ImageUpload";
import {
  handlePreviewFileInLocal,
  handleUploadImgFile,
  startApiCall,
} from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { airplaneIconRelatedEndPoint } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ToasterMessages from "../../../Global/ToasterMessages";
import ApiLoader from "../../../components/Loader/ApiLoader";
import DataLoader from "../../../components/Loader/DataLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { AuthContext } from "../../../contextApi/context";

const AddAirlineIcon = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [airlineCode, setAirlineCode] = useState("");
  const [airlineName, setAirlineName] = useState("");
  const [airlineDetails, setAirlineDetails] = useState([]);
  const [image, setimage] = useState(null);

  const setFileForUpload = (event) => {
    setimage(event);
  };

  const location = useLocation();
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    setLocationData(location?.state);
  }, [location?.state]);

  const handleReset = () => {
    setLoader(false);
    setErrorMessage("");
    setAirlineCode("");
    setimage(null);
  };

  const handleAddAirlineIcon = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      airline_code: airlineCode.toUpperCase(),
      airline_name: airlineName.toUpperCase(),
      image: uploadImg,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      airplaneIconRelatedEndPoint.addAirplaneIcon,
      data
    );
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditAirlineIcon = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
    const data = {
      iconId: airlineDetails?._id,
      airline_code: airlineCode.toUpperCase(),
      airline_name: airlineName.toUpperCase(),
      // image: airlineIcon,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      airplaneIconRelatedEndPoint.editAirplaneIcon,
      data
    );
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  const handleGetAirlineDetails = async () => {
    startApiCall(null, setLoader2);
    const data = {
      iconId: locationData?.iconId,
    };
    const res = await ApiCall(
      "post",
      airplaneIconRelatedEndPoint.getAirplaneIconById,
      data
    );
    if (res?.success) {
      setAirlineDetails(res?.result);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  useEffect(() => {
    if (locationData?.iconId) {
      handleGetAirlineDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.iconId) {
      setAirlineCode(airlineDetails?.airline_code);
      setimage(airlineDetails?.image);
    }
  }, [locationData, airlineDetails]);

  return (
    <div className="container pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex align-items-center gap-3 mb-3">
            <Link to="/allairlineicons" className="btn btn-light border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </Link>
            <h4 className="text-uppercase mb-0">
              {locationData?.iconId ? "Edit" : "Add New"} Icon
            </h4>
          </div>
          {!loader2 && (
            <form
              onSubmit={(e) => {
                if (locationData?.iconId) {
                  handleEditAirlineIcon(e);
                } else {
                  handleAddAirlineIcon(e);
                }
              }}
            >
              <div className="form-group">
                <label>Airline Code</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={airlineCode}
                  onChange={(e) => setAirlineCode(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Airline Name</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={airlineName}
                  onChange={(e) => setAirlineName(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Image</label>
                <div className="image-upload-container m-auto">
                  {image ? (
                    <div className="image-container">
                      <img
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        src={handlePreviewFileInLocal(image)}
                        alt="Selected"
                        style={{ objectFit: "contain" }}
                      />
                      <button
                        className="remove-image-button"
                        onClick={() => setimage(null)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-lg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <input
                        className="image-upload-input pointer"
                        type="file"
                        name="imageUpload"
                        accept="image/png,image/jpeg"
                        onChange={setFileForUpload}
                      />
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="42"
                          height="42"
                          fill="currentColor"
                          className="bi bi-upload text-secondary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                        </svg>
                        <span className="image-upload-label">
                          Click to upload
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <hr className="mb-4" />
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger mr-3 px-5"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-success px-5">
                  {loader ? (
                    <ApiLoader />
                  ) : locationData?.iconId ? (
                    "Edit"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      {loader2 && <DataLoader />}
    </div>
  );
};

export default AddAirlineIcon;
