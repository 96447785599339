const PRIVATEROUTES = {
  ADD_STAFF: "Add Staff",
  ADMIN_LIST: "Admin List",
  MANAGER_LIST: "Manager List",
  EMPLOYEE_LIST: "Employee List",
  ADD_CUSTOMER: "Add Customer",
  CUSTOMER_LIST: "Customer List",
  ADD_OFFER: "Add OfferPage",
  OFFER_LIST: "OfferPage List",
  BLOG: "Blog",
  CATEGORY: "Category",
  TAGS: "Tag",
  PAYMENTS: "Payments",
  AIR_ICONS:"Airline Icons",
  UNASSIGN_INQUIRY:"Unassigned Inquiry",
  ASSIGN_INQUIRY: "Assigned Inquiry",
  CREATE_BOOKING: "Create Booking",
  UNASSIGN_BOOKING: "Unassigned Booking",
  ASSIGN_BOOKING: "Assigned Booking",
  ADD_ROUTE: "Add Route",
  ROUTE_LIST: "Route List",
  COMPOSE_MAIL: "Compose",
  OUTBOX:  "Outbox",
  INBOX: "Inbox",
  IP_ADDRESS: "IP Address",
  AIRLINE_ICONS: "Airline Icons",
  Logged_In_Profiles: "Logged In Profiles",
  Activity_Logs: "Activity Logs"
};

export default PRIVATEROUTES;
