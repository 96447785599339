import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { offerInquiryRelatedEndPoint } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";

const AssignedInquiry = () => {
  const [loader, setloader] = useState(false);
  const [allOfferInquiry, setAllOfferInquiry] = useState([]);

  const handleGetAllAssignedInquiry = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {},
    };
    const res = await ApiCall(
      "post",
      offerInquiryRelatedEndPoint.getAllOfferInquiry,
      data
    );
    if (res?.success) {
      setAllOfferInquiry(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllAssignedInquiry();
  }, []);

  return (
    <div className="container pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <h4 className="text-uppercase">Assigned inquiry</h4>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllAssignedInquiry();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>

          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </form>
      </div>
      {!loader && allOfferInquiry?.length > 0 && (
        <div className="d-flex flex-column justify-content-between">
          <div className="table-responsive scrollbar-hide pb-4" >
            <table className="table table-striped mb-0 text-center text-nowrap">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Assign to</th>
                  <th scope="col">Offer page</th>
                  <th scope="col">Name</th>
                </tr>
              </thead>
              <tbody>
                {allOfferInquiry?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1} .</td>
                      <td>{item?.assign_to}</td>
                      <td>{item?.offerpage_id}</td>
                      <td>{item?.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>
      )}
      {loader && <DataLoader />}
      {!loader && allOfferInquiry?.length === 0 && <NoData />}
    </div>
  );
};

export default AssignedInquiry;
