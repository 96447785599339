import React, { useContext, useState } from "react";
import { getCurrentLogo } from "../../Global/globalFunctions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { LOGOUT } from "../../contextApi/actions/auth.actions";
import { AuthContext } from "../../contextApi/context";
import ApiCall from "../../api/ApiCall";
import { creationEndpoint } from "../../api/Endpoints";
import ToasterMessages from "../../Global/ToasterMessages";

const Navbar = () => {
  const { authDispatch, authState } = useContext(AuthContext);
  const { user } = authState;
  const [isOpen, setIsOpen] = useState(false);
  const refreshToken = JSON.parse(
    localStorage.getItem("refreshTokenForOguleAdmin")
  );

  const handleLogout = async () => {
    const data = {
      token: refreshToken,
      isCustomer: false,
    };
    const customHeaders = {
      operation: "logout",
    };
    const res = await ApiCall(
      "post",
      creationEndpoint.userLogout,
      data,
      customHeaders
    );
    if (res?.success) {
      toast.success(ToasterMessages.LOGOUTMESSGAE);
      window.location.href = "/login";
      authDispatch({
        type: LOGOUT,
      });
    } else {
      authDispatch({
        type: LOGOUT,
      });
      toast.success(ToasterMessages.LOGOUTMESSGAE);
      window.location.href = "/login";
    }
  };

  const handleSwitchRoute = (selectedOption) => {
    localStorage.setItem("urltype", selectedOption);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshTokenForOguleAdmin");
    window.location.href = "/login";
  };

  return (
    <div
      className="sticky-header-top d-flex align-items-center justify-content-between px-3 py-2 border-bottom"
      style={{ height: "56px" }}
    >
      <img
        src={getCurrentLogo()}
        alt=""
        className="h-100"
        style={{
          width: "126px",
          objectFit: "contain",
          objectPosition: "center",
        }}
      />

      <div className="d-flex align-items-center gap-3">
        <div className="dropdown">
          <span
            className="dropdown-toggle pointer custom-after h5"
            id="switch_main"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {localStorage.getItem("urltype")}
          </span>
          <div className="dropdown-menu" aria-labelledby="switch_main">
            <button
              className={`dropdown-item pointer d-flex align-items-center ${
                localStorage.getItem("urltype") === "Ogule" ? "disabled" : ""
              }`}
              disabled={
                localStorage.getItem("urltype") === "Ogule" ? true : false
              }
              onClick={() => handleSwitchRoute("Ogule")}
            >
              {localStorage.getItem("urltype") === "Ogule" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#007bff"
                  class="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
              )}
              &nbsp;Ogule
            </button>
            <button
              className={`dropdown-item pointer d-flex align-items-center ${
                localStorage.getItem("urltype") === "Nilhans" ? "disabled" : ""
              }`}
              disabled={
                localStorage.getItem("urltype") === "Nilhans" ? true : false
              }
              onClick={() => handleSwitchRoute("Nilhans")}
            >
              {localStorage.getItem("urltype") === "Nilhans" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#32CD32"
                  class="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
              )}
              &nbsp;Nilhans
            </button>
            <button
              className={`dropdown-item pointer d-flex align-items-center ${
                localStorage.getItem("urltype") === "BestAirFares"
                  ? "disabled"
                  : ""
              }`}
              disabled={
                localStorage.getItem("urltype") === "BestAirFares"
                  ? true
                  : false
              }
              onClick={() => handleSwitchRoute("BestAirFares")}
            >
              {localStorage.getItem("urltype") === "BestAirFares" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#007bff"
                  class="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg>
              )}
              &nbsp;Best Air Fares
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end gap-2 float-right">
          <div className="form-inline my-2 my-lg-0 d-flex ms-auto gap-2">
            <div className="dropdown">
              <span
                className="nav-link dropdown-toggle pointer d-flex align-items-center"
                id="route"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div
                  style={{
                    height: "26px",
                    width: "26px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="objectFit-cover border bg-primary"
                >
                  {user?.image ? (
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                      src={user?.image}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    <h6 className="text-white mb-0">
                      {user?.name?.charAt(0)}
                    </h6>
                  )}
                </div>
              </span>
              <div
                className="dropdown-menu"
                aria-labelledby="route"
                style={{ left: "-102px", zIndex: "1001" }}
              >
                <Link
                  to="/profile/editprofile"
                  className="dropdown-item"
                  onClick={() => setIsOpen(false)}
                >
                  Profile
                </Link>
                <Link
                  to="/setting"
                  className="dropdown-item"
                  onClick={() => setIsOpen(false)}
                >
                  Settings
                </Link>
                <div class="dropdown-divider"></div>
                <span className="dropdown-item pointer" onClick={handleLogout}>
                  Log out
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
