import React from "react";
// import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

export const DateRange = (props) => {
  const { startDate, setstartDate, endDate, setendDate } = props;
  return (
    <div className="row gx-3 mt-3">
      <div className="col-sm-6">
        <div className="form-group">
          <label className="form-label">Start Date</label>
          <input
            className="form-control"
            name="single-date-pick"
            type="date"
            value={startDate}
            onChange={(e) => {
              setstartDate(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label className="form-label">End Date</label>
          <input
            className="form-control"
            name="single-date-pick"
            type="date"
            value={endDate}
            onChange={(e) => {
              setendDate(e.target.value);
            }}
            min={startDate}
          />
        </div>
      </div>
      {/* <div className="modal-footer align-items-center">
        <button type="button" className="btn btn-secondary">
          Close
        </button>
        <button
          type="submit"
          disabled={startDate === "" || endDate === ""}
          className="btn btn-primary"
        >
          Save
        </button>
      </div> */}
    </div>
  );
};
