import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { dateFormatter, startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { NilhansofferRelatedEndPoint, offerRelatedEndPoint } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import OtherApiCall from "../../../api/OtherApiCall";

const OfferPageList = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState("");
  const [allOffers, setAllOffers] = useState([]);
  const isNilhans = (localStorage.getItem("urltype") === "Nilhans" || localStorage.getItem("urltype") === "BestAirFares") ? NilhansofferRelatedEndPoint.getAllOffers : offerRelatedEndPoint.getAllOffers 

  const handleGetAllOffer = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {},
    };
    const res = await OtherApiCall("post", isNilhans, data);
    if (res) {
      setAllOffers(isNilhans ? res : res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllOffer();
  }, []);

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">All Offers</h4>
          <Link
            to="/offerpage/addofferpage"
            className="btn btn-outline-primary text-capitalize"
          >
            Add OfferPage
          </Link>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllOffer();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
        </form>
      </header>
      {!loader && allOffers?.length > 0 && (
        <div className="d-flex flex-column justify-content-between">
          <div className="table-responsive scrollbar-hide pb-4" >
            <table className="table table-striped mb-0 text-center text-nowrap">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Title</th>
                  <th scope="col">Slug</th>
                  <th scope="col">Offer Link</th>
                  <th scope="col">Status</th>
                  <th scope="col">Publish Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {allOffers?.map((offer, index) => {
                  return (
                    <tr>
                      <td>{index + 1} .</td>
                      <td className="text-capitalize">{offer?.name}</td>
                      <td>{offer?.slug}</td>
                      <td>{offer?.offer_link}</td>
                      <td>
                        {offer?.status === "publish" ? (
                          <span className="icon-padding text-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check2-all"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                            </svg>
                          </span>
                        ) : (
                          <span className="icon-padding">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-eye-slash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                              <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                            </svg>
                          </span>
                        )}
                      </td>
                      <td>{dateFormatter(offer?.publish_date)}</td>
                      <td>
                        <div className="dropdown">
                          <span
                            className="dropdown-toggle"
                            type="button"
                            id="action_dropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-three-dots"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                            </svg>
                          </span>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="action_dropdown"
                            style={{ minWidth: "0" }}
                          >
                            <span
                              className="dropdown-item pointer"
                              onClick={() => {
                                navigate("/offerpage/addofferpage", {
                                  state: { offerId: offer?._id },
                                });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                              &nbsp; Edit
                            </span>
                            {/* <span className="dropdown-item">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                              </svg>
                            </span>
                            <span className="dropdown-item">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-credit-card-2-back"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5z" />
                                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1m-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1" />
                              </svg>
                            </span> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>
      )}
      {loader && <DataLoader />}
      {!loader && allOffers?.length === 0 && <NoData />}
    </div>
  );
};

export default OfferPageList;
