import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import ToasterMessages from "../../../Global/ToasterMessages";
import { toast } from "react-toastify";
import {
  CategoryRelatedEndPoint,
  TagRelatedEndPoint,
} from "../../../api/Endpoints";
import ApiLoader from "../../../components/Loader/ApiLoader";
import DataLoader from "../../../components/Loader/DataLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { AuthContext } from "../../../contextApi/context";

const AddCategory = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [categoryLoader, setcategoryLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [tag, setTag] = useState("");

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setTitle("");
    setSlug("");
    setDescription("");
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      slug,
      actionBy: user?._id,
      name: title,
      parent_id: tag,
      description,
    };
    const res = await ApiCall(
      "post",
      CategoryRelatedEndPoint.addCategory,
      data
    );
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditCategory = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      categoryId: locationData?.catId,
      slug,
      actionBy: user?._id,
      name: title,
      parent_id: tag,
      description,
    };
    const res = await ApiCall(
      "post",
      CategoryRelatedEndPoint.editCategory,
      data
    );
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetCategoryDetails = async () => {
    startApiCall(null, setcategoryLoader);
    const data = {
      categoryId: locationData?.catId,
    };
    const res = await ApiCall(
      "post",
      CategoryRelatedEndPoint.getCategoryById,
      data
    );
    if (res?.success) {
      setCategoryDetails(res?.result);
      setcategoryLoader(false);
    } else {
      setcategoryLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.catId) {
      handleGetCategoryDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.catId) {
      setTitle(categoryDetails?.name);
      setSlug(categoryDetails?.slug);
      setDescription(categoryDetails?.description);
    }
  }, [locationData, categoryDetails]);

  const handleGetAllTags = async () => {
    const data = {
      filters: {},
    };
    const res = await ApiCall("post", TagRelatedEndPoint.getAllTags, data);
    if (res?.success) {
      setAllTags(res?.result);
    }
  };

  useEffect(() => {
    handleGetAllTags();
  }, []);

  return (
    <div className="container pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex align-items-center gap-3 mb-3">
            <Link to="/blog/categorylist" className="btn btn-light border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </Link>
            <h4 className="text-uppercase mb-0">
              {locationData?.catId ? "Edit" : "Add"} category
            </h4>
          </div>
          {!categoryLoader && (
            <form
              onSubmit={(e) => {
                if (locationData?.catId) {
                  handleEditCategory(e);
                } else {
                  handleAddCategory(e);
                }
              }}
            >
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Slug</label>
                <input
                  type="text"
                  className="form-control"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  rows="3"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div class="form-group">
                <label>Tags</label>
                <select
                  class="form-control"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                >
                  {allTags?.map((data) => {
                    return <option value={data?._id}>{data?.name}</option>;
                  })}
                </select>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <hr className="mb-4"/>
              <div className="d-flex justify-content-end">
                <button type="reset" className="btn btn-danger mr-3 px-5" onClick={handleReset}>
                  Reset
                </button>
                <button type="submit" className="btn btn-success px-5">
                  {loader ? (
                    <ApiLoader />
                  ) : locationData?.catId ? (
                    "Edit"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      {categoryLoader && <DataLoader />}
    </div>
  );
};

export default AddCategory;
