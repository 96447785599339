import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { creationEndpoint } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import { AuthContext } from "../../../contextApi/context";
import { handleGetCheckModulePermission } from "../../../contextApi/CheckmodulePermission";

const EmployeeList = () => {
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions } = authState;
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [allEmployee, setAllEmployee] = useState([]);
  const [search, setSearch] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [editStaff, setEditStaff] = useState("");
  const [isReadWrite, setIsReadWrite] = useState(false);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  useEffect(() => {
    const checkModulePermission = async () => {
      if (usermoduleswithpermissions?.length) {
        const permission = await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Employee List"
        );
        setIsReadWrite(permission);
      }
    };

    checkModulePermission();
  }, [usermoduleswithpermissions]);

  const handleReset = () => {
    setloader2(false);
    seterrorMessage("");
    setConfirmModal(false);
    setEditStaff("");
  };

  const handleGetAllStaff = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        role: "employee",
        searchKey: search,
        isDeleted: false,
      },
    };
    const res = await ApiCall("post", creationEndpoint.getAllUsers, data);
    if (res?.success) {
      setAllEmployee(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllStaff();
  }, [search, page, pageLimit]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleDeleteStaff = async () => {
    startApiCall(seterrorMessage, setloader2);
    const data = {
      staffId: editStaff?._id,
      isDeleted: true,
    };
    const res = await ApiCall("post", creationEndpoint.editUser, data);
    if (res?.success) {
      handleReset();
      handleGetAllStaff();
      toast.success("Delete employee successfully!");
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  const handleWhitelistStaff = async () => {
    startApiCall(seterrorMessage, setloader2);
    const data = {
      userId: editStaff?._id,
      isIpWhitelisted: editStaff?.isIpWhitelisted === false ? true : false,
    };
    const res = await ApiCall("post", creationEndpoint.whitelistUserIp, data);
    if (res?.success) {
      handleReset();
      handleGetAllStaff();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">Employee</h4>
          {user?.role !== "employee" && isReadWrite && (
            <span
            className="btn btn-outline-primary text-capitalize"
            onClick={() =>
              navigate("/staff/addstaff", {
                state: { type: "employee" },
              })
            }
            >
              Add employee
            </span>
          )}
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllStaff();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>

          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={search}
            onChange={handleSearch}
          />
        </form>
      </header>
      {!loader && allEmployee?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-5">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile No.</th>
                    <th scope="col">Email</th>
                    <th scope="col">Total Assigned Bookings</th>
                    <th scope="col">Is Active</th>
                    <th scope="col">Access by</th>
                    <th scope="col">Last Login</th>
                    <th scope="col">Last Logout</th>
                    {user?.role !== "employee" && isReadWrite && <th scope="col">Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {allEmployee?.map((employee, index) => {
                    return (
                      <tr key={employee?._id}>
                        <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                        <td>{employee?.name}</td>
                        <td>{employee?.mobile_no}</td>
                        <td>{employee?.email}</td>
                        <td>
                          {employee?.bookingCount !== 0 ? (
                            <div className="d-flex gap-2 justify-content-center">
                              <span className="font-3">
                                {employee?.bookingCount}
                              </span>
                              <span
                                className="text-primary pointer mt-1"
                                onClick={() =>
                                  navigate("/booking/assignedemployee", {
                                    state: {
                                      empId: employee?._id,
                                      module: "employee",
                                    },
                                  })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                  className="bi bi-info-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                </svg>
                              </span>
                            </div>
                          ) : (
                            "No Booking Assigned"
                          )}
                        </td>
                        <td>
                          {employee?.is_active === true ? (
                            <span className="icon-padding text-success">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check2-all"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                              </svg>
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {employee?.isIpWhitelisted === false ? "By ip" : "-"}
                        </td>
                        <td>
                          {employee?.loginTime
                            ? dateAndTimeFormatter(employee?.loginTime)
                            : "-"}
                        </td>
                        <td>
                          {employee?.logoutTime
                            ? dateAndTimeFormatter(employee?.logoutTime)
                            : "-"}
                        </td>
                        {user?.role !== "employee" && isReadWrite && (
                          <td>
                            <div className="dropdown">
                              <span
                                className="dropdown-toggle"
                                type="button"
                                id="action_dropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-three-dots"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                                </svg>
                              </span>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="action_dropdown"
                                style={{ minWidth: "0" }}
                              >
                                <span
                                  className="dropdown-item pointer"
                                  onClick={() =>
                                    navigate("/staff/addstaff", {
                                      state: { staffId: employee?._id },
                                    })
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                    />
                                  </svg>
                                  &nbsp; Edit
                                </span>
                                {(user?.role === "admin" ||
                                  user?.role === "sadmin") && (
                                  <span
                                    className="dropdown-item pointer"
                                    onClick={() => {
                                      setConfirmModal({
                                        show: true,
                                        type: "delete",
                                      });
                                      setEditStaff(employee);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="currentColor"
                                      className="bi bi-trash text-danger"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                    </svg>
                                    &nbsp; Delete
                                  </span>
                                )}
                                {user?.role === "sadmin" && (
                                  <span
                                    className="dropdown-item pointer"
                                    onClick={() => {
                                      setConfirmModal({
                                        show: true,
                                        type: "whitelist",
                                      });
                                      setEditStaff(employee);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="currentColor"
                                      class="bi bi-ban"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
                                    </svg>
                                    &nbsp;{" "}
                                    {employee?.isIpWhitelisted === false
                                      ? "Add to Whitelist"
                                      : "Remove from Whitelist"}
                                  </span>
                                )}
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allEmployee?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {confirmModal && (
        <ConfirmModal
          setConfirmModal={setConfirmModal}
          text={
            confirmModal?.type === "delete"
              ? "Are You Sure You Want to Delete this Employee ?"
              : `${
                  editStaff?.isIpWhitelisted === false
                    ? "Add this employee to whitelist"
                    : "Remove this employee from whitelist"
                } ?`
          }
          handleSubmit={
            confirmModal?.type === "delete"
              ? handleDeleteStaff
              : handleWhitelistStaff
          }
          loader={loader2}
          errorMessage={errorMessage}
          handleReset={handleReset}
        />
      )}
      {loader && <DataLoader />}
      {!loader && allEmployee?.length === 0 && <NoData />}
    </div>
  );
};

export default EmployeeList;
