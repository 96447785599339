import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BlogRelatedEndPoint,
  NilhansBlogRelatedEndPoint,
} from "../../../api/Endpoints";
import { dateFormatter, startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import Pagination from "../../../components/Pagination";

const BlogList = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const isNilhans =
    localStorage.getItem("urltype") === "Nilhans" ||
    localStorage.getItem("urltype") === "BestAirFares"
      ? NilhansBlogRelatedEndPoint.getAllBlogs
      : BlogRelatedEndPoint.getAllBlogs;

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllBlogs = async () => {
    startApiCall(null, setloader);
    const data = {
      // page: page,
      // limit: pageLimit,
      filters: {},
    };
    if (localStorage.getItem("urltype") === "BestAirFares") {
      data["page"] = page;
      data["limit"] = pageLimit;
    }
    const res = await ApiCall("post", isNilhans, data);
    if (res?.success) {
      setAllBlogs(
        localStorage.getItem("urltype") === "BestAirFares"
          ? res?.docs
          : res?.result
      );
      setcount(res?.total);
      settotalPages(res?.pages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllBlogs();
  }, [page, pageLimit]);

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">Blog</h4>
          <Link
            to="/blog/addblog"
            className="btn btn-outline-primary text-capitalize"
          >
            Add blog
          </Link>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllBlogs();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
        </form>
      </div>
      {!loader && allBlogs?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-6">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Title</th>
                    <th scope="col">Slug</th>
                    <th scope="col">Tags</th>
                    <th scope="col">Category</th>
                    <th scope="col">Status</th>
                    <th scope="col">Publish Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allBlogs?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1} .</td>
                        <td>{item?.name}</td>
                        <td>{item?.slug}</td>
                        <td>-</td>
                        <td>{item?.category_id?.name}</td>
                        <td>
                          {item?.status}
                          {/* <span className="icon-padding">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-check2-all"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                          <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg>
                      </span> */}
                        </td>
                        <td>{dateFormatter(item?.publish_date)}</td>
                        <td>
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              type="button"
                              id="action_dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </span>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action_dropdown"
                              style={{ minWidth: "0" }}
                            >
                              <span
                                className="dropdown-item"
                                onClick={() => {
                                  navigate("/blog/addblog", {
                                    state: { blogId: item?._id },
                                  });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                              </span>
                              {/* <span className="dropdown-item">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                          </span> */}
                              {/* <span className="dropdown-item">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-credit-card-2-back"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5z" />
                              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1m-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1" />
                            </svg>
                          </span> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {localStorage.getItem("urltype") === "BestAirFares" && (
            <div
              className="fixed-bottom p-2 bg-white border-top"
              style={{
                zIndex: 1,
              }}
            >
              <Pagination
                setpageLimit={setpageLimit}
                pageLimit={pageLimit}
                setPage={setPage}
                page={page}
                totalPages={totalPages}
                count={count}
                dataLength={allBlogs?.length}
                align={"end"}
              />
            </div>
          )}
        </>
      )}
      {loader && <DataLoader />}
      {!loader && allBlogs?.length === 0 && <NoData />}
    </div>
  );
};

export default BlogList;
