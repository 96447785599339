import React from "react";
import EmployeeList from "../../pages/Staff/EmployeeList/EmployeeList";

const EmployeeRoutes = {
  path: "/staff",
  children: [
    {
      path: "employeelist",
      element: <EmployeeList />,
    },
  ],
};

export default EmployeeRoutes;
