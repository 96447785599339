import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  handlePreviewFileInLocal,
  handleUploadImgFile,
  reverseDateFormatter,
  startApiCall,
} from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  NilhansofferRelatedEndPoint,
  offerRelatedEndPoint,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ToasterMessages from "../../../Global/ToasterMessages";
import ApiLoader from "../../../components/Loader/ApiLoader";
import DataLoader from "../../../components/Loader/DataLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { AuthContext } from "../../../contextApi/context";

const AddOfferPage = () => {
  const currentDate = new Date();
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [offerLoader, setofferLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [offerLink, setOfferLink] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("publish");
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [offerDetails, setOfferDetails] = useState([]);
  const [image, setimage] = useState(null);
  const isAddOffer =
    localStorage.getItem("urltype") === "Nilhans" ||
    localStorage.getItem("urltype") === "BestAirFares"
      ? NilhansofferRelatedEndPoint.addOffer
      : offerRelatedEndPoint.addOffer;
  const isEditOffer =
    localStorage.getItem("urltype") === "Nilhans" ||
    localStorage.getItem("urltype") === "BestAirFares"
      ? NilhansofferRelatedEndPoint.editOffer
      : offerRelatedEndPoint.editOffer;
  const isOfferDetails =
    localStorage.getItem("urltype") === "Nilhans" ||
    localStorage.getItem("urltype") === "BestAirFares"
      ? NilhansofferRelatedEndPoint.getOfferById
      : offerRelatedEndPoint.getOfferById;

  const setFileForUpload = (event) => {
    setimage(event);
  };

  const handlePreviewFileInLocalEdit = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const img = URL.createObjectURL(event.target.files[0]);
      return img;
    } else if (offerDetails?.image) {
      // Return the URL of the existing image if no new file is selected
      return offerDetails?.image;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setTitle("");
    setSlug("");
    setOfferLink("");
    setDescription("");
    setDate("");
    setStatus("publish");
    setimage(null);
  };

  const handleAddOffer = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      slug,
      actionBy: user?._id,
      name: title,
      short_description: description,
      offer_link: offerLink,
      publish_date: date,
      status,
      image: uploadImg,
    };
    const res = await ApiCall("post", isAddOffer, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditOffer = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      offerId: locationData?.offerId,
      slug,
      actionBy: user?._id,
      name: title,
      description,
      offer_link: offerLink,
      publish_date: date,
      status,
      image: uploadImg,
    };
    const res = await ApiCall("post", isEditOffer, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetOfferDetails = async () => {
    startApiCall(null, setofferLoader);
    const data = {
      offerId: locationData?.offerId,
    };
    const res = await ApiCall("post", isOfferDetails, data);
    if (res?.success) {
      setOfferDetails(res?.result);
      setofferLoader(false);
    } else {
      setofferLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.offerId) {
      handleGetOfferDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.offerId) {
      setTitle(offerDetails?.name);
      setSlug(offerDetails?.slug);
      setOfferLink(offerDetails?.offer_link);
      setDescription(offerDetails?.short_description);
      setDate(reverseDateFormatter(offerDetails?.publish_date));
      setStatus(offerDetails?.status);
      setimage(offerDetails?.image ? offerDetails?.image : "");
    }
  }, [locationData, offerDetails]);

  return (
    <div className="container pt-3 pb-4">
      <div className="d-flex align-items-center gap-3 mb-3">
        <Link to="/offerpage/offerpagelist" className="btn btn-light border">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-bar-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
            />
          </svg>
        </Link>
        <h4 className="text-uppercase mb-0">
          {locationData?.offerId ? "Edit" : "Add"} offerpage
        </h4>
      </div>
      {!offerLoader && (
        <form
          onSubmit={(e) => {
            if (locationData?.offerId) {
              handleEditOffer(e);
            } else {
              handleAddOffer(e);
            }
          }}
        >
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Slug</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Offer link</label>
                <input
                  type="url"
                  pattern="https://.*"
                  title="Please enter a valid link."
                  className="form-control"
                  placeholder="Enter Offer Link"
                  value={offerLink}
                  onChange={(e) => setOfferLink(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Image</label>
                <div className="image-upload-container m-auto">
                  {image ? (
                    <div className="image-container">
                      <img
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        src={
                          locationData === ""
                            ? handlePreviewFileInLocal(image)
                            : handlePreviewFileInLocalEdit(image)
                        }
                        alt="Selected"
                        style={{ objectFit: "contain" }}
                      />
                      <button
                        className="remove-image-button"
                        onClick={() => setimage(null)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-lg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <input
                        className="image-upload-input pointer"
                        type="file"
                        name="imageUpload"
                        accept="image/png,image/jpeg"
                        onChange={setFileForUpload}
                      />
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="42"
                          height="42"
                          fill="currentColor"
                          className="bi bi-upload text-secondary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                        </svg>
                        <span className="image-upload-label">
                          Click to upload
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label>Publish date</label>
                <input
                  type="date"
                  className="form-control"
                  min={reverseDateFormatter(currentDate)}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Status</label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="publish"
                    checked={status === "publish"}
                    onChange={() => setStatus("publish")}
                  />
                  <label className="form-check-label" htmlFor="publish">
                    Publish
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="hidden"
                    checked={status === "hidden"}
                    onChange={() => setStatus("hidden")}
                  />
                  <label className="form-check-label" htmlFor="hidden">
                    Hidden
                  </label>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <hr className="mb-4" />

          <div className="d-flex justify-content-end">
            <button className="btn btn-danger mr-3 px-5" onClick={handleReset}>
              Reset
            </button>
            <button type="submit" className="btn btn-success px-5">
              {loader ? <ApiLoader /> : locationData?.offerId ? "Edit" : "Save"}
            </button>
          </div>
        </form>
      )}
      {offerLoader && <DataLoader />}
    </div>
  );
};

export default AddOfferPage;
