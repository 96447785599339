import React from "react";

const CustomChip = ({ selectedFilterType, handleResetFilters }) => {
  return (
    <>
      <span
        className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border rounded-5"
        style={{ backgroundColor: "#b5b3b3", color: "#ffff", }}
      >
        {selectedFilterType}
        <span className="initial-wrap w-30p">
          <svg
            onClick={() => {
              handleResetFilters(selectedFilterType, true);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#fff"
            className="bi bi-x-circle-fill mt-1"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </span>
      </span>
    </>
  );
};

export default CustomChip;
