import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { startApiCall } from "../../../../Global/globalFunctions";
import { MarkupRelatedEndPoint } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import DataLoader from "../../../../components/Loader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import { toast } from "react-toastify";

const Markup = () => {
  const navigate = useNavigate();
  const { routeid } = useParams();
  const [loader, setloader] = useState(false);
  const [allMarkup, setAllMarkup] = useState([]);
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleGetAllmarkupTools = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: { route_id: routeid },
    };
    const res = await ApiCall(
      "post",
      MarkupRelatedEndPoint.getAllMarkUpTools,
      data
    );
    if (res?.success) {
      setAllMarkup(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllmarkupTools();
  }, [page, pageLimit]);

  const handleStatusChange = async (markupId, status) => {
    const data = {
      markup_toolId: markupId,
      status,
    };
    const res = await ApiCall(
      "post",
      MarkupRelatedEndPoint.editMarkupTool,
      data
    );
    if (res?.success) {
      toast.success("Status change successfully!");
      handleGetAllmarkupTools();
    }
  };

  const downloadCSV = () => {
    let csvData = [];
    allMarkup?.map((data, index) => {
      csvData.push({
        ID: index + 1,
        "Way of Flight": data?.wayOfFlight
          ? data?.wayOfFlight
          : data?.wayOfflight,
        Cabins: data?.cabins ? data?.cabins : "-",
        "Inbound Class": data?.inbound_classes,
        "Outbound Class": data?.outbound_classes,
        Passengers: data?.passengers_count,
        Adults: data?.adults_count,
        Childs: data?.childs_count,
        Infants: data?.infants_count,
        "Total Amount": data?.total_amount,
        "Markup Type": data?.markup_type,
        "Markup Amount": data?.markup_amount,
        "Markup Amount Type": data?.markup_amount_type,
        "Start Active Date": data?.start_active_date,
        "End Active Date": data?.end_active_date,
        Status: data?.status === "enable" ? "Active" : "In Active",
      });
    });
    // Extract headers from the first object in the data array
    const headers = Object.keys(csvData[0]);
    // Convert header and data to CSV format
    const headerRow = headers.map((header) => `"${header}"`).join(",");
    const dataRows = csvData.map((obj) =>
      headers.map((header) => `"${obj[header]}"`).join(",")
    );
    const csvContent = `data:text/csv;charset=utf-8,${headerRow}\n${dataRows.join(
      "\n"
    )}`;
    // Create a data URI and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "markup.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <span
            className="btn btn-light border"
            onClick={() => navigate("/route/routelist")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
          </span>
          <h5 className="text-uppercase mb-0 ml-2 mr-2 headline-sm">
            All Markups Of Route (Origin : {locationData?.route?.origin},
            Destination : {locationData?.route?.destination}, Airlines :{" "}
            {locationData?.route?.airlines})
          </h5>
          <span
            onClick={() =>
              navigate(`/route/addmarkup/${routeid}`, {
                state: {
                  // userId: locationData?.userId,
                  route: locationData?.route,
                },
              })
            }
            className="btn btn-outline-primary text-capitalize"
          >
            Add markup
          </span>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllmarkupTools();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              fill="currentColor"
              className="bi bi-arrow-clockwise mt-1"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
          {!loader && allMarkup?.length > 0 && (
            <div className="d-flex justify-content-end mb-1 mt-1">
              <span
                className="btn btn-outline-secondary pointer"
                onClick={downloadCSV}
              >
                Export Csv
              </span>
            </div>
          )}
        </form>
      </header>
      {!loader && allMarkup?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-10">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-bordered table-striped mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Classes</th>
                    <th scope="col">Passengers</th>
                    <th scope="col">Markup Details</th>
                    <th scope="col">Active Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allMarkup?.map((data, index) => {
                    return (
                      <tr>
                        <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                        <td>
                          <p>In: {data?.inbound_classes}</p>
                          <p>Out: {data?.outbound_classes}</p>
                        </td>
                        <td>
                          <p>Value (1 PAX) : {data?.one_px}</p>
                          <p>Value (2 PAX) : {data?.two_px}</p>
                          <p>Value (3 PAX) : {data?.three_px}</p>
                          <p>Value (4 PAX) : {data?.four_px}</p>
                          <p>Value (5 PAX) : {data?.five_px}</p>
                          <p>Value (6 PAX) : {data?.six_px}</p>
                          <p>Value (7 PAX) : {data?.seven_px}</p>
                          <p>Value (8 PAX) : {data?.eight_px}</p>
                          <p>Value (9 PAX) : {data?.nine_px}</p>
                        </td>
                        <td className="text-capitalize">
                          <p>Type : {data?.markup_type}</p>
                          <p>Amount : {data?.markup_amount}</p>
                        </td>
                        <td>
                          From : {data?.start_active_date} To :{" "}
                          {data?.end_active_date}
                        </td>
                        <td>
                          {/* {data?.status === "enable" ? "Active" : "In Active"} */}
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle pointer p-1 text-dark px-3 ml-2"
                              type="button"
                              id="action_dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {data?.status === "enable"
                                ? "Active"
                                : "In Active"}
                            </span>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action_dropdown"
                              style={{ minWidth: "0" }}
                            >
                              <button
                                className="dropdown-item pointer"
                                disabled={data?.status === "enable"}
                                onClick={() =>
                                  handleStatusChange(data?._id, "enable")
                                }
                              >
                                Active
                              </button>
                              <button
                                className="dropdown-item pointer"
                                disabled={data?.status === "disable"}
                                onClick={() =>
                                  handleStatusChange(data?._id, "disable")
                                }
                              >
                                In Active
                              </button>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              type="button"
                              id="action_dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </span>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action_dropdown"
                              style={{ minWidth: "0" }}
                            >
                              <span
                                className="dropdown-item pointer"
                                accordion
                                onClick={() =>
                                  navigate(`/route/addmarkup/${routeid}`, {
                                    state: {
                                      markUpId: data?._id,
                                      route: locationData?.route,
                                    },
                                  })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                                &nbsp; Edit
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allMarkup?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {loader && <DataLoader />}
      {!loader && allMarkup?.length === 0 && <NoData />}
    </div>
  );
};

export default Markup;
