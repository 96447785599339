import React from "react";

const SwitchingRoute = ({ setSwitchingRoute }) => {

  const handleSwitchRoute = (selectedOption) => {
    localStorage.setItem("urltype", selectedOption);
    setSwitchingRoute(false);
    window.location.reload();
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:"540px"}}>
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
              <p class="text-capitalize mb-2 text-center font-weight-bold">Please choose the business type you want to log in with ?</p>
            <div className="row gx-3 px-2 mb-2 mt-3 d-flex justify-content-center">
              <div className="d-flex align-items-center justify-content-around w-100">
                <div class="d-flex align-items-center gap-2 fs-6 h5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="Ogule"
                    checked={localStorage.getItem("urltype") === "Ogule"}
                    onClick={() => {
                      handleSwitchRoute("Ogule");
                    }}
                  />

                  <label
                    className="form-check-label mt-1"
                    htmlFor="inlineRadio1"
                  >
                    Ogule
                  </label>
                </div>
                <div class="d-flex align-items-center gap-2 fs-6 h5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="Nilhans"
                    checked={localStorage.getItem("urltype") === "Nilhans"}
                    onClick={() => {
                      handleSwitchRoute("Nilhans");
                    }}
                  />

                  <label
                    className="form-check-label mt-1"
                    htmlFor="inlineRadio2"
                  >
                    Nilhans
                  </label>
                </div>
                <div class="d-flex align-items-center gap-2 fs-6 h5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="Nilhans"
                    checked={localStorage.getItem("urltype") === "BestAirFares"}
                    onClick={() => {
                      handleSwitchRoute("BestAirFares");
                    }}
                  />

                  <label
                    className="form-check-label mt-1"
                    htmlFor="inlineRadio2"
                  >
                    Best Air Fares
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer align-items-center mt-3 border-0 pt-0">
              <button
                type="submit"
                className="btn btn-primary btn-md px-4"
                onClick={() => setSwitchingRoute(false)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchingRoute;
