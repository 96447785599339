import { useRoutes } from "react-router-dom";
import HomeRoutes from "./Home/HomeRoutes";
import StaffRoutes from "./Staff/StaffRoutes";
import CustomerRoutes from "./Customer/CustomerRoutes";
import OfferPageRoutes from "./OfferPage/OfferPageRoutes";
import BlogRoutes from "./Blog/BlogRoutes";
import SearchRecordRoutes from "./SearchRecord/SearchRecord";
import PaymentRoutes from "./Payments/PaymentsRoutes";
import MailboxRoutes from "./Mailbox/MailboxRoutes";
import AirlineIconsRoutes from "./AirlineIcons/AirlineIconsRoutes";
import RouteRoutes from "./Route/RouteRoutes";
import SettingsRoutes from "./Settings/SettingsRoutes";
import ChangePasswordRoutes from "./Profile/ChangePassword";
import AdminRoutes from "./Staff/AdminList";
import EmployeeRoutes from "./Staff/EmployeeList";
import ManagerRoutes from "./Staff/ManagerList";
import AddCustomerRoutes from "./Customer/AddCustomer";
import AddOfferPageRoutes from "./OfferPage/AddOfferPage";
import CategoryRoutes from "./Blog/CategoryRoutes";
import TagRoutes from "./Blog/TagRoutes";
import UnassignedInquiryRoutes from "./OfferInquiry/UnassigneInquiry";
import AssignedInquiryRoutes from "./OfferInquiry/AssignedInquiry";
import AddRouteRoutes from "./Route/AddRoute";
import AddBookingRoutes from "./Booking/AddBooking";
import AssignedBookingRoutes from "./Booking/AssignedBooking";
import UnassignedBookingRoutes from "./Booking/UnassignedBooking";
import NoAccessRoute from "./Other/NoAccess";
import { AuthContext } from "../contextApi/context";
import { useContext } from "react";
import PRIVATEROUTES from "../components/Constants/Constant";
import IpAddressRoutes from "./IpAddress/IpAddress";
import LoginProfileRoutes from "./LogInProfile/LoginProfile";
import AllActionRoutes from "./LogInProfile/AllAction";
import PhoneFaresRoutes from "./PhoneFares/AllPhoneFares";

export default function ThemeRoutes() {
  const { authState } = useContext(AuthContext);
  const { permissionmodules } = authState;

  const handleCheckModulePermission = (moduleName) => {
    let isPublic = false;
    isPublic = permissionmodules?.includes(moduleName);
    return isPublic;
  };

  return useRoutes([
    HomeRoutes,
    NoAccessRoute,
    handleCheckModulePermission(PRIVATEROUTES.ADD_STAFF) && StaffRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ADMIN_LIST) && AdminRoutes,
    handleCheckModulePermission(PRIVATEROUTES.EMPLOYEE_LIST) && EmployeeRoutes,
    handleCheckModulePermission(PRIVATEROUTES.MANAGER_LIST) && ManagerRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ADD_CUSTOMER) && AddCustomerRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CUSTOMER_LIST) && CustomerRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ADD_OFFER) && AddOfferPageRoutes,
    handleCheckModulePermission(PRIVATEROUTES.OFFER_LIST) && OfferPageRoutes,
    handleCheckModulePermission(PRIVATEROUTES.BLOG) && BlogRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CATEGORY) && CategoryRoutes,
    handleCheckModulePermission(PRIVATEROUTES.TAGS) && TagRoutes,
    SearchRecordRoutes,
    handleCheckModulePermission(PRIVATEROUTES.PAYMENTS) && PaymentRoutes,
    MailboxRoutes,
    handleCheckModulePermission(PRIVATEROUTES.AIRLINE_ICONS) && AirlineIconsRoutes,
    handleCheckModulePermission(PRIVATEROUTES.UNASSIGN_INQUIRY) && UnassignedInquiryRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ASSIGN_INQUIRY) && AssignedInquiryRoutes,
    handleCheckModulePermission(PRIVATEROUTES.CREATE_BOOKING) && AddBookingRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ASSIGN_BOOKING) && AssignedBookingRoutes,
    handleCheckModulePermission(PRIVATEROUTES.UNASSIGN_BOOKING) && UnassignedBookingRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ADD_ROUTE) && AddRouteRoutes,
    handleCheckModulePermission(PRIVATEROUTES.ROUTE_LIST) && RouteRoutes,
    handleCheckModulePermission(PRIVATEROUTES.IP_ADDRESS) && IpAddressRoutes,
    handleCheckModulePermission(PRIVATEROUTES.Logged_In_Profiles) && LoginProfileRoutes,
    handleCheckModulePermission(PRIVATEROUTES.Activity_Logs) && AllActionRoutes,
    PhoneFaresRoutes,
    SettingsRoutes,
    ChangePasswordRoutes,
  ]);
}
