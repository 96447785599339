import React from "react";
import AllLoginProfile from "../../pages/LoginProfiles/AllLoginProfile";

const LoginProfileRoutes = {
  path: "/",
  children: [
    {
      path: "allloggedprofile",
      element: <AllLoginProfile />,
    },
  ],
};

export default LoginProfileRoutes;
