import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import ApiCall from "../../../api/ApiCall";
import { creationEndpoint } from "../../../api/Endpoints";
import { startApiCall } from "../../../Global/globalFunctions";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";

const CustomerList = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [allCustomer, setAllCustomer] = useState([]);
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllCustomer = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        role: "customer",
        searchKey: filterType,
        searchValue: search,
        isDeleted: false
      },
    };
    const res = await ApiCall("post", creationEndpoint.getCustomers, data);
    if (res?.success) {
      setAllCustomer(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllCustomer();
  }, [search, page, pageLimit, filterType]);

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">Customer</h4>
          <Link
            to="/customer/addcustomer"
            className="btn btn-outline-primary text-capitalize"
          >
            Add customer
          </Link>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllCustomer();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
          <div>
            <select
              className="form-control"
              style={{ width: "200px" }}
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="">Select</option>
              <option value="name">Name</option>
              <option value="email">Email</option>
              <option value="mobile_no">Phone</option>
              <option value="pnr_no">Pnr</option>
              <option value="card_no">Card details</option>
            </select>
          </div>
          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
          />
        </form>
      </div>
      {!loader && allCustomer?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-5">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile No.</th>
                    <th scope="col">Email</th>
                    <th scope="col">Total Bookings</th>
                    <th scope="col">Total Search Record</th>
                    <th scope="col">Is Active</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allCustomer?.map((data, index) => {
                    return (
                      <tr>
                        <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                        <td className="text-capitalize">{data?.name}</td>
                        <td>{data?.mobile_no ? data?.mobile_no : "-"}</td>
                        <td>{data?.email}</td>
                        <td>
                          {data?.bookings?.length > 0 ? (
                            <div className="d-flex gap-2 justify-content-center">
                              <span className="font-3">
                                {data?.bookings?.length}
                              </span>
                              <span
                                className="text-primary pointer mt-1"
                                onClick={() =>
                                  navigate("/booking/allbooking", {
                                    state: { custId: data?._id },
                                  })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                  className="bi bi-info-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                </svg>
                              </span>
                            </div>
                          ) : (
                            "No Booking Created"
                          )}
                        </td>
                        <td>
                          {data?.flightSearchCount !== 0 ? (
                            <div className="d-flex gap-2 justify-content-center">
                              <span className="font-3">
                                {data?.flightSearchCount}
                              </span>
                              <span
                                className="text-primary pointer mt-1"
                                onClick={() =>
                                  navigate(`/customer/allsearchrecord/${data?._id}`,
                                  {
                                    state: { userName: data?.name },
                                  })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                  className="bi bi-info-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                </svg>
                              </span>
                            </div>
                          ) : (
                            data?.flightSearchCount
                          )}
                        </td>
                        <td>
                          {data?.is_active && (
                            <span className="icon-padding text-success">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check2-all"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                              </svg>
                            </span>
                          )}
                        </td>
                        <td>
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              type="button"
                              id="action_dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </span>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action_dropdown"
                              style={{ minWidth: "0" }}
                            >
                              <span
                                className="dropdown-item pointer"
                                onClick={() =>
                                  navigate("/customer/addcustomer", {
                                    state: { staffId: data?._id },
                                  })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                                &nbsp; Edit
                              </span>
                              <span
                                className="dropdown-item pointer"
                                onClick={() =>
                                  navigate(
                                    `/customer/allusercard/${data?._id}`,
                                    {
                                      state: { userId: data?._id },
                                    }
                                  )
                                }
                                data-toggle="tooltip"
                                data-placement="right"
                                title="User card list"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-credit-card-2-back"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5z" />
                                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1m-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1" />
                                </svg>
                                &nbsp; Cards
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allCustomer?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {loader && <DataLoader />}
      {!loader && allCustomer.length === 0 && <NoData />}
    </div>
  );
};

export default CustomerList;
