import React, { useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SideNavbar from "./components/Navbar/SideNavbar";
import Login from "./pages/Authentication/Login/Login";
import Signup from "./pages/Authentication/Signup/Signup";
import AllRoutes from "./all-routes";
import { ToastContainer } from "react-toastify";
import { creationEndpoint } from "./api/Endpoints";
import ApiCall from "./api/ApiCall";
import PageLoader from "./components/PageLoader/PageLoader";
import {
  GETUSER,
  PERMISSIONMODULES,
  USERMODULESWITHPERSMISSIONS,
} from "./contextApi/actions/auth.actions";
import { authReducer } from "./contextApi/reducer";
import { AuthContext } from "./contextApi/context";
import Navbar from "./components/Navbar/Navbar";

const App = () => {
  const [authState, authDispatch] = useReducer(authReducer, {});
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const refreshToken = JSON.parse(
    localStorage.getItem("refreshTokenForOguleAdmin")
  );

  // ******** AUTHENTICATE USER CHECK**********
  const handleAuthenticateUser = async () => {
    console.log("***Logged in Success.Token Verified***");
    const res = await ApiCall("get", creationEndpoint.getStaff, {
      params: { token: refreshToken },
    });
    if (res?.success) {
      let modulesNames = res?.result?.permissions?.[0]?.modules?.map((per) => {
        return per.moduleName;
      });
      authDispatch({
        type: GETUSER,
        payload: res.result,
      });
      authDispatch({
        type: USERMODULESWITHPERSMISSIONS,
        payload: res?.result?.permissions?.[0]?.modules,
      });
      authDispatch({
        type: PERMISSIONMODULES,
        payload: modulesNames ? modulesNames : [],
      });
      setisLoggedIn(true);
      setIsLoaded(true);
    } else {
      setisLoggedIn(false);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (refreshToken) {
      handleAuthenticateUser();
    } else {
      setisLoggedIn(false);
      setIsLoaded(true);
    }
  }, []);

  return isLoaded ? (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {!isLoggedIn ? (
        <Routes>
          <>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </>

          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      ) : (
        <div className="d-flex flex-column-md">
          <SideNavbar />
          <div className="w-100 h-100vh overflow-y-scroll border">
            <Navbar />
            <AllRoutes />
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
      />
    </AuthContext.Provider>
  ) : (
    <PageLoader />
  );
};

export default App;
