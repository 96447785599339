let host = window.location.href;
let isNilhans = localStorage.getItem("urltype") === "Nilhans" ? true : false;
// let isProduction = host?.includes("bizhood") ? true : false;
// console.log(
//   isProduction
//     ? "🚨🚨🚨🚨🚨🚨LIVE🚨🚨🚨🚨🚨🚨"
//     : "🚧🚧🚧🚧🚧DEVELOPMENT🚧🚧🚧🚧🚧🚧"
// );

// ********* BASE URL ********
// export const BASE_URL = isProduction
//   ? "https://api.bizhood.in/api"
//   : "https://apitemp.bizhood.in/api";

let urlType = localStorage.getItem("urltype");
export const BASE_URL =
  urlType === "Nilhans"
    ? "https://api.nilhans.com/api"
    : urlType === "BestAirFares"
    ? "https://lb.sociohood.com/api"
    : "https://api.ogule.com/api";

// ********* VERSION URL ********
// export const VERSION_URL = isProduction
//   ? "https://versionprod.sociohood.com/api"
//   : "https://version.sociohood.com/api";

// export const ACTIVE_VERSION = isProduction ? "1.0.Production" : "1.1";

// ********* AWS BUCKET NAME ********
// export const AWS_BUCKET_NAME = isProduction
//   ? "bizhood-task-manager-dashboard"
//   : "sociohood-development-bucket";

export const AWS_BUCKET_NAME = "ogule-website";
