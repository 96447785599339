import React from "react";
import RouteList from "../../pages/Route/RouteList/RouteList";
import Markup from "../../pages/Route/Markup/MarkupList/Markup";
import AddMarkup from "../../pages/Route/Markup/AddMarkup/AddMarkup";

const RouteRoutes = {
  path: "/route",
  children: [
    {
      path: "routelist",
      element: <RouteList />,
    },
    {
      path: "addmarkup/:routeid",
      element: <AddMarkup />,
    },
    {
      path: "markuplist/:routeid",
      element: <Markup />,
    },
  ],
};

export default RouteRoutes;
