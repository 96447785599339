import React from "react";
import EditProfile from "../../pages/Profile/EditProfile/EditProfile";

const ChangePasswordRoutes = {
  path: "/profile",
  children: [
    {
      path: "editprofile",
      element: <EditProfile />,
    },
  ],
};
export default ChangePasswordRoutes;
