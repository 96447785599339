import React from "react";
import AllPhoneFares from "../../pages/PhoneFares/AllPhoneFares";

const PhoneFaresRoutes = {
  path: "/",
  children: [
    {
      path: "allphonefares",
      element: <AllPhoneFares />,
    },
  ],
};
export default PhoneFaresRoutes;
