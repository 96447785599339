import React from "react";
import UnassignedInquiry from "../../pages/OfferInquiry/UnassignedInquiry/UnassignedInquiry";

const UnassignedInquiryRoutes = {
  path: "/offerinquiry",
  children: [
    {
      path: "unassignedinquiry",
      element: <UnassignedInquiry />,
    },
  ],
};

export default UnassignedInquiryRoutes;
