import React from "react";
import noData from "../../assets/NoData.svg";

const NoData = ({text}) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <img className="noData-img w-100" src={noData} alt="No Data" />
      <p className="text-secondary text-uppercase fw-700 h4">{text ? text : "No data"}</p>
    </div>
  );
};

export default NoData;
