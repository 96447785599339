import React, { useState } from "react";
import UserProfile from "./UserProfile";
import ForgotPassword from "../../Authentication/ForgotPassword/ForgotPassword";
import Security from "./Security";

const EditProfile = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [firstStep, setfirstStep] = useState(true);

  const [currentTab, setCurrentTab] = useState("Personal Details");
  return (
    <>
      <div className="p-3">
        <h4 className="text-uppercase mb-0 mb-3">
          User Profile
        </h4>
        <ul className="nav nav-tabs">
          <li
            className="nav-item pointer"
            onClick={() => setCurrentTab("Personal Details")}
          >
            <span
              className={`nav-link ${
                currentTab === "Personal Details" ? "active text-primary" : ""
              }`}
            >
              Personal Details
            </span>
          </li>
          <li
            className="nav-item pointer"
            onClick={() => setCurrentTab("Security")}
          >
            <span
              className={`nav-link ${
                currentTab === "Security" ? "active text-primary" : ""
              }`}
            >
              Security
            </span>
          </li>
        </ul>
        {currentTab === "Personal Details" && <UserProfile />}
        {currentTab === "Security" && <Security setForgotPassword={setForgotPassword} setfirstStep={setfirstStep} />}
      </div>
      {forgotPassword && (
        <ForgotPassword
          setForgotPassword={setForgotPassword}
          firstStep={firstStep}
          setfirstStep={setfirstStep}
          isLogin={true}
        />
      )}
    </>
  );
};

export default EditProfile;