import React, { useContext, useState } from "react";
import ApiLoader from "../../components/Loader/ApiLoader";
import { startApiCall } from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { bookingRelatedEndPoint } from "../../api/Endpoints";
import ErrorMessage from "../../components/Error/ErrorMessage";
import { AuthContext } from "../../contextApi/context";
import { toast } from "react-toastify";

const ConvertToContact = ({ setConfirmModal, bookingId , handleGetBookingDetails}) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const handleChangeToCustomer = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      bookingId,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      bookingRelatedEndPoint.convertToCustomer,
      data
    );
    if (res?.success) {
      setloader(false);
      setConfirmModal(false);
      toast.success("Convert to customer successfully!");
      handleGetBookingDetails();
    } else {
      setloader(false);
      seterrorMessage(res.error)
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setConfirmModal(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Confirmation</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <form onSubmit={handleChangeToCustomer}>
              <div className="row gx-3 py-2">
                <div className="col-12 d-flex justify-content-center text-capitalize mt-2">
                  Are you sure you want to convert this contact ?
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer mt-3 justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger mr-3"
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvertToContact;
