import React from "react";

const DataLoader = () => {
  return (
    <>
      <div class="loader">
        <div class="justify-content-center jimu-primary-loading"></div>
      </div>
    </>
  );
};

export default DataLoader;
