import React, { useContext, useEffect, useState } from "react";
import { startApiCall } from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { bookingRelatedEndPoint } from "../../api/Endpoints";
import DataLoader from "../../components/Loader/DataLoader";
import { AuthContext } from "../../contextApi/context";

const Home = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState("");
  const [bookingCounts, setBookingCounts] = useState(0);

  const handleGetAllBookingCounts = async () => {
    startApiCall(null, setloader);
    const res = await ApiCall(
      "post",
      bookingRelatedEndPoint.getBookingCounts,
      {}
    );
    if (res?.success) {
      setBookingCounts(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllBookingCounts();
  }, []);

  return (
    <div className="container py-3 d-flex flex-column justify-content-between overflow-hidden" style={{ height: "calc(100vh - 56px)" }}>
      <header className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm ml-2">
            Welcome, <span className="text-primary">{user?.name}</span>
          </h4>
        </div>
      </header>
      {!loader && (
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="card shadow mb-4">
              <div className="card-header bg-primary">
                <div className="card-text text-uppercase font-weight-bold d-flex align-items-center gap-2 justify-content-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-airplane-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849" />
                  </svg>
                  All Bookings
                </div>
              </div>
              <div className="card-body">
                <p className="h5 text-center">{bookingCounts?.All}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card shadow mb-4">
              <div className="card-header bg-success">
                <div className="card-text text-uppercase font-weight-bold d-flex align-items-center gap-2 justify-content-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                  Confirmed Bookings
                </div>
              </div>
              <div className="card-body">
                <p className="h5 text-center">{bookingCounts?.Confirmed}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card shadow mb-4">
              <div className="card-header bg-warning">
                <div className="card-text text-uppercase font-weight-bold d-flex align-items-center gap-2 justify-content-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clock-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                  </svg>
                  Pending Bookings
                </div>
              </div>
              <div className="card-body">
                <p className="h5 text-center">{bookingCounts?.Pending}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card shadow mb-4">
              <div className="card-header bg-danger">
                <div className="card-text text-uppercase font-weight-bold d-flex align-items-center gap-2 justify-content-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                  </svg>
                  Cancelled Bookings
                </div>
              </div>
              <div className="card-body">
                <p className="h5 text-center">{bookingCounts?.Cancelled}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card shadow mb-4">
              <div className="card-header bg-secondary">
                <div className="card-text text-uppercase font-weight-bold d-flex align-items-center gap-2 justify-content-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                  </svg>
                  Failed Bookings
                </div>
              </div>
              <div className="card-body">
                <p className="h5 text-center">{bookingCounts?.Failed}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {loader && <DataLoader />}
      <p className="text-center">
        Copyright © 2018 OGULE , All rights reserved. Website design MaMITs
      </p>
    </div>
  );
};

export default Home;
