import React, { useEffect, useState } from "react";
import { FlightRelatedEndPoint } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { startApiCall } from "../../../Global/globalFunctions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import moment from "moment";
import Pagination from "../../../components/Pagination";

const SearchRecord = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [allFlights, setAllFlights] = useState([]);
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(100);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleGetAllFlights = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        user_id: id,
      },
    };
    const res = await ApiCall(
      "post",
      FlightRelatedEndPoint.getFlightRecord,
      data
    );
    if (res?.success) {
      setAllFlights(localStorage.getItem("urltype") === "BestAirFares" ? res?.result?.docs : res?.result?.data);
      setcount(localStorage.getItem("urltype") === "BestAirFares" ? res?.result?.total : res?.result?.totalCounts);
      settotalPages(localStorage.getItem("urltype") === "BestAirFares" ? res?.result?.pages : res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllFlights();
  }, [page, pageLimit]);

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <span
            className="btn btn-light border"
            onClick={() => navigate("/customer/customerlist")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
          </span>
          <h4 className="text-uppercase mb-0 ml-2 headline-sm">
            Flight Search Record &nbsp;
            <span
              className="text-primary text-capitalize"
              style={{ fontSize: "22px" }}
            >
              ({locationData?.userName})
            </span>
          </h4>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span className="btn btn-light mr-2" onClick={handleGetAllFlights}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </form>
      </header>
      {!loader && allFlights?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-6">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Click ID</th>
                    <th scope="col">Click Date</th>
                    <th scope="col">Click Time</th>
                    <th scope="col">IP address</th>
                    <th scope="col">Way of flight</th>
                    <th scope="col">Route</th>
                    <th scope="col">Departure date</th>
                    <th scope="col">Return date</th>
                    <th scope="col">Travel class</th>
                    <th scope="col">Passenger</th>
                  </tr>
                </thead>
                <tbody>
                  {allFlights?.map((data, index) => {
                    return (
                      <tr>
                        <td>{data?.clickId}</td>
                        <td>
                          {moment(data?.clickDateAndTime).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment(data?.clickDateAndTime).format("HH:mm:ss")}
                        </td>
                        <td>{data?.ip_address ? data?.ip_address : "-"}</td>
                        <td className="text-capitalize">{data?.wayOfFlight}</td>
                        <td>
                          {data?.originLocationCode} -{" "}
                          {data?.destinationLocationCode}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {moment(data?.departureDate, "DDMMYY").format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {data?.returnDate
                            ? moment(data?.returnDate, "DDMMYY").format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </td>
                        <td>{data?.travelClass ? data?.travelClass : "-"}</td>
                        <td>{data?.pax ? data?.pax : "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allFlights?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {loader && <DataLoader />}
      {!loader && allFlights?.length === 0 && <NoData />}
    </div>
  );
};

export default SearchRecord;
