import React, { useEffect, useState } from "react";
import {
  airplaneIconRelatedEndPoint,
  PhoneFareRelatedEndPoint,
} from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loader/ApiLoader";
import { startApiCall } from "../../Global/globalFunctions";
import { toast } from "react-toastify";
import ToasterMessages from "../../Global/ToasterMessages";

const AddPhoneFare = ({
  setAddFare,
  editPhoneFare,
  handleGetAllPhoneFares,
  setEditPhoneFare
}) => {
  const [allIcons, setAllIcons] = useState([]);
  const [airlines, setAirlines] = useState("ALL");
  const [bookingClass, setBookingClass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingIcons, setLoadingIcons] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleGetAirlineIcons = async () => {
    setLoadingIcons(true);
    const data = { filters: {} };
    const res = await ApiCall(
      "post",
      airplaneIconRelatedEndPoint.getAllAirplaneIcon,
      data
    );
    setLoadingIcons(false);
    if (res?.success) {
      setAllIcons(res.result);
    } else {
      setErrorMessage("Failed to load airline icons");
    }
  };

  useEffect(() => {
    handleGetAirlineIcons();
  }, []);

  const handleReset = () => {
    setAirlines("ALL");
    setBookingClass("");
    setErrorMessage("");
    setLoadingSubmit(false);
    handleGetAllPhoneFares();
    setEditPhoneFare("");
    setAddFare(false);
  };

  const handleAddPhoneFare = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    setErrorMessage("");
    const sendData = {
      airline: airlines,
      booking_class: bookingClass,
    };
    const res = await ApiCall(
      "post",
      PhoneFareRelatedEndPoint?.addPhoneFare,
      sendData
    );
    setLoadingSubmit(false);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
      setAddFare(false);
    } else {
      setErrorMessage(res.error || "Failed to add phone fare");
    }
  };

  const handleEditPhoneFare = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoadingSubmit);
    const data = {
      phonefareId: editPhoneFare?._id,
      airline: airlines,
      booking_class: bookingClass,
    };
    const res = await ApiCall(
      "post",
      PhoneFareRelatedEndPoint?.editPhoneFare,
      data
    );
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setLoadingSubmit(false);
      setErrorMessage(res.error);
    }
  };
  useEffect(() => {
    if (editPhoneFare) {
      setAirlines(editPhoneFare?.airline);
      setBookingClass(editPhoneFare?.booking_class);
    }
  }, [editPhoneFare]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => setAddFare(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="d-flex">
              <h5 className="text-capitalize mb-2">
                {editPhoneFare === "" ? "Add " : "Edit"}Phone Fare
              </h5>
            </div>
            <div className="border-bottom"></div>
            <form
              onSubmit={
                editPhoneFare ? handleEditPhoneFare : handleAddPhoneFare
              }
            >
              <div className="form-group mt-3">
                <label>Airlines</label>
                <select
                  required
                  className="form-control"
                  value={airlines}
                  onChange={(e) => setAirlines(e.target.value)}
                >
                  <option value="ALL">All</option>
                  {allIcons.map((item) => (
                    <option key={item.airline_code} value={item.airline_code}>
                      {item.airline_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mt-2">
                <label>Booking class</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={bookingClass}
                  onChange={(e) => setBookingClass(e.target.value)}
                />
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer mt-3 justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger mr-3"
                  onClick={() => setAddFare(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!airlines || !bookingClass || loadingSubmit}
                >
                  {loadingSubmit ? (
                    <ApiLoader />
                  ) : editPhoneFare === "" ? (
                    "Save"
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPhoneFare;
