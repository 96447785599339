import React from "react";
import CreateNewForm from "../../../components/CreateNewForm/CreateNewForm";

const AddStaff = () => {
  return (
    <CreateNewForm
      title="Add Staff"
      showRoleSection={true}
      // onSubmit={handleFormSubmit}
    />
  );
};

export default AddStaff;
