const staffPrefix = "staff";
const managementPrefix = "management";
const authPrefix = "auth";
const bookingPrefix = "booking";
const blogPrefix = "blog";
const userPrefix = "user";
const flightPrefix = "frontend";
const awsPrefix = "aws";

export const forgotPasswordEndpoints = {
  sendOtpForResetPassword: authPrefix + "/send-otp-forgot-password",
  validateOtpForResetPassword: authPrefix + "/validate-otp-forgot-password",
  resetPassword: authPrefix + "/reset-password",
};

// ** ADD RELATED ENDPOINTS **
export const creationEndpoint = {
  addUser: staffPrefix + "/add-staff",
  getAllUsers: staffPrefix + "/get-all-staffs",
  editUser: staffPrefix + "/edit-staff",
  userLogin: staffPrefix + "/login-staff",
  userLogout: staffPrefix + "/logout-staff",
  getStaffById: staffPrefix + "/get-staff-by-id",
  getStaff: staffPrefix + "/get-staff",
  getCustomers: managementPrefix + "/get-customers",
  whitelistUserIp: managementPrefix + "/whitelist-user-ip",
  getAccessRecords: managementPrefix + "/get-access-records",
  getActivityLogs: managementPrefix + "/get-activity-logs",
};

export const offerRelatedEndPoint = {
  addOffer: managementPrefix + "/add-offer",
  getAllOffers: managementPrefix + "/get-all-offers",
  getOfferById: managementPrefix + "/get-offer-by-id",
  editOffer: managementPrefix + "/edit-offer",
};

export const bookingRelatedEndPoint = {
  addBooking: flightPrefix + "/add-bill-info",
  getAllBookings: managementPrefix + "/get-all-bookings",
  getBookingById: managementPrefix + "/get-booking-by-id",
  editBooking: managementPrefix + "/edit-booking",
  assignedBooking: managementPrefix + "/assign-employee-to-booking",
  bookingLifeCycle: managementPrefix + "/booking-life-cycle",
  getBookingLifeCycle: managementPrefix + "/get-booking-life-cycle",
  convertToCustomer: managementPrefix + "/convert-to-customer",
  getBookingCounts: managementPrefix + "/get-bookings-count",
};

export const routeRelatedEndPoint = {
  addRoute: managementPrefix + "/add-route",
  getAllRoutes: managementPrefix + "/get-all-routes",
  getRouteById: managementPrefix + "/get-route-by-id",
  editRoute: managementPrefix + "/edit-route",
};

export const offerInquiryRelatedEndPoint = {
  addOfferInquiry: managementPrefix + "/add-offer-inquiry",
  getAllOfferInquiry: managementPrefix + "/get-all-offer-inquiries",
  getOfferInquiryById: managementPrefix + "/get-offer-inquiry-by-id",
  editOfferInquiry: managementPrefix + "/edit-offer-inquiry",
};

export const airplaneIconRelatedEndPoint = {
  addAirplaneIcon: managementPrefix + "/add-icon",
  getAllAirplaneIcon: managementPrefix + "/get-all-icons",
  getAirplaneIconById: managementPrefix + "/get-icon-by-id",
  editAirplaneIcon: managementPrefix + "/edit-icon",
};

export const UserCardRelatedEndPoint = {
  addCard: managementPrefix + "/add-card",
  getAllCards: managementPrefix + "/get-all-cards",
  getCardById: managementPrefix + "/get-card-by-id",
  editCard: managementPrefix + "/edit-card",
};

export const TagRelatedEndPoint = {
  addTag: managementPrefix + "/add-tag",
  getAllTags: managementPrefix + "/get-all-tags",
  getTagById: managementPrefix + "/get-tag-by-id",
  editTag: managementPrefix + "/edit-tag",
};

export const CategoryRelatedEndPoint = {
  addCategory: managementPrefix + "/add-category",
  getAllCategories: managementPrefix + "/get-all-categories",
  getCategoryById: managementPrefix + "/get-category-by-id",
  editCategory: managementPrefix + "/edit-category",
};

export const BlogRelatedEndPoint = {
  addBlog: blogPrefix + "/add-blog",
  getAllBlogs: blogPrefix + "/get-all-blogs",
  getBlogById: blogPrefix + "/get-blog-by-id",
  editBlog: blogPrefix + "/edit-blog",
};

export const MailRelatedEndPoint = {
  addMail: managementPrefix + "/add-mail",
  getAllMails: managementPrefix + "/get-all-mails",
  getMailById: managementPrefix + "/get-mail-by-id",
  editMail: managementPrefix + "/edit-mail",
};

export const PaymentRelatedEndPoint = {
  addPayment: managementPrefix + "/add-transaction",
  getAllPayments: managementPrefix + "/get-all-transactions",
  getPaymnetById: managementPrefix + "/get-transaction-by-id",
  editPayment: managementPrefix + "/edit-transaction",
};

export const MarkupRelatedEndPoint = {
  addMarkup: managementPrefix + "/add-markup_tool",
  getAllMarkUpTools: managementPrefix + "/get-all-markup_tools",
  getMarkupToolById: managementPrefix + "/get-markup_tool-by-id",
  editMarkupTool: managementPrefix + "/edit-markup_tool",
  csvUploadForMarkup: managementPrefix + "/csv-upload-for-markup",
};

export const IpRelatedEndPoint = {
  addIp: managementPrefix + "/add-whitelist-ip",
  getAllIp: managementPrefix + "/get-whitelist-ip",
  deleteIp: managementPrefix + "/delete-whitelist-ip",
};

export const SettingRelatedEndPoint = {
  addPhoneFareSettings: managementPrefix + "/add-phone-fare-settings",
  editPhoneFareSettings: managementPrefix + "/edit-phone-fare-settings",
  getPhoneFareSettings: managementPrefix + "/get-phone-fare-settings",
};

export const FlightRelatedEndPoint = {
  getFlightRecord: managementPrefix + "/get-flight_search_records",
};

// End points for nilhans
export const NilhansbookingRelatedEndPoint = {
  getAllBookings: bookingPrefix + "/get-all-bookings",
  getBookingById: bookingPrefix + "/get-booking-by-id",
  editBooking: bookingPrefix + "/add-booking-manual-infos",
};

export const NilhansBlogRelatedEndPoint = {
  addBlog: blogPrefix + "/add-blog",
  getAllBlogs: blogPrefix + "/get-blogs",
  editBlog: blogPrefix + "/edit-blog",
  getBlogById: blogPrefix + "/get-blog-by-id",
};

export const NilhansUserCardRelatedEndPoint = {
  addCard: userPrefix + "/add-card",
  getAllCards: userPrefix + "/get-all-cards",
  getCardById: userPrefix + "/get-card-by-id",
  editCard: userPrefix + "/edit-card",
};

export const NilhansofferRelatedEndPoint = {
  addOffer: flightPrefix + "/add-offer",
  getAllOffers: flightPrefix + "/get-all-offers",
  getOfferById: flightPrefix + "/get-offer-by-id",
  editOffer: flightPrefix + "/edit-offer",
};

export const PhoneFareRelatedEndPoint = {
  addPhoneFare: managementPrefix + "/add-phone-fare",
  editPhoneFare: managementPrefix + "/edit-phone-fare",
  getAllPhoneFares: managementPrefix + "/get-phone-fare",
};

export const AwsS3Routes = {
  getPreSignedURL: awsPrefix + "/generate-presigned-url",
};
