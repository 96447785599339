import React, { useEffect, useState } from "react";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import { startApiCall } from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { SettingRelatedEndPoint } from "../../api/Endpoints";
import ApiLoader from "../../components/Loader/ApiLoader";
import DataLoader from "../../components/Loader/DataLoader";

const Setting = () => {
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allSettings, setAllSettings] = useState("");
  const [discount, setDiscount] = useState("");
  const [thresold, setThresold] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const handleGetSettings = async () => {
    startApiCall(null, setloader);
    const res = await ApiCall(
      "post",
      SettingRelatedEndPoint.getPhoneFareSettings,
      {}
    );
    if (res?.success) {
      setAllSettings(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetSettings();
  }, []);

  const handleReset = () => {
    setloader2(false);
    setDiscount("");
    setThresold("");
  };

  const handleAddSettings = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      discount,
      thresold,
    };
    const res = await ApiCall(
      "post",
      SettingRelatedEndPoint.addPhoneFareSettings,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetSettings();
      setIsEdit(false);
    } else {
      setloader2(false);
    }
  };

  const handlEditSettings = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      phonefareId: allSettings?._id,
      discount,
      thresold,
    };
    const res = await ApiCall(
      "post",
      SettingRelatedEndPoint.editPhoneFareSettings,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetSettings();
      setIsEdit(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (allSettings) {
      setDiscount(allSettings?.discount);
      setThresold(allSettings?.thresold);
    }
  }, [allSettings]);

  return (
    <div className="container pt-3 pb-5">
      <h4 className="text-uppercase text-center mb-3">Settings</h4>
      {!loader && (
        <form
          onSubmit={(e) => {
            if (allSettings === null) {
              handleAddSettings(e);
            } else {
              handlEditSettings(e);
            }
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Phone Fare Discount (%)</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="phone fare discount in %"
                  required
                  disabled={!isEdit}
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Phone Fare Thresold (s)</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="phone fare thresold"
                  required
                  disabled={!isEdit}
                  value={thresold}
                  onChange={(e) => setThresold(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {!isEdit && (
              <button
                type="reset"
                className="btn btn-primary mr-3 px-5"
                onClick={() => setIsEdit(true)}
              >
                Edit
              </button>
            )}
            {isEdit && (
              <>
                <button
                  type="reset"
                  className="btn btn-danger mr-3 px-5"
                  onClick={() => {
                    handleGetSettings();
                    setIsEdit(false);
                  }}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-success px-5">
                  {loader2 ? <ApiLoader /> : "Save"}
                </button>
              </>
            )}
          </div>
        </form>
      )}
      {loader && <DataLoader />}
    </div>
  );
};

export default Setting;
