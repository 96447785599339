import React from "react";
import ApiLoader from "../Loader/ApiLoader";
import ErrorMessage from "../Error/ErrorMessage";

const ConfirmModal = ({ setConfirmModal, text, handleSubmit, loader , errorMessage, handleReset }) => {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Confirmation</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <div>
              <div className="row gx-3 py-2">
                <div className="col-12 d-flex justify-content-center mt-2">
                  {text}
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer mt-3 justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger mr-3"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success" onClick={handleSubmit}>
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
