import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ApiCall from "../../../api/ApiCall";
import { startApiCall } from "../../../Global/globalFunctions";
import ToasterMessages from "../../../Global/ToasterMessages";
import { toast } from "react-toastify";
import { TagRelatedEndPoint } from "../../../api/Endpoints";
import ApiLoader from "../../../components/Loader/ApiLoader";
import DataLoader from "../../../components/Loader/DataLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { AuthContext } from "../../../contextApi/context";

const AddTag = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [tagloader, setTagloader] = useState();
  const [errorMessage, seterrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [tagDetails, setTagDetails] = useState([]);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setTitle("");
    setSlug("");
  };

  const handleAddTag = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      slug,
      name: title,
      actionBy: user?._id,
    };
    const res = await ApiCall("post", TagRelatedEndPoint.addTag, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditTag = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      tagId: locationData?.tagId,
      slug,
      name: title,
      actionBy: user?._id,
    };
    const res = await ApiCall("post", TagRelatedEndPoint.editTag, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetTagDetails = async () => {
    startApiCall(null, setTagloader);
    const data = {
      tagId: locationData?.tagId,
    };
    const res = await ApiCall("post", TagRelatedEndPoint.getTagById, data);
    if (res?.success) {
      setTagDetails(res?.result);
      setTagloader(false);
    } else {
      setTagloader(false);
    }
  };

  useEffect(() => {
    if (locationData?.tagId) {
      handleGetTagDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.tagId) {
      setTitle(tagDetails?.name);
      setSlug(tagDetails?.slug);
    }
  }, [locationData, tagDetails]);

  return (
    <div className="container pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex align-items-center gap-3 mb-3">
            <Link to="/blog/taglist" className="btn btn-light border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </Link>
            <h4 className="text-uppercase mb-0">
              {locationData?.tagId ? "Edit" : "Add"} tag
            </h4>
          </div>
          {!tagloader && (
            <form
              onSubmit={(e) => {
                if (locationData?.tagId) {
                  handleEditTag(e);
                } else {
                  handleAddTag(e);
                }
              }}
            >
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Slug</label>
                <input
                  type="text"
                  className="form-control"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <hr className="mb-4"/>
              <div className="d-flex justify-content-end">
                <button type="reset" className="btn btn-danger mr-3 px-5" onClick={handleReset}>
                  Reset
                </button>
                <button type="submit" className="btn btn-success px-5">
                  {loader ? (
                    <ApiLoader />
                  ) : locationData?.tagId ? (
                    "Edit"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      {tagloader && <DataLoader />}
    </div>
  );
};

export default AddTag;
