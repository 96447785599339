import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  airplaneIconRelatedEndPoint,
  routeRelatedEndPoint,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ToasterMessages from "../../../Global/ToasterMessages";
import ApiCall from "../../../api/ApiCall";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiLoader from "../../../components/Loader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import DataLoader from "../../../components/Loader/DataLoader";
import { AuthContext } from "../../../contextApi/context";

const AddRoute = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [routeLoader, setRouteLoader] = useState(false);
  const [apiToken, setApiToken] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [referenceName, setReferenceName] = useState("");
  const [airlines, setAirlines] = useState("ALL");
  const [allIcons, setAllIcons] = useState([]);
  const [status, setStatus] = useState("active");
  const [metaSearch, setMetaSearch] = useState("Kayak")

  const [locationFrom, setLocationFrom] = useState("");
  const [fromData, setFromData] = useState([]);
  const [locationTo, setLocationTo] = useState("");
  const [toData, setToData] = useState([]);

  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [routeDetails, setRouteDetails] = useState([]);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setReferenceName("");
    setLocationFrom("");
    setLocationTo("");
    setAirlines("ALL");
    setStatus("active");
    setMetaSearch("Kayak");
  };

  const handleAddRoute = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      referenceName,
      origin: locationFrom,
      destination: locationTo,
      airlines: airlines,
      actionBy: user?._id,
      status,
      metaSearch
    };
    const res = await ApiCall("post", routeRelatedEndPoint.addRoute, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditRoute = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      routeId: routeDetails?._id,
      referenceName,
      origin: locationFrom,
      destination: locationTo,
      airlines: airlines,
      actionBy: user?._id,
      status,
      metaSearch
    };
    const res = await ApiCall("post", routeRelatedEndPoint.editRoute, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetRouteDetails = async () => {
    startApiCall(null, setRouteLoader);
    const data = {
      routeId: locationData?.routeId,
    };
    const res = await ApiCall("post", routeRelatedEndPoint.getRouteById, data);
    if (res?.success) {
      setRouteDetails(res?.result);
      setRouteLoader(false);
    } else {
      setRouteLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.routeId) {
      handleGetRouteDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.routeId) {
      setLocationFrom(routeDetails?.origin);
      setLocationTo(routeDetails?.destination);
      setAirlines(routeDetails?.airlines);
      setStatus(routeDetails?.status);
      setMetaSearch(routeDetails?.metsSearch);
    }
  }, [locationData, routeDetails]);

  const fetchToken = async () => {
    try {
      const response = await fetch(
        "https://test.api.amadeus.com/v1/security/oauth2/token",
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
          }),
          body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE", // <-- Post parameters
        }
      );

      const res = await response.json();
      if (res.access_token) {
        setApiToken(res.access_token);
      } else {
        alert(JSON.stringify(res));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const searchFrom = (val) => {
    setLocationFrom(val);
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${apiToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          const data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === data[i].iataCode) {
              localStorage.setItem("iatacode_from", data[i].iataCode);
              localStorage.setItem("airport_name_from", data[i].name);
              localStorage.setItem("country_from", data[i].address.countryCode);
              localStorage.setItem("city_from", data[i].address.cityName);
            }
          }
          setFromData(data);
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTo = (val) => {
    setLocationTo(val);
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${apiToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          const data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === data[i].iataCode) {
              localStorage.setItem("iatacode_to", data[i].iataCode);
              localStorage.setItem("airport_name_to", data[i].name);
              localStorage.setItem("country_to", data[i].address.countryCode);
              localStorage.setItem("city_to", data[i].address.cityName);
            }
          }
          setToData(data);
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGetAirlineIcons = async () => {
    const data = {
      filters: {},
    };
    const res = await ApiCall(
      "post",
      airplaneIconRelatedEndPoint.getAllAirplaneIcon,
      data
    );
    if (res?.success) {
      setAllIcons(res?.result);
    }
  };

  useEffect(() => {
    handleGetAirlineIcons();
  }, []);

  return (
    <div className="container pt-3 pb-5">
      <div className="row justify-content-center ">
        <div className="col-md-8">
          <div className="d-flex align-items-center gap-3 mb-3">
            <Link to="/route/routelist" className="btn btn-light border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </Link>
            <h4 className="text-uppercase text-center">
              {locationData?.routeId ? "Edit" : "Add New"} Route
            </h4>
          </div>
          {!routeLoader && (
            <form
              onSubmit={(e) => {
                if (locationData?.routeId) {
                  handleEditRoute(e);
                } else {
                  handleAddRoute(e);
                }
              }}
            >
              <div className="form-group">
                <label>Reference Name</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={referenceName}
                  onChange={(e) => setReferenceName(e.target.value)}
                />
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Origin</label>
                    <input
                      type="text"
                      maxLength={3}
                      className="form-control"
                      required
                      list="fromdata"
                      value={locationFrom}
                      onChange={(e) => searchFrom(e.target.value)}
                    />
                    <datalist id="fromdata">
                      {fromData?.length > 0 && (
                        <>
                          {fromData.map((item) => (
                            <option key={item.iataCode} value={item.iataCode}>
                              {item.name}, {item.address.cityName}{" "}
                              {item.address.countryName}
                            </option>
                          ))}
                        </>
                      )}
                    </datalist>
                    <small className="form-text text-muted">
                      Add 3 letter Origin Code In upper case like MIA or JFK
                      etc.
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Destination</label>
                    <input
                      type="text"
                      maxLength={3}
                      className="form-control"
                      list="todata"
                      required
                      value={locationTo}
                      onChange={(e) => searchTo(e.target.value)}
                    />
                    <datalist id="todata">
                      {toData?.length > 0 && (
                        <>
                          {toData.map((item) => (
                            <option key={item.iataCode} value={item.iataCode}>
                              {item.name}, {item.address.cityName}{" "}
                              {item.address.countryName}
                            </option>
                          ))}
                        </>
                      )}
                    </datalist>
                    <small className="form-text text-muted">
                      Add 3 letter Destination Code In upper case like MIA or
                      JFK etc.
                    </small>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Airlines</label>
                <select
                  required
                  class="form-control"
                  name="exampleSelect"
                  value={airlines}
                  onChange={(e) => setAirlines(e.target.value)}
                >
                  <option value="ALL">All</option>
                  {allIcons?.map((item) => {
                    return (
                      <option value={item?.airline_code}>
                        {item?.airline_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="row mb-2">
                <div className="col-lg-6 col-md-12">
                  <label>Meta Search</label>
                  <select
                    required
                    class="form-control"
                    name="exampleSelect"
                    value={metaSearch}
                    onChange={(e) => setMetaSearch(e.target.value)}
                  >
                    <option value="Kayak">Kayak</option>
                    <option value="D2C">D2C</option>
                  </select>
                </div>
                <div className="col-lg-6 col-md-12">
                  <label>Status</label>
                  <select
                    required
                    class="form-control"
                    name="exampleSelect"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <hr className="mb-4" />
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-danger mr-3 px-5"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-success px-5">
                  {loader ? (
                    <ApiLoader />
                  ) : locationData?.routeId ? (
                    "Edit"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      {routeLoader && <DataLoader />}
    </div>
  );
};

export default AddRoute;
