import React from "react";
import AssignedInquiry from "../../pages/OfferInquiry/AssignedInquiry/AssignedInquiry";

const AssignedInquiryRoutes = {
  path: "/offerinquiry",
  children: [
    {
      path: "assignedinquiry",
      element: <AssignedInquiry />,
    },
  ],
};

export default AssignedInquiryRoutes;
