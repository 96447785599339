import React from "react";
import AllAirlineIcons from "../../pages/AirlineIcons/AllAirlineIcons/AllAirlineIcons";
import AddAirlineIcon from "../../pages/AirlineIcons/AddAirlineIcon/AddAirlineIcon";

const AirlineIconsRoutes = {
  path: "/",
  children: [
    {
      path: "allairlineicons",
      element: <AllAirlineIcons />,
    },
    {
      path: "addairlineicons",
      element: <AddAirlineIcon />,
    },
  ],
};
export default AirlineIconsRoutes;
