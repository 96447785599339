import React from "react";
import OfferPageList from "../../pages/OfferPage/OfferPageList/OfferPageList";

const OfferPageRoutes = {
  path: "/offerpage",
  children: [
    {
      path: "offerpagelist",
      element: <OfferPageList />,
    },
  ],
};

export default OfferPageRoutes;
