import React, { useEffect, useState } from "react";
import ApiCall from "../../../api/ApiCall";
import { creationEndpoint } from "../../../api/Endpoints";
import { startApiCall } from "../../../Global/globalFunctions";
import DataLoader from "../../../components/Loader/DataLoader";

const AssigneeFilter = ({
  selectedEmpId,
  setSelectedEmpId,
}) => {
  const [loader, setloader] = useState(false);
  const [allEmployee, setAllEmployee] = useState([]);
  const [searchKey, setsearchKey] = useState("");

  const filteredEmployee = allEmployee?.filter((emp) =>
    emp?.name?.toLowerCase()?.includes(searchKey?.toLowerCase())
  );

  const handleGetAllStaff = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 400,
      filters: {
        role: "employee",
        isDeleted: false,
      },
    };
    const res = await ApiCall("post", creationEndpoint.getAllUsers, data);
    if (res?.success) {
      setAllEmployee(res?.result?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllStaff();
  }, []);

  return (
    <>
      <div className="row mt-2">
        <div className="col-10 mt-2">
          <div className="form-group">
            <label className="mb-2">
              Employee&nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                className="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>
            </label>
            <div className="dropdown form-control p-0">
              <button
                className="btn w-100 dropdown-toggle d-flex justify-content-between align-items-center bg-white"
                type="button"
                id="emp_name"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedEmpId?.name || "Select Employee"}
              </button>
              <div className="dropdown-menu" aria-labelledby="emp_name">
                <div className="px-1">
                  <input
                    type="text"
                    className="form-control"
                    value={searchKey}
                    onChange={(e) => setsearchKey(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    maxHeight: "30vh",
                    minWidth: "25vw",
                    overflowY: "scroll",
                    zIndex: 9999,
                  }}
                >
                  {filteredEmployee?.map((emp, index) => {
                    return (
                      <span
                        className="dropdown-item pointer"
                        onClick={() => setSelectedEmpId(emp)}
                      >
                        {index + 1} . {emp?.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssigneeFilter;
