import React from "react";
import AllActions from "../../pages/AllAction/AllActions";

const AllActionRoutes = {
  path: "/",
  children: [
    {
      path: "alllactions",
      element: <AllActions />,
    },
  ],
};

export default AllActionRoutes;
