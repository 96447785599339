import React from "react";

const Pagination = (props) => {
  const numbers = [10, 30, 50, 80, 100];
  const {
    page,
    setPage,
    pageLimit,
    setpageLimit,
    totalPages,
    count,
    dataLength,
    align,
  } = props;

  const handlePageChange = (isIncreament) => {
    setPage(isIncreament ? page + 1 : page - 1);
  };
  const handlePaginationCalculation = () => {
    let start = (page - 1) * pageLimit + 1;
    let end = start + dataLength - 1;
    let overall = `${start}-${end} of ${count}`;
    return overall;
  };

  return (
    <div className={`pagination-wrap justify-content-${align} pointer mr-4`}>
      <span className="text-dark mr-1">Row per page : </span>
      <select
        className="form-control mr-2 pointer"
        style={{ width: "75px" }}
        value={pageLimit}
        onChange={(event) => {
          setPage(1);
          setpageLimit(event.target.value);
        }}
      >
        {numbers?.map((no) => {
          return <option value={no}>{no}</option>;
        })}
      </select>
      <p className="text-dark mr-3 mt-2">{handlePaginationCalculation()}</p>
      <div className="row ml-1">
        <ul className="pagination custom-pagination pagination-simple mb-0 justify-content-center justify-content-md-end">
          <li
            onClick={() => {
              if (page !== 1) {
                handlePageChange(false);
              }
            }}
            className={`paginate_button page-item previous ${
              page === 1 ? "disabled" : ""
            }`}
          >
            <span className="page-link text-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="currentColor"
              >
                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
              </svg>
            </span>
          </li>
          <li
            onClick={() => {
              if (totalPages !== page) {
                handlePageChange(true);
              }
            }}
            className={`paginate_button page-item next ${
              totalPages === page ? "disabled" : ""
            }`}
          >
            <span className="page-link text-dark">
              {/* <i className="ri-arrow-right-s-line"></i> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                fill="currentColor"
              >
                <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
