import React from "react";

const StatusFilter = ({ setStatus, status, allStatus }) => {
  return (
    <div className="row gx-3 mt-3">
      <div className="col-sm-12">
        <div className="form-group">
          <label className="form-label">Status</label>
          <select
            className="form-control"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">All</option>
            {allStatus?.map((st) => {
              return <option value={st?.name}>{st?.name}</option>;
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default StatusFilter;
