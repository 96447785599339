import React, { useEffect, useState } from "react";
import AddPhoneFare from "./AddPhoneFare";
import { startApiCall } from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { PhoneFareRelatedEndPoint } from "../../api/Endpoints";
import DataLoader from "../../components/Loader/DataLoader";

const AllPhoneFares = () => {
  const [loader, setloader] = useState(false);
  const [allPhoneFares, setAllPhoneFares] = useState([]);
  const [addFare, setAddFare] = useState(false);
  const [editPhoneFare, setEditPhoneFare] = useState("");

  const handleGetAllPhoneFares = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {},
    };
    const res = await ApiCall(
      "post",
      PhoneFareRelatedEndPoint?.getAllPhoneFares,
      data
    );
    if (res?.success) {
      setAllPhoneFares(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllPhoneFares();
  }, []);

  return (
    <div className="container pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">
            All Phone Fares
          </h4>
          <span
            className="btn btn-outline-primary text-capitalize"
            onClick={() => setAddFare(true)}
          >
            Add Phone Fare
          </span>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllPhoneFares();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
        </form>
      </div>
      <div className="d-flex flex-column justify-content-between">
        <div className="table-responsive scrollbar-hide pb-4">
          {!loader && (
            <table className="table table-striped mb-0 text-center text-nowrap">
              <thead>
                <tr>
                  <th scope="col">Sr no.</th>
                  <th scope="col">Airlines</th>
                  <th scope="col">Booking Class</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {allPhoneFares?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}.</td>
                    <td className="text-uppercase">{item?.airline}</td>
                    <td className="text-capitalize">{item?.booking_class}</td>
                    <td>
                      <span
                        className="btn bg-white border"
                        onClick={() => {
                          setEditPhoneFare(item);
                          setAddFare(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                        </svg>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {loader && <DataLoader />}
        </div>
      </div>
      {addFare && (
        <AddPhoneFare
          setAddFare={setAddFare}
          editPhoneFare={editPhoneFare}
          handleGetAllPhoneFares={handleGetAllPhoneFares}
          setEditPhoneFare={setEditPhoneFare}
        />
      )}
    </div>
  );
};

export default AllPhoneFares;
