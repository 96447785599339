import {
  LOGINUSER,
  GETUSER,
  LOGOUT,
  PERMISSIONMODULES,
  USERMODULESWITHPERSMISSIONS,
} from "./actions/auth.actions";

export const authReducer = (state, action) => {
  // console.log("Action:", action); // Log the action
  // console.log("Previous State:", state); // Log the previous state
  switch (action.type) {
    case LOGINUSER:
      localStorage.setItem(
        "accessToken",
        JSON.stringify(action.payload.tokens.access.token)
      );
      localStorage.setItem(
        "refreshTokenForOguleAdmin",
        JSON.stringify(action.payload.tokens.refresh.token)
      );
      return { user: action.payload };
    case GETUSER:
      return { ...state, user: action.payload };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        user: null,
      };
    case PERMISSIONMODULES:
      return {
        ...state,
        permissionmodules: action.payload,
      };
    case USERMODULESWITHPERSMISSIONS:
      return {
        ...state,
        usermoduleswithpermissions: action.payload,
      };
    default:
      return state;
  }
};
