import React from "react";
import AllPayments from "../../pages/Payment/AllPayments/AllPayments";
import AddPayment from "../../pages/Payment/AddPayment/AddPayment";

const PaymentRoutes = {
  path: "/",
  children: [
    {
      path: "addpayment",
      element: <AddPayment />,
    },
    {
      path: "allpayments",
      element: <AllPayments />,
    },
  ],
};
export default PaymentRoutes;
