import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  reverseDateFormatter,
  startApiCall,
} from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  NilhansUserCardRelatedEndPoint,
  UserCardRelatedEndPoint,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ToasterMessages from "../../../Global/ToasterMessages";
import ApiLoader from "../../../components/Loader/ApiLoader";
import DataLoader from "../../../components/Loader/DataLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";

const AddUserCard = () => {
  const { id } = useParams();
  var date = reverseDateFormatter(new Date());
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [cardLoader, setcardLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvvNo, setCvvNo] = useState("");
  const [name, setName] = useState("");
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const isAddCard =
    (localStorage.getItem("urltype") === "Nilhans" || localStorage.getItem("urltype") === "BestAirFares")
      ? NilhansUserCardRelatedEndPoint.addCard
      : UserCardRelatedEndPoint.addCard;
  const isEditCard =
    (localStorage.getItem("urltype") === "Nilhans" || localStorage.getItem("urltype") === "BestAirFares")
      ? NilhansUserCardRelatedEndPoint.editCard
      : UserCardRelatedEndPoint.editCard;
  const isCradDetails =
    (localStorage.getItem("urltype") === "Nilhans" || localStorage.getItem("urltype") === "BestAirFares")
      ? NilhansUserCardRelatedEndPoint.getCardById
      : UserCardRelatedEndPoint.getCardById;

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setCardName("");
    setCardNo("");
    setExpDate("");
    setCvvNo("");
    setName("");
  };

  const handleAddCard = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      user_id: id,
      card_name: cardName,
      card_no: cardNo,
      card_expiry_date: expDate,
      card_cvv: cvvNo,
      name_on_card: name,
    };
    const res = await ApiCall("post", isAddCard, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditCard = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      cardId: locationData?.cardId,
      user_id: id,
      card_name: cardName,
      card_no: cardNo,
      card_expiry_date: expDate,
      card_cvv: cvvNo,
      name_on_card: name,
    };
    const res = await ApiCall("post", isEditCard, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetCardDetails = async () => {
    startApiCall(null, setcardLoader);
    const data = {
      cardId: locationData?.cardId,
    };
    const res = await ApiCall("post", isCradDetails, data);
    if (res?.success) {
      setCardDetails(res?.result);
      setcardLoader(false);
    } else {
      setcardLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.cardId) {
      handleGetCardDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.cardId) {
      setCardName(cardDetails?.card_name);
      setCardNo(cardDetails?.card_no);
      setExpDate(reverseDateFormatter(cardDetails?.card_expiry_date));
      setCvvNo(cardDetails?.card_cvv);
      setName(cardDetails?.name_on_card);
    }
  }, [locationData, cardDetails]);

  const handleChangeNumber = (event) => {
    const inputValue = event.target.value.replace(/[^\d]/g, "");
    const formattedValue = inputValue.replace(/(\d{4})(?=\d)/g, "$1 ").trim();
    setCardNo(formattedValue);
  };

  const handleChangeExpDate = (event) => {
    const { value } = event.target;
    let formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{0,2})/, "$1/$2")
      .slice(0, 5);

    if (formattedValue.length === 3 && value.length < formattedValue.length) {
      formattedValue = formattedValue.slice(0, 2);
    }
    const currentYear = new Date().getFullYear() % 100;
    const month = parseInt(formattedValue.slice(0, 2), 10);
    const year = parseInt(formattedValue.slice(3, 5), 10);
    if (month < 1 || month > 12) {
      formattedValue = formattedValue.slice(0, 2);
    }
    if (formattedValue.length === 5 && year < currentYear) {
      formattedValue = formattedValue.slice(0, 3);
    }
    setExpDate(formattedValue);
  };

  const handleChangeCvv = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, "").slice(0, 4);
    setCvvNo(formattedValue);
  };

  return (
    <div className="container pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex align-items-center gap-3 mb-3">
            <span
              className="btn btn-light border"
              onClick={() =>
                navigate(`/customer/allusercard/${id}`, {
                  state: { userId: locationData?.userId },
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </span>
            <h4 className="text-uppercase text-center">
              {locationData?.cardId ? "Edit" : "Add New"} Card
            </h4>
          </div>

          {!cardLoader && (
            <form
              onSubmit={(e) => {
                if (locationData?.cardId) {
                  handleEditCard(e);
                } else {
                  handleAddCard(e);
                }
              }}
            >
              <div className="row justify-content-center ">
                <div className="col-md-8">
                  <div className="form-group">
                    <label>Card name</label>
                    <select
                      required
                      class="form-control"
                      name="exampleSelect"
                      value={cardName}
                      onChange={(e) => {
                        setCardName(e.target.value);
                        setCardNo("");
                        setExpDate("");
                        setCvvNo("");
                      }}
                    >
                      <option value="">Select Card</option>
                      <option value="American express">American express</option>
                      <option value="Master card">Master card</option>
                      <option value="Visa">Visa</option>
                      <option value="Discover">Discover</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Card Number</label>
                    <input
                      className="form-control"
                      id="ccn"
                      type="tel"
                      inputmode="numeric"
                      pattern="[0-9\s]{13,19}"
                      autocomplete="cc-number"
                      required
                      disabled={!cardName}
                      maxlength={cardName === "American express" ? "18" : "19"}
                      placeholder={
                        cardName === "American express"
                          ? "xxxx xxxx xxxx xxx"
                          : "xxxx xxxx xxxx xxxx"
                      }
                      value={cardNo}
                      onChange={handleChangeNumber}
                    />
                  </div>
                  <div className="form-group">
                    <label>Card Expiry Date</label>
                    <input
                      className="form-control"
                      type="text"
                      id="expiry-date"
                      name="expiry-date"
                      disabled={!cardName}
                      value={expDate}
                      onChange={handleChangeExpDate}
                      maxLength="5"
                      placeholder="MM/YY"
                    />
                  </div>
                  <div className="form-group">
                    <label>Card CVV</label>
                    <input
                      className="form-control"
                      type="text"
                      id="cvv"
                      name="cvv"
                      maxlength={cardName === "American express" ? "4" : "3"}
                      pattern="\d{3,4}"
                      disabled={!cardName}
                      placeholder={
                        cardName === "American express" ? "xxxx" : "xxx"
                      }
                      required
                      value={cvvNo}
                      onChange={handleChangeCvv}
                    />
                  </div>
                  <div className="form-group">
                    <label>Name On Card</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                  <div className="d-flex justify-content-end">
                    <button type="reset" className="btn btn-danger mr-3 px-5">
                      Reset
                    </button>
                    <button type="submit" className="btn btn-success px-5">
                      {loader ? (
                        <ApiLoader />
                      ) : locationData?.cardId ? (
                        "Edit"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      {cardLoader && <DataLoader />}
    </div>
  );
};

export default AddUserCard;
