import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  handlePreviewFileInLocal,
  handleUploadImgFile,
  reverseDateFormatter,
  startApiCall,
} from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import ToasterMessages from "../../../Global/ToasterMessages";
import {
  BlogRelatedEndPoint,
  CategoryRelatedEndPoint,
  NilhansBlogRelatedEndPoint,
  TagRelatedEndPoint,
} from "../../../api/Endpoints";
import ApiLoader from "../../../components/Loader/ApiLoader";
import DataLoader from "../../../components/Loader/DataLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { AuthContext } from "../../../contextApi/context";

const AddBlog = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [blogLoader, setblogLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("publish");
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [blogDetails, setBlogDetails] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState("select category");
  const [allTags, setAllTags] = useState([]);
  const [selectTag, setSelectTag] = useState("select tag");
  const [image, setimage] = useState(null);
  const isAddBlog =
    localStorage.getItem("urltype") === "Nilhans" ||
    localStorage.getItem("urltype") === "BestAirFares"
      ? NilhansBlogRelatedEndPoint.addBlog
      : BlogRelatedEndPoint.addBlog;
  const isEditBlog =
    localStorage.getItem("urltype") === "Nilhans" ||
    localStorage.getItem("urltype") === "BestAirFares"
      ? NilhansBlogRelatedEndPoint.editBlog
      : BlogRelatedEndPoint.editBlog;

  const setFileForUpload = (event) => {
    setimage(event);
  };

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handlePreviewFileInLocalEdit = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const img = URL.createObjectURL(event.target.files[0]);
      return img;
    } else if (blogDetails?.image) {
      // Return the URL of the existing image if no new file is selected
      return blogDetails?.image;
    } else {
      return null;
    }
  };

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setTitle("");
    setSlug("");
    setShortDescription("");
    setDescription("");
    setDate("");
    setStatus("publish");
    setimage(null);
  };

  const handleAddBlog = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    let uploadImg = await handleUploadImgFile(image);

    const data = {
      slug,
      name: title,
      actionBy: user?._id,
      short_description: shortDescription,
      description,
      publish_date: date,
      status,
      category_id: selectCategory,
      tag_id: selectTag,
      image: uploadImg,
    };
    const res = await ApiCall("post", isAddBlog, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditBlog = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);

    let uploadImg = await handleUploadImgFile(image);

    const data = {
      blogId: locationData?.blogId,
      slug,
      name: title,
      actionBy: user?._id,
      short_description: shortDescription,
      description,
      publish_date: date,
      status,
      image: uploadImg,
      // category_id: selectCategory,
      // tag_id: selectTag,
    };
    const res = await ApiCall("post", isEditBlog, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetBlogDetails = async () => {
    startApiCall(null, setblogLoader);
    const data = {
      blogId: locationData?.blogId,
    };
    const res = await ApiCall("post", BlogRelatedEndPoint.getBlogById, data);
    if (res?.success) {
      setBlogDetails(res?._doc);
      setblogLoader(false);
    } else {
      setblogLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.blogId) {
      handleGetBlogDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.blogId) {
      setTitle(blogDetails?.name);
      setSlug(blogDetails?.slug);
      setShortDescription(blogDetails?.short_description);
      setDescription(blogDetails?.description);
      setDate(reverseDateFormatter(blogDetails?.publish_date));
      setStatus(blogDetails?.status);
      setimage(blogDetails?.image ? blogDetails?.image : "");
    }
  }, [locationData, blogDetails]);

  const handleGetAllCategories = async () => {
    const data = {
      filters: {},
    };
    const res = await ApiCall(
      "post",
      CategoryRelatedEndPoint.getAllCategories,
      data
    );
    if (res?.success) {
      setAllCategory(res?.result);
    }
  };

  const handleGetAllTags = async () => {
    const data = {
      filters: {},
    };
    const res = await ApiCall("post", TagRelatedEndPoint.getAllTags, data);
    if (res?.success) {
      setAllTags(res?.result);
    }
  };

  useEffect(() => {
    handleGetAllCategories();
    handleGetAllTags();
  }, []);

  return (
    <div className="container pt-3 pb-4">
      <div className="d-flex align-items-center gap-3 mb-3">
        <Link to="/blog/bloglist" className="btn btn-light border">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-bar-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
            />
          </svg>
        </Link>
        <h4 className="text-uppercase mb-0">
          {locationData?.blogId ? "Edit" : "Add"} blog
        </h4>
      </div>
      {!blogLoader && (
        <form
          onSubmit={(e) => {
            if (locationData?.blogId) {
              handleEditBlog(e);
            } else {
              handleAddBlog(e);
            }
          }}
        >
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Slug</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Short Description</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter Description"
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Enter Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              {!locationData?.blogId && (
                <div className="row">
                  <div className="col">
                    <div class="form-group">
                      <label>Category</label>
                      <select
                        class="form-control"
                        value={selectCategory}
                        onChange={(e) => setSelectCategory(e.target.value)}
                      >
                        <option className="select category">
                          Select Category
                        </option>
                        {allCategory?.map((data) => {
                          return (
                            <option value={data?._id}>{data?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <div class="form-group">
                      <label>Tags</label>
                      <select
                        class="form-control"
                        value={selectTag}
                        onChange={(e) => setSelectTag(e.target.value)}
                      >
                        <option className="select tag">Select Tag</option>
                        {allTags?.map((data) => {
                          return (
                            <option value={data?._id}>{data?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Image</label>
                <div className="image-upload-container m-auto">
                  {image ? (
                    <div className="image-container">
                      <img
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        src={
                          locationData === ""
                            ? handlePreviewFileInLocal(image)
                            : handlePreviewFileInLocalEdit(image)
                        }
                        alt="Selected"
                        style={{ objectFit: "contain" }}
                      />
                      <button
                        className="remove-image-button"
                        onClick={() => setimage(null)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-lg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <input
                        className="image-upload-input pointer"
                        type="file"
                        name="imageUpload"
                        accept="image/png,image/jpeg"
                        onChange={setFileForUpload}
                      />
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="42"
                          height="42"
                          fill="currentColor"
                          className="bi bi-upload text-secondary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                        </svg>
                        <span className="image-upload-label">
                          Click to upload
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Publish date</label>
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Status</label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="publish"
                    checked={status === "publish"}
                    onChange={() => setStatus("publish")}
                  />
                  <label className="form-check-label" htmlFor="publish">
                    Publish
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="hidden"
                    checked={status === "hidden"}
                    onChange={() => setStatus("hidden")}
                  />
                  <label className="form-check-label" htmlFor="hidden">
                    Hidden
                  </label>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <hr className="mb-4" />

          <div className="d-flex justify-content-end">
            <button className="btn btn-danger mr-3 px-5" onClick={handleReset}>
              Reset
            </button>
            <button type="submit" className="btn btn-success px-5">
              {loader ? <ApiLoader /> : locationData?.blogId ? "Edit" : "Save"}
            </button>
          </div>
        </form>
      )}
      {blogLoader && <DataLoader />}
    </div>
  );
};

export default AddBlog;
