// import axios from "axios";
// import { ACTIVE_VERSION, BASE_URL } from "./base";
// import { resolveErrorMessage } from "./service";
// import { toast } from "react-toastify";

// const API = axios.create({ baseURL: BASE_URL });

// // API.interceptors.request.use((req) => {
// //   if (localStorage.getItem("refreshTokenForTaskManager")) {
// //     req.headers.token = localStorage.getItem("refreshTokenForTaskManager");
// //   }
// //   req.headers.version = ACTIVE_VERSION;
// //   req.headers.platform = "dashboard";
// //   return req;
// // });

// const ApiCall = async (type, route, data) => {
//   try {
//     let response;
//     const apiFunctions = {
//       post: API.post,
//       get: API.get,
//       patch: API.patch,
//       delete: API.delete,
//       put: API.put,
//     };
//       response = await apiFunctions[type](route, data)
//     var success = true;
//     if (response?.data?.success) {
//       return response?.data;
//     }
//   } catch (error) {
//     success = false;
//     toast.error(
//       `Something went wrong, ${resolveErrorMessage(error)}`
//     );
//     return {
//       success,
//       error: resolveErrorMessage(error),
//       status: error?.response?.status,
//     };
//   }
// };

// export default ApiCall;

import axios from "axios";
import { toast } from "react-toastify";
import { publicIpv4 } from "public-ip";
import { BASE_URL } from "./base";
import { resolveErrorMessage } from "./service";

const API = axios.create({ baseURL: BASE_URL });

// Function to fetch IP address asynchronously
const fetchIpAddress = async () => {
  try {
    let ip = sessionStorage.getItem("ipAddress");
    if (!ip) {
      ip = await publicIpv4();
      sessionStorage.setItem("ipAddress", ip);
    }
    return ip;
  } catch (error) {
    console.error("Failed to fetch IP address:", error);
    return null;
  }
};

// Axios interceptors to set headers
API.interceptors.request.use(async (req) => {
  const ip = await fetchIpAddress();
  const isAuthOperation = req.headers.operation === "login" || req.headers.operation === "logout";

  if (!isAuthOperation && localStorage.getItem("refreshTokenForOguleAdmin")) {
    req.headers.authorization = JSON.parse(localStorage.getItem("refreshTokenForOguleAdmin"));
  }
  if (ip) {
    req.headers.ip = ip;
  } else {
    req.headers.ip = "unknown";
  }
  
  if (isAuthOperation) {
    req.headers.operation = req.headers.operation;
  }
  req.headers.platform = "admin_portal";
  return req;
});

const ApiCall = async (type, route, data, customHeaders = {}) => {
  try {
    let response;
    const apiFunctions = {
      post: API.post,
      get: API.get,
      patch: API.patch,
      delete: API.delete,
      put: API.put,
    };

    const originalHeaders = { ...API.defaults.headers.common };
    API.defaults.headers.common = { ...API.defaults.headers.common, ...customHeaders };

    response = await apiFunctions[type](route, data);

    API.defaults.headers.common = originalHeaders;
    if (response?.data?.success) {
      return response?.data;
    }
  } catch (error) {
    toast.error(`Something went wrong, ${resolveErrorMessage(error)}`);
    return {
      success: false,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default ApiCall;
