import React from "react";
import CreateNewForm from "../../../components/CreateNewForm/CreateNewForm";

const AddCustomer = () => {
  return (
    <CreateNewForm
      title="Add Customer"
      showRoleSection={false}
      type="customer"
      // onSubmit={handleFormSubmit}
    />
  );
};

export default AddCustomer;
