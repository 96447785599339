import React, { useState } from "react";
import ApiLoader from "../../../../components/Loader/ApiLoader";
import allCountries from "../../../../components/Countries";

const BillingSection = ({
  cardDetails,
  setcardDetails,
  billingDetails,
  setbillingDetails,
  handleAddBooking,
  loader,
  isTravelerDisabled,
  isBillingDisabled,
  isCardDisabled,
}) => {
  const [search, setSearch] = useState("");
  const [search1, setSearch2] = useState("");
  const filteredCountries = allCountries?.filter((con) =>
    con?.country?.toLowerCase()?.includes(search?.toLowerCase())
  );
  const filteredCountryCode = allCountries?.filter(
    (con) =>
      con?.code?.includes(search1) ||
      con?.country?.toLowerCase()?.includes(search1?.toLowerCase())
  );

  function isValidCardNumber(cardNumber) {
    if (!cardNumber) return false;
    const cleanedNumber = cardNumber.replace(/\s/g, "");
    const startsWithCorrectDigit = {
      "American express": cleanedNumber.startsWith("3"),
      Discover: cleanedNumber.startsWith("6"),
      "Master card": cleanedNumber.startsWith("5"),
      Visa: cleanedNumber.startsWith("4"),
    }[cardDetails?.name];

    const validLength =
      cardDetails?.name === "American express"
        ? cleanedNumber.length === 15
        : cleanedNumber.length === 16;

    return startsWithCorrectDigit && validLength && /^\d+$/.test(cleanedNumber);
  }

  function isValidExpDate(expDate) {
    if (!expDate) return false;
    const [month, year] = expDate.split("/");
    const currentYear = new Date().getFullYear() % 100;
    return (
      month >= "01" &&
      month <= "12" &&
      year >= currentYear.toString() &&
      year <= (currentYear + 30).toString()
    );
  }

  function isValidCVV(cvv, cardName) {
    if (!cvv) return false;
    if (cardName === "American express") {
      return cvv.length === 4 && /^\d+$/.test(cvv);
    } else {
      return cvv.length === 3 && /^\d+$/.test(cvv);
    }
  }

  const isCardNoLength =
    cardDetails?.name === "American express"
      ? cardDetails?.number?.length >= 18
      : cardDetails?.number?.length >= 19;

  const handleChange = (event) => {
    const { value } = event.target;
    let formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{0,2})/, "$1/$2")
      .slice(0, 5);

    if (formattedValue.length === 3 && value.length < formattedValue.length) {
      formattedValue = formattedValue.slice(0, 2);
    }
    const currentYear = new Date().getFullYear() % 100;
    const month = parseInt(formattedValue.slice(0, 2), 10);
    const year = parseInt(formattedValue.slice(3, 5), 10);
    if (month < 1 || month > 12) {
      formattedValue = formattedValue.slice(0, 2);
    }
    if (formattedValue.length === 5 && year < currentYear) {
      formattedValue = formattedValue.slice(0, 3);
    }
    setcardDetails({
      ...cardDetails,
      expDate: formattedValue,
    });
  };

  const handleChangeCvv = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, "").slice(0, 4);
    setcardDetails({
      ...cardDetails,
      cvv: formattedValue,
    });
  };

  const handleChangeNumber = (event) => {
    const inputValue = event.target.value.replace(/[^\d]/g, "");
    const formattedValue = inputValue.replace(/(\d{4})(?=\d)/g, "$1 ").trim();

    setcardDetails({
      ...cardDetails,
      number: formattedValue,
    });
  };

  const handleBillingDetails = (e) => {
    const { name, value } = e.target;
    // if (name === "phone" && value.length > 10) return;
    if (name === "phone") {
      if (/^\d*$/.test(value)) {
        setbillingDetails({
          ...billingDetails,
          phone: value,
        });
      }
    } else {
      setbillingDetails({
        ...billingDetails,
        [name]: value,
      });
    }
  };

  const handleCountrySelect = (code) => {
    setbillingDetails({ ...billingDetails, countrycode: code });
  };

  const handleClickCountry = (country) => {
    setbillingDetails({
      ...billingDetails,
      country: country,
    });
  };

  return (
    <>
      <h4>Card Details</h4>
      <hr />
      <div className="row g-3 mb-3">
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Card Name{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
              :
            </label>
            <select
              className="form-control"
              value={cardDetails?.name}
              onChange={(event) => {
                setcardDetails({
                  ...cardDetails,
                  name: event.target.value,
                  number: "",
                  expDate: "",
                  cvv: "",
                });
              }}
            >
              <option value="">Select Card</option>
              <option value="American express">American express</option>
              <option value="Master card">Master card</option>
              <option value="Visa">Visa</option>
              <option value="Discover">Discover</option>
            </select>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Card Holder Name{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
              :{" "}
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={cardDetails?.holderName}
              onChange={(event) =>
                setcardDetails({
                  ...cardDetails,
                  holderName: event.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Card Number{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
              :
            </label>
            <div className="input-with-icon">
              <input
                className="form-control"
                disabled={!cardDetails.name}
                id="ccn"
                type="tel"
                inputmode="numeric"
                pattern="[0-9\s]{13,19}"
                autocomplete="cc-number"
                maxlength={
                  cardDetails?.name === "American express" ? "18" : "19"
                }
                placeholder={
                  cardDetails?.name === "American express"
                    ? "xxxx xxxx xxxx xxx"
                    : "xxxx xxxx xxxx xxxx"
                }
                required
                value={cardDetails?.number}
                onChange={handleChangeNumber}
              />
              {cardDetails?.number &&
                isCardNoLength &&
                (isValidCardNumber(cardDetails?.number) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x text-danger"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                ))}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="form-group">
            <label className="mb-2">
              Card Expiry Date{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>
            </label>
            <div className="input-with-icon">
              <input
                className="form-control"
                type="text"
                id="expiry-date"
                name="expiry-date"
                disabled={!cardDetails.name}
                value={cardDetails?.expDate}
                onChange={handleChange}
                maxLength="5"
                placeholder="MM/YY"
              />
              {isValidExpDate(cardDetails?.expDate) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                  </svg>
                )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              CVV{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
              :
            </label>
            <div className="input-with-icon">
              <input
                className="form-control"
                type="text"
                id="cvv"
                name="cvv"
                maxlength={cardDetails.name === "American express" ? "4" : "3"}
                pattern="\d{3,4}"
                disabled={!cardDetails.name}
                placeholder={
                  cardDetails.name === "American express" ? "xxxx" : "xxx"
                }
                required
                value={cardDetails.cvv}
                onChange={handleChangeCvv}
              />
              {isValidCVV(cardDetails.cvv, cardDetails.name) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                  </svg>
                )}
            </div>
          </div>
        </div>
      </div>

      <h4 className="mt-2">Billing Details</h4>
      <hr />
      <div className="row g-3">
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Name{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              required
              value={billingDetails?.name}
              onChange={handleBillingDetails}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Email Address{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              required
              value={billingDetails?.email}
              onChange={handleBillingDetails}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Country&nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                className="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>
            </label>
            <div className="dropdown form-control p-0">
              <button
                className="btn w-100 dropdown-toggle d-flex justify-content-between align-items-center bg-white"
                type="button"
                id="country_name"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {billingDetails?.country || "Choose Country"}
              </button>
              <div className="dropdown-menu" aria-labelledby="country_name">
                <div className="px-1">
                  <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div style={{ maxHeight: "30vh", overflowY: "scroll" }}>
                  {filteredCountries?.map((con) => (
                    <span
                      key={con?.country}
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickCountry(con?.country);
                      }}
                    >
                      {con?.flag} &nbsp;
                      {con?.country}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Contact No.&nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                className="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>
            </label>
            <div className="input-group">
              <div
                className="dropdown border"
                style={{
                  borderTopLeftRadius: "0.25rem",
                  borderBottomLeftRadius: "0.25rem",
                }}
              >
                <button
                  className="btn dropdown-toggle bg-white form-control"
                  type="button"
                  id="country_code"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {billingDetails?.countrycode || "Code"}
                </button>
                <div className="dropdown-menu" aria-labelledby="country_code">
                  <div className="px-1">
                    <input
                      type="text"
                      className="form-control"
                      value={search1}
                      onChange={(e) => setSearch2(e.target.value)}
                    />
                  </div>
                  <div style={{ maxHeight: "30vh", overflowY: "scroll" }}>
                    {filteredCountryCode?.map((con) => (
                      <span
                        className="dropdown-item"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCountrySelect(con?.code);
                        }}
                      >
                        {con?.flag} &nbsp; {con?.code} &nbsp; {con?.country}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <input
                type="tel"
                inputmode="numeric"
                className="form-control"
                aria-describedby="contact_number"
                name="phone"
                required
                value={billingDetails?.phone}
                onChange={handleBillingDetails}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Address{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              name="address"
              required
              value={billingDetails?.address}
              onChange={handleBillingDetails}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              State{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              name="state"
              required
              value={billingDetails?.state}
              onChange={handleBillingDetails}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              City{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              name="city"
              required
              value={billingDetails?.city}
              onChange={handleBillingDetails}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label className="mb-2">
              Zip{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                fill="currentColor"
                class="bi bi-asterisk mb-2 text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
              </svg>{" "}
            </label>
            <input
              type="number"
              className="form-control"
              name="zip"
              required
              value={billingDetails?.zip}
              onChange={handleBillingDetails}
            />
          </div>
        </div>
      </div>
      <div
        className="row d-flex justify-content-end"
        style={{ marginTop: -20, marginRight: 0 }}
      >
        <div className="form-group me-2">
          <label>&nbsp;</label>
          <button
            type="submit"
            className="form-control btn btn-primary"
            onClick={handleAddBooking}
            disabled={
              isBillingDisabled ||
              isCardDisabled ||
              isTravelerDisabled ||
              loader ||
              !isValidCardNumber(cardDetails?.number)
            }
          >
            <div className="d-flex align-items-center justify-content-center gap-2">
              {loader ? <ApiLoader /> : "Save"}
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default BillingSection;
