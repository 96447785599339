import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiLoader from "../../../../components/Loader/ApiLoader";
import { startApiCall } from "../../../../Global/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import {
  MarkupRelatedEndPoint,
  airplaneIconRelatedEndPoint,
} from "../../../../api/Endpoints";
import ToasterMessages from "../../../../Global/ToasterMessages";
import { toast } from "react-toastify";
import DataLoader from "../../../../components/Loader/DataLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import { AuthContext } from "../../../../contextApi/context";

const AddMarkup = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const navigate = useNavigate();
  const { routeid } = useParams();
  const [loader, setloader] = useState(false);
  const [offerLoader, setofferLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [trvlStartDate, setTrvlStartDate] = useState("");
  const [trvlEndDate, setTrvlEndDate] = useState("");
  const [inBound, setInBound] = useState(
    "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z"
  );
  const [outBound, setOutBound] = useState(
    "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z"
  );
  const [markupType, setMarkupType] = useState("fixed");
  const [markupAmount, setMarkupAmount] = useState("fixed");
  const [airlines, setAirlines] = useState("ALL");
  const [allIcons, setAllIcons] = useState([]);
  const [dta, setDta] = useState("");
  const [px1, setPx1] = useState("");
  const [px2, setPx2] = useState("");
  const [px3, setPx3] = useState("");
  const [px4, setPx4] = useState("");
  const [px5, setPx5] = useState("");
  const [px6, setPx6] = useState("");
  const [px7, setPx7] = useState("");
  const [px8, setPx8] = useState("");
  const [px9, setPx9] = useState("");

  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [markUpDetails, setMarkUpDetails] = useState([]);

  const [blackoutDates, setBlackoutDates] = useState([]);
  const [blackoutDate, setBlackoutDate] = useState("");

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleGetAirlineIcons = async () => {
    const data = {
      filters: {},
    };
    const res = await ApiCall(
      "post",
      airplaneIconRelatedEndPoint.getAllAirplaneIcon,
      data
    );
    if (res?.success) {
      setAllIcons(res?.result);
    }
  };

  useEffect(() => {
    handleGetAirlineIcons();
  }, []);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setAirlines("ALL")
    setTrvlStartDate("");
    setTrvlEndDate("");
    setInBound("");
    setOutBound("");
    setMarkupType("fixed");
    setMarkupAmount("");
    setDta("");
    setPx1("");
    setPx2("");
    setPx3("");
    setPx4("");
    setPx5("");
    setPx6("");
    setPx7("");
    setPx8("");
    setPx9("");
  };

  const handleAddMarkupTool = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      route_id: routeid,
      actionBy: user?._id,
      one_px: (!px2 && !px3 && !px4 && !px5 && !px6 && !px7 && !px8 && !px9) ? markupAmount : px1,
      two_px: px2,
      three_px: px3,
      four_px: px4,
      five_px: px5,
      six_px: px6,
      seven_px: px7,
      eight_px: px8,
      nine_px: px9,
      inbound_classes: inBound,
      outbound_classes: outBound,
      markup_amount: markupAmount,
      markup_type: markupType,
      start_active_date: trvlStartDate,
      end_active_date: trvlEndDate,
      dta,
    };
    const res = await ApiCall("post", MarkupRelatedEndPoint.addMarkup, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditMarkupTool = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      markup_toolId: locationData?.markUpId,
      actionBy: user?._id,
      one_px: px1,
      two_px: px2,
      three_px: px3,
      four_px: px4,
      five_px: px5,
      six_px: px6,
      seven_px: px7,
      eight_px: px8,
      nine_px: px9,
      inbound_classes: inBound,
      outbound_classes: outBound,
      markup_amount: markupAmount,
      markup_type: markupType,
      start_active_date: trvlStartDate,
      end_active_date: trvlEndDate,
      dta,
    };
    const res = await ApiCall(
      "post",
      MarkupRelatedEndPoint.editMarkupTool,
      data
    );
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetMarkupDetails = async () => {
    startApiCall(null, setofferLoader);
    const data = {
      markup_toolId: locationData?.markUpId,
    };
    const res = await ApiCall(
      "post",
      MarkupRelatedEndPoint.getMarkupToolById,
      data
    );
    if (res?.success) {
      setMarkUpDetails(res?.result);
      setofferLoader(false);
    } else {
      setofferLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.markUpId) {
      handleGetMarkupDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.markUpId) {
      setTrvlStartDate(markUpDetails?.start_active_date);
      setTrvlEndDate(markUpDetails?.end_active_date);
      setInBound(markUpDetails?.inbound_classes);
      setOutBound(markUpDetails?.outbound_classes);
      setDta(markUpDetails?.dta);
      setMarkupType(markUpDetails?.markup_type);
      setMarkupAmount(markUpDetails?.markup_amount);
      setPx1(markUpDetails?.one_px);
      setPx2(markUpDetails?.two_px);
      setPx3(markUpDetails?.three_px);
      setPx4(markUpDetails?.four_px);
      setPx5(markUpDetails?.five_px);
      setPx6(markUpDetails?.six_px);
      setPx7(markUpDetails?.seven_px);
      setPx8(markUpDetails?.eight_px);
      setPx9(markUpDetails?.nine_px);
    }
  }, [locationData, markUpDetails]);

  const handleDateChange = (e) => {
    setBlackoutDate(e.target.value);
  };

  const handleAddBlackoutDate = (e) => {
    e.preventDefault();
    if (blackoutDate) {
      setBlackoutDates([...blackoutDates, blackoutDate]);
      setBlackoutDate("");
    }
  };

  const handleRemoveBlackoutDate = (index) => {
    const updatedDates = blackoutDates.filter((_, i) => i !== index);
    setBlackoutDates(updatedDates);
  };

  return (
    <div className="px-3 pt-3 pb-5">
      <div className="d-flex align-items-center gap-3 mb-3 sticky-header">
        <span
          onClick={() =>
            navigate(`/route/markuplist/${routeid}`, {
              state: {
                // userId: locationData?.userId,
                route: locationData?.route,
              },
            })
          }
          className="btn btn-light border"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-bar-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
            />
          </svg>
        </span>
        <h4 className="text-uppercase mb-0">
          {locationData?.markUpId ? "Edit" : "Add New"} Markup
        </h4>
      </div>

      <div className="card">
        <div className="card-header" style={{ backgroundColor: "#def0d8" }}>
          <h5 className="mb-0 text-success">
            ROUTE 3990046: {locationData?.route?.origin} -{" "}
            {locationData?.route?.destination}
          </h5>
        </div>
        <div className="card-body">
          <div className="d-flex flex-column gap-4">
            {!offerLoader && (
              <form
                className="card bg-light"
                onSubmit={(e) => {
                  if (locationData?.markUpId) {
                    handleEditMarkupTool(e);
                  } else {
                    handleAddMarkupTool(e);
                  }
                }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label
                          htmlFor="all_airlines"
                          className="form-label w-100 text-truncate required"
                        >
                          Airlines (All = All Airlines):
                        </label>
                        <select
                          className="form-control"
                          value={airlines}
                          onChange={(e) => setAirlines(e.target.value)}
                        >
                          <option value="ALL">All</option>
                          {allIcons?.map((item) => {
                            return (
                              <option value={item?.airline_code}>
                                {item?.airline_code}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label
                          htmlFor="outbound_classes"
                          className="form-label"
                        >
                          Outbound Classes:
                        </label>
                        <select
                          multiple
                          className="form-control"
                          value={outBound}
                          onChange={(e) => setOutBound(e.target.value)}
                        >
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                          <option value="E">E</option>
                          <option value="F">F</option>
                          <option value="G">G</option>
                          <option value="H">H</option>
                          <option value="I">I</option>
                          <option value="J">J</option>
                          <option value="K">K</option>
                          <option value="L">L</option>
                          <option value="M">M</option>
                          <option value="N">N</option>
                          <option value="O">O</option>
                          <option value="P">P</option>
                          <option value="Q">Q</option>
                          <option value="R">R</option>
                          <option value="S">S</option>
                          <option value="T">T</option>
                          <option value="U">U</option>
                          <option value="V">V</option>
                          <option value="W">W</option>
                          <option value="X">X</option>
                          <option value="Y">Y</option>
                          <option value="Z">Z</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="inbound_classes" className="form-label">
                          Inbound Classes:
                        </label>
                        <select
                          multiple
                          className="form-control"
                          value={inBound}
                          onChange={(e) => setInBound(e.target.value)}
                        >
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                          <option value="E">E</option>
                          <option value="F">F</option>
                          <option value="G">G</option>
                          <option value="H">H</option>
                          <option value="I">I</option>
                          <option value="J">J</option>
                          <option value="K">K</option>
                          <option value="L">L</option>
                          <option value="M">M</option>
                          <option value="N">N</option>
                          <option value="O">O</option>
                          <option value="P">P</option>
                          <option value="Q">Q</option>
                          <option value="R">R</option>
                          <option value="S">S</option>
                          <option value="T">T</option>
                          <option value="U">U</option>
                          <option value="V">V</option>
                          <option value="W">W</option>
                          <option value="X">X</option>
                          <option value="Y">Y</option>
                          <option value="Z">Z</option>{" "}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">Markup Type:</label>
                        <select
                          className="form-control"
                          value={markupType}
                          onChange={(e) => setMarkupType(e.target.value)}
                        >
                          <option value="fixed">Fixed</option>
                          <option value="discount">Discount</option>
                          <option value="mark_up">Mark Up</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label
                          htmlFor="value_1pax"
                          className="form-label"
                        >
                          Value (1 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_1pax"
                          disabled={(!px2 && !px3 && !px4 && !px5 && !px6 && !px7 && !px8 && !px9) ? true : false}
                          value={(!px2 && !px3 && !px4 && !px5 && !px6 && !px7 && !px8 && !px9) ? markupAmount : px1}
                          onChange={(e) => setPx1(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="dta" className="form-label required">
                          Markup Amount:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="dta"
                          required
                          value={markupAmount}
                          onChange={(e) => setMarkupAmount(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="dta" className="form-label required">
                          DTA:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="dta"
                          required
                          value={dta}
                          onChange={(e) => setDta(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="start_date" className="form-label">
                          Start Date:
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_date"
                          value={trvlStartDate}
                          onChange={(e) => setTrvlStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="end_date" className="form-label">
                          End Date:
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_date"
                          value={trvlEndDate}
                          onChange={(e) => setTrvlEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_2pax" className="form-label">
                          Value (2 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_2pax"
                          value={px2}
                          onChange={(e) => setPx2(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_3pax" className="form-label">
                          Value (3 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_3pax"
                          value={px3}
                          onChange={(e) => setPx3(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_4pax" className="form-label">
                          Value (4 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_4pax"
                          value={px4}
                          onChange={(e) => setPx4(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_5pax" className="form-label">
                          Value (5 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_5pax"
                          value={px5}
                          onChange={(e) => setPx5(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_6pax" className="form-label">
                          Value (6 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_6pax"
                          value={px6}
                          onChange={(e) => setPx6(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_7pax" className="form-label">
                          Value (7 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_7pax"
                          value={px7}
                          onChange={(e) => setPx7(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_8pax" className="form-label">
                          Value (8 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_8pax"
                          value={px8}
                          onChange={(e) => setPx8(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="value_9pax" className="form-label">
                          Value (9 PAX):
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="value_9pax"
                          value={px9}
                          onChange={(e) => setPx9(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <button className="btn btn-danger" onClick={handleReset}>
                      Remove
                    </button>
                    <button className="btn btn-success" type="submit">
                      {loader ? (
                        <ApiLoader />
                      ) : locationData?.markUpId ? (
                        "Edit"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}

            <form className="card bg-light" onSubmit={handleAddBlackoutDate}>
              <div className="card-body">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                  <h5 className="mb-0">Blackout Dates</h5>
                  <div className="d-flex align-items-center gap-3">
                    <input
                      type="date"
                      className="form-control"
                      value={blackoutDate}
                      onChange={handleDateChange}
                      required
                    />
                    <button type="submit" className="btn btn-primary">
                      Add
                    </button>
                  </div>
                </div>
                <div className="mt-3">
                  <h6>Added Blackout Dates:</h6>
                  <ul className="list-group">
                    {blackoutDates.map((date, index) => (
                      <li key={index} className="list-group-item">
                        <div className="d-flex align-items-center gap-3 justify-content-between">
                          {date}
                          <span
                            className="btn btn-danger d-flex align-items-center justify-content-center"
                            onClick={() => handleRemoveBlackoutDate(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {offerLoader && <DataLoader />}
      {/* {!offerLoader && (
        <form
          onSubmit={(e) => {
            if (locationData?.markUpId) {
              handleEditMarkupTool(e);
            } else {
              handleAddMarkupTool(e);
            }
          }}
        >
          <div className="mb-3">
            <h4 className="d-flex align-items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-airplane-fill"
                viewBox="0 0 16 16"
              >
                <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849" />
              </svg>
              Flight Section
            </h4>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="text-capitalize">Way of flight</label>
                  <select
                    className="form-control"
                    value={wayOfFlight}
                    onChange={(e) => setWayOfFlight(e.target.value)}
                  >
                    <option value="">Choose...</option>
                    <option value="oneway">One Way</option>
                    <option value="roundway">Round Way</option>
                    <option value="both">Both</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-capitalize">Cabins</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="economy"
                      value="option1"
                      checked={cabins === "economy"}
                      onChange={() => setCabins("economy")}
                    />
                    <label className="form-check-label" for="economy">
                      Economy
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="premium"
                      value="option2"
                      checked={cabins === "premium"}
                      onChange={() => setCabins("premium")}
                    />
                    <label className="form-check-label" for="premium">
                      Premium Economy
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="business"
                      value="option3"
                      checked={cabins === "business"}
                      onChange={() => setCabins("business")}
                    />
                    <label className="form-check-label" for="business">
                      Business
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="first"
                      value="option4"
                      checked={cabins === "first"}
                      onChange={() => setCabins("first")}
                    />
                    <label className="form-check-label" for="first">
                      First
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Inbound Classes</label>
                  <select
                    multiple
                    className="form-control"
                    value={inBound}
                    onChange={(e) => setInBound(e.target.value)}
                  >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                    <option value="N">N</option>
                    <option value="O">O</option>
                    <option value="P">P</option>
                    <option value="Q">Q</option>
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="T">T</option>
                    <option value="U">U</option>
                    <option value="V">V</option>
                    <option value="W">W</option>
                    <option value="X">X</option>
                    <option value="Y">Y</option>
                    <option value="Z">Z</option>{" "}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Outbound Classes</label>
                  <select
                    multiple
                    className="form-control"
                    value={outBound}
                    onChange={(e) => setOutBound(e.target.value)}
                  >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                    <option value="N">N</option>
                    <option value="O">O</option>
                    <option value="P">P</option>
                    <option value="Q">Q</option>
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="T">T</option>
                    <option value="U">U</option>
                    <option value="V">V</option>
                    <option value="W">W</option>
                    <option value="X">X</option>
                    <option value="Y">Y</option>
                    <option value="Z">Z</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <h4 className="d-flex align-items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
              </svg>
              Passengers Section
            </h4>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Passengers Count</label>
                  <div className="input-group mb-2">
                    <input
                      type="number"
                      className="form-control"
                      required
                      placeholder="Passengers Count"
                      name="passCount"
                      value={passengerSection.passCount}
                      onChange={handleChangePassengerSection}
                    />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label>Adults Count</label>
                  <div className="input-group mb-2">
                    <input
                      type="number"
                      className="form-control"
                      required
                      placeholder="Adults Count"
                      name="adultsCount"
                      value={passengerSection.adultsCount}
                      onChange={handleChangePassengerSection}
                    />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label>Childs Count</label>
                  <div className="input-group mb-2">
                    <input
                      type="number"
                      className="form-control"
                      required
                      placeholder="Childs Count"
                      name="childCount"
                      value={passengerSection.childCount}
                      onChange={handleChangePassengerSection}
                    />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label>Infants Count</label>
                  <div className="input-group mb-2">
                    <input
                      type="number"
                      className="form-control"
                      required
                      placeholder="Infants Count"
                      name="infantsCount"
                      value={passengerSection.infantsCount}
                      onChange={handleChangePassengerSection}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <h4 className="d-flex align-items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-currency-dollar"
                viewBox="0 0 16 16"
              >
                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
              </svg>
              Budget Section
            </h4>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Total Amount ( In USD )</label>
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Total Amount"
                      value={totalBudget}
                      onChange={(e) => setTotalBudget(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4 className="d-flex align-items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-wrench"
                viewBox="0 0 16 16"
              >
                <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11z" />
              </svg>
              Markup Section
            </h4>
            <hr />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Markup Amount</label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <select
                        className="custom-select"
                        value={markupType}
                        onChange={(e) => setMarupType(e.target.value)}
                      >
                        <option value="mark_up">Mark Up</option>
                        <option value="discount">Discount</option>
                        <option value="fixed">Fixed</option>
                      </select>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Markup Amount"
                      name="amount"
                      value={markupSection.amount}
                      onChange={handleChangeMarkupSection}
                    />
                    <div className="input-group-append">
                      <select
                        className="custom-select"
                        value={markupAmountType}
                        onChange={(e) => setMarkupAmountType(e.target.value)}
                      >
                        <option value="percentage">%</option>
                        <option value="reduce">-</option>
                        <option value="addition">+</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-capitalize">Start Active Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="startDate"
                    value={markupSection.startDate}
                    onChange={handleChangeMarkupSection}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-capitalize">End Active Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={markupSection.endDate}
                    onChange={handleChangeMarkupSection}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="text-capitalize">Status</label>
                  <div className="d-flex flex-column gap-2">
                    <div className="form-check form-check-inline align-items-start">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="enable"
                        value="enable"
                        name="status"
                        checked={markupSection.status === "enable"}
                        onChange={handleChangeMarkupSection}
                      />
                      <label className="form-check-label" htmlFor="enable">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline align-items-start">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="disable"
                        value="disable"
                        name="status"
                        checked={markupSection.status === "disable"}
                        onChange={handleChangeMarkupSection}
                      />
                      <label className="form-check-label" htmlFor="disable">
                        In Active
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label className="text-capitalize">Priority</label>
                  <input
                    type="text"
                    className="form-control"
                    name="priority"
                    value={markupSection.priority}
                    onChange={handleChangeMarkupSection}
                    style={{ width: "50%" }}
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <div className="d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-danger mr-3 px-5"
              onClick={handleReset}
            >
              Reset
            </button>
            <button type="submit" className="btn btn-success px-5">
              {loader ? (
                <ApiLoader />
              ) : locationData?.markUpId ? (
                "Edit"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )} */}
      {/* {offerLoader && <DataLoader />} */}
    </div>
  );
};

export default AddMarkup;
