import React from "react";
import AdminList from "../../pages/Staff/AdminList/AdminList";

const AdminRoutes = {
  path: "/staff",
  children: [
    {
      path: "adminlist",
      element: <AdminList />,
    },
  ],
};

export default AdminRoutes;
