import React from "react";
import AssignedBooking from "../../pages/Booking/AssignedBooking/AssignedBooking";
import BookingDetails from "../../pages/Booking/BookingDetails";
import AllBookings from "../../pages/Booking/AllBookings";
import EmployeeWiseBooking from "../../pages/Booking/EmployeeWiseBooking";

const AssignedBookingRoutes = {
  path: "/booking",
  children: [
    {
      path: "assignedbooking",
      element: <AssignedBooking />,
    },
    {
      path: "bookingdetails/:bookingid",
      element: <BookingDetails />,
    },
    {
      path: "allbooking",
      element: <AllBookings />,
    },
    {
      path: "assignedemployee",
      element: <EmployeeWiseBooking />,
    },
  ],
};

export default AssignedBookingRoutes;
