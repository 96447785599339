import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  MarkupRelatedEndPoint,
  routeRelatedEndPoint,
} from "../../../api/Endpoints";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loader/DataLoader";

const RouteList = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allRoutes, setAllRoutes] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [csvContent, setCsvContent] = useState("");
  const fileInputRef = useRef(null);
  const [search, setSearch] = useState("");

  const handleGetAllRoutes = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: { searchKey: search },
    };
    const res = await ApiCall("post", routeRelatedEndPoint.getAllRoutes, data);
    if (res?.success) {
      setAllRoutes(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllRoutes();
  }, [search]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setCsvFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        setCsvContent(event.target.result);
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  const handleUploadCsv = async () => {
    startApiCall(null, setloader2);
    if (!csvFile) {
      alert("Please select a CSV file first.");
      return;
    }
    const formData = new FormData();
    formData.append("image", csvFile);

    const res = await ApiCall(
      "post",
      MarkupRelatedEndPoint.csvUploadForMarkup,
      formData
    );
    if (res?.success) {
      setCsvFile(null);
      setCsvContent("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      handleGetAllRoutes();
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const data = [
    {
      referenceName: "XYZ",
      origin: "JFK",
      destination: "MIA",
      airlines: "ALL",
      wayOfFlight: "both",
      cabins: ["ECONOMY", "PREMIUM_ECONOMY"],
      inbound_classes: "",
      outbound_classes: "",
      start_travel_date: "06-08-2020",
      end_travel_date: "31-12-2020",
      passengers_count: 3,
      passengers_condition: "greater_than",
      adults_count: 2,
      adults_condition: "equal_to",
      childs_count: 1,
      childs_condition: "equal_to",
      infants_count: 1,
      infants_condition: "equal_to",
      total_amount: 200,
      total_amount_condition: "greater_than",
      markup_type: "discount",
      markup_amount: 10,
      markup_amount_type: "percentage",
      start_active_date: "06-08-2020",
      end_active_date: "31-12-2020",
      priority: 1,
    },
    {
      referenceName: "ABC",
      origin: "MIA",
      destination: "JFK",
      airlines: "ALL",
      wayOfFlight: "oneway",
      cabins: "ECONOMY",
      inbound_classes: "B",
      outbound_classes: "B",
      start_travel_date: "",
      end_travel_date: "",
      passengers_count: 2,
      passengers_condition: "greater_than",
      adults_count: 2,
      adults_condition: "greater_than",
      childs_count: 2,
      childs_condition: "greater_than",
      infants_count: 2,
      infants_condition: "greater_than",
      total_amount: 1000,
      total_amount_condition: "Choose...",
      markup_type: "markup",
      markup_amount: 10,
      markup_amount_type: "percentage",
      start_active_date: "20-08-2020",
      end_active_date: "25-08-2020",
      priority: 2,
    },
  ];

  const downloadCSV = () => {
    let csvData = [];
    data?.map((dt, index) => {
      csvData.push({
        "Sr. No.": index + 1,
        Origin: dt.origin,
        Destination: dt.destination,
        Airlines: dt.airlines,
        "Way of Flight": dt.wayOfFlight,
        Inbound: dt.inbound_classes,
        Outbound: dt?.outbound_classes,
        "Start travel Date": dt.start_travel_date,
        "End travel Date": dt.end_travel_date,
        Passenger: dt.passengers_count,
        Adult: dt.adults_count,
        Child: dt.childs_count,
        Infants: dt.infants_count,
        "Total amount": dt.total_amount,
        "Markup type": dt.markup_type,
        "markup amount type": dt.markup_amount_type,
        "markup amount": dt.markup_amount,
        "Start active Date": dt.start_active_date,
        "End active Date": dt.end_active_date,
        Priority: dt.priority,
      });
    });
    // Extract headers from the first object in the data array
    const headers = Object.keys(csvData[0]);
    // Convert header and data to CSV format
    const headerRow = headers.map((header) => `"${header}"`).join(",");
    const dataRows = csvData.map((obj) =>
      headers.map((header) => `"${obj[header]}"`).join(",")
    );
    const csvContent = `data:text/csv;charset=utf-8,${headerRow}\n${dataRows.join(
      "\n"
    )}`;
    // Create a data URI and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "my_route.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="container pt-3 pb-5">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">All route</h4>
          <Link
            to="/route/addroute"
            className="btn btn-outline-primary text-capitalize"
          >
            Add route
          </Link>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllRoutes();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>

          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search by origin or destination"
            aria-label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </header>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div class="d-flex form-group">
            <input
              type="file"
              name="image"
              id="attachment"
              accept=".csv"
              className="form-control-file"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            {csvFile && (
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleUploadCsv}
              >
                {loader2 ? "loading...." : "Upload"}
              </button>
            )}
          </div>
        </div>
        <span className="btn btn-outline-secondary" onClick={downloadCSV}>
          Download Sample
        </span>
      </div>
      {!loader && allRoutes?.length > 0 && (
        <div className="d-flex flex-column justify-content-between mt-2">
          <div className="table-responsive scrollbar-hide pb-4">
            <table className="table table-striped mb-0 text-center text-nowrap">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Reference Name</th>
                  <th scope="col">Origin</th>
                  <th scope="col">Destination</th>
                  <th scope="col">Airlines</th>
                  <th scope="col">Meta Search</th>
                  <th scope="col">Status</th>
                  <th scope="col" style={{ textAlign: "start" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allRoutes?.map((route, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1} .</td>
                      <td>{route?.referenceName}</td>
                      <td>{route?.origin}</td>
                      <td>{route?.destination}</td>
                      <td>{route?.airlines}</td>
                      <td>{route?.metaSearch ? route?.metaSearch : "-"}</td>
                      <td className="text-capitalize">
                        {route?.status ? route?.status : "-"}
                      </td>
                      <td className="text-start">
                        <div className="d-flex align-items-center gap-3 pl-2">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              type="button"
                              id="action_dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </span>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action_dropdown"
                              style={{ minWidth: "0" }}
                            >
                              <span
                                className="dropdown-item pointer"
                                onClick={() => {
                                  navigate("/route/addroute", {
                                    state: { routeId: route?._id },
                                  });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                                &nbsp; Edit
                              </span>
                            </div>
                          </div>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              navigate(`/route/markuplist/${route?._id}`, {
                                state: { route: route },
                              });
                            }}
                          >
                            View Markup
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {loader && <DataLoader />}
      {!loader && allRoutes?.length === 0 && <NoData />}
    </div>
  );
};

export default RouteList;
