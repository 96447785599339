import axios from "axios";
import { BASE_URL } from "./base";
import { resolveErrorMessage } from "./service";
import { toast } from "react-toastify";
import { publicIpv4 } from "public-ip";

const API = axios.create({ baseURL: BASE_URL });

const fetchIpAddress = async () => {
  try {
    let ip = sessionStorage.getItem("ipAddress");
    if (!ip) {
      ip = await publicIpv4();
      sessionStorage.setItem("ipAddress", ip);
    }
    return ip;
  } catch (error) {
    console.error("Failed to fetch IP address:", error);
    return null;
  }
};

API.interceptors.request.use(async (req) => {
  const ip = await fetchIpAddress();

  if (localStorage.getItem("refreshTokenForOguleAdmin")) {
    req.headers.authorization = JSON.parse(localStorage.getItem("refreshTokenForOguleAdmin"));
  }
  if (ip) {
    req.headers.ip = ip;
  } else {
    req.headers.ip = "unknown";
  }
  req.headers.platform = "admin_portal";
  return req;
});

const OtherApiCall = async (type, route, data) => {
  try {
    let response;
    const apiFunctions = {
      post: API.post,
      get: API.get,
      patch: API.patch,
      delete: API.delete,
      put: API.put,
    };
    response = await apiFunctions[type](route, data);
    var success = true;
    if (response?.data) {
      return response?.data;
    }
  } catch (error) {
    success = false;
    toast.error(`Something went wrong, ${resolveErrorMessage(error)}`);
    return {
      success,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default OtherApiCall;
