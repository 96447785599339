import React from "react";
import CustomerList from "../../pages/Customer/CustomerList/CustomerList";
import UserCardList from "../../pages/UserCard/UserCardList/UserCardList";
import AddUserCard from "../../pages/UserCard/AddUserCard/AddUserCard";
import SearchRecord from "../../pages/Customer/CustomerList/SearchRecord";

const CustomerRoutes = {
  path: "/customer",
  children: [
    {
      path: "customerlist",
      element: <CustomerList />,
    },
    {
      path: "addusercard/:id",
      element: <AddUserCard />,
    },
    {
      path: "allusercard/:id",
      element: <UserCardList />,
    },
    {
      path: "allsearchrecord/:id",
      element: <SearchRecord />,
    },
  ],
};

export default CustomerRoutes;
