import React from "react";
import AddMail from "../../pages/Mailbox/Compose/AddMail";
import AllMails from "../../pages/Mailbox/AllMails/AllMails";
import Outbox from "../../pages/Mailbox/Outbox/Outbox";

const MailboxRoutes = {
  path: "/mailbox",
  children: [
    {
      path: "addmail",
      element: <AddMail />,
    },
    {
      path: "allmails",
      element: <AllMails />,
    },
    {
      path: "outbox",
      element: <Outbox />,
    },
  ],
};

export default MailboxRoutes;
