import React, { useContext, useEffect, useState } from "react";
import { startApiCall } from "../../../Global/globalFunctions";
import {
  bookingRelatedEndPoint,
  creationEndpoint,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import ToasterMessages from "../../../Global/ToasterMessages";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loader/ApiLoader";
import { AuthContext } from "../../../contextApi/context";

const AssignedEmployee = ({ setAssignEmpModal, bookingData, handleGetUnassignedBookings, isReassign }) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allEmployee, setAllEmployee] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState("");
  const [errorMessage, seterrorMessage] = useState("");

  useEffect(() => {
    if(isReassign) {
      setSelectEmployee(bookingData?.assign_to?._id)
    }
  }, [])

  const handleGetAllStaff = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 400,
      filters: {
        role: "employee",
        isDeleted: false,
      },
    };
    const res = await ApiCall("post", creationEndpoint.getAllUsers, data);
    if (res?.success) {
      setAllEmployee(res?.result?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllStaff();
  }, []);

  const handleReset = () => {
    setloader2(false);
    seterrorMessage("");
    setSelectEmployee("");
    setAssignEmpModal(false);
  };

  const handleAddAssignee = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      assign_to: selectEmployee,
      booking_id: bookingData?._id,
      assignedBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      bookingRelatedEndPoint.assignedBooking,
      data
    );
    if (res.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
      handleGetUnassignedBookings();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setAssignEmpModal(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Assign Employee</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <form onSubmit={handleAddAssignee}>
              <div className="row gx-3 py-2">
                <div className="col-12 mt-2">
                  <div class="form-group">
                    <label className="text-capitalize">Select Employee</label>
                    <select
                      className="form-control"
                      disabled={loader}
                      value={selectEmployee}
                      onChange={(e) => setSelectEmployee(e.target.value)}
                    >
                      <option value="">Select Employee</option>
                      {allEmployee?.map((employee) => {
                        return (
                          <option value={employee?._id}>
                            {employee?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className=" modal-footer mt-3 justify-content-end ">
                <button
                  type="button"
                  className="btn btn-danger mr-3"
                  onClick={() => {
                    setAssignEmpModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={selectEmployee === ""}
                >
                  {loader2 ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedEmployee;
