import React from "react";
import AddRoute from "../../pages/Route/AddRoute/AddRoute";

const AddRouteRoutes = {
  path: "/route",
  children: [
    {
      path: "addroute",
      element: <AddRoute />,
    },
  ],
};

export default AddRouteRoutes;
