import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import ApiCall from "../../../api/ApiCall";
import {
  NilhansbookingRelatedEndPoint,
  bookingRelatedEndPoint,
} from "../../../api/Endpoints";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../../Global/globalFunctions";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import AssignedEmployee from "./AssignedEmployee";
import EditBookig from "../EditBookig";

const UnassignedBooking = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const isNilhans =
    localStorage.getItem("urltype") === "Nilhans" ||
    localStorage.getItem("urltype") === "BestAirFares"
      ? NilhansbookingRelatedEndPoint.getAllBookings
      : bookingRelatedEndPoint.getAllBookings;
  const [allBooking, setAllBooking] = useState([]);
  const [assignedEmplModal, setAssignEmpModal] = useState(false);
  const [bookingData, setBookingData] = useState("");
  const [bookingType, setBookingType] = useState("");
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editBooking, setEditBooking] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetUnassignedBookings = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        pnr_no: search,
        assign_to: { $eq: null },
        isOnline:
          bookingType === "online"
            ? true
            : bookingType === "offline"
            ? false
            : "",
      },
    };
    const res = await ApiCall("post", isNilhans, data);
    if (res?.success) {
      setAllBooking(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetUnassignedBookings();
  }, [bookingType, search, page, pageLimit]);

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">
            Unassigned Booking
          </h4>
          <Link
            to="/booking/createbooking"
            className="btn btn-outline-primary text-capitalize"
          >
            Create Booking
          </Link>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetUnassignedBookings();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
          />
          <div>
            <select
              className="form-control"
              style={{ width: "200px" }}
              value={bookingType}
              onChange={(e) => setBookingType(e.target.value)}
            >
              <option value="">All</option>
              <option value="online">Online</option>
              <option value="offline">Offline</option>
            </select>
          </div>
        </form>
      </header>

      {!loader && allBooking?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-10">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Pnr No.</th>
                    <th scope="col">Issued Pnr No.</th>
                    <th scope="col" className="text-left">
                      Customer
                    </th>
                    <th scope="col">Booking Created</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Travel Date</th>
                    <th scope="col">No. of Passenger</th>
                    <th scope="col">Booking Type</th>
                    <th scope="col">MCO</th>
                    <th scope="col">Issuance</th>
                    <th scope="col">Transaction</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allBooking?.map((data, index) => {
                    return (
                      <tr>
                        <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                        <td>{data?.pnr_no}</td>
                        <td>{data?.issuedPnr ? data?.issuedPnr : "-"}</td>
                        <td
                          className="pointer hover-item"
                          onClick={() => {
                            navigate(`/booking/bookingdetails/${data?._id}`, {
                              state: {
                                bookingDetails: data,
                                module: "unassigned",
                              },
                            });
                          }}
                        >
                          {data?.billing_details?.name}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-bar-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5"
                            />
                          </svg>
                        </td>
                        <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                        <td>
                          <div>
                            <p>Origin : {data?.originLocationCode}</p>
                            <p>Destination : {data?.destinationLocationCode}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>Departure Date: {data?.departureDate}</p>
                            <p>Return Date : {data?.returnDate}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>Adults : {data?.adults}</p>
                            <p>Childs : {data?.children}</p>
                            <p>Infants : {data?.infants}</p>
                          </div>
                        </td>
                        <td className="text-capitalize">
                          {data?.isOnline === true ? "Online" : "Offline"}
                        </td>
                        <td>{data?.mco ? data?.mco : "-"}</td>
                        <td>{data?.issuance ? data?.issuance : "-"}</td>
                        <td>{data?.transaction ? data?.transaction : "-"}</td>
                        <td>
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle"
                              type="button"
                              id="action_dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </span>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action_dropdown"
                              style={{ minWidth: "0" }}
                            >
                              <span
                                className="dropdown-item pointer"
                                onClick={() => {
                                  setAssignEmpModal(true);
                                  setBookingData(data);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-person-fill-add"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                  <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                </svg>
                                &nbsp; Assigned to
                              </span>
                              <span
                                className="dropdown-item pointer"
                                onClick={() => {
                                  setIsEdit(true);
                                  setEditBooking(data);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                                &nbsp; Edit
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allBooking?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {assignedEmplModal && (
        <AssignedEmployee
          setAssignEmpModal={setAssignEmpModal}
          bookingData={bookingData}
          handleGetUnassignedBookings={handleGetUnassignedBookings}
        />
      )}
      {isEdit && (
        <EditBookig
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editBooking={editBooking}
          handleRefresh={handleGetUnassignedBookings}
        />
      )}
      {loader && <DataLoader />}
      {!loader && allBooking?.length === 0 && (
        <NoData text="No Unassigned Booking" />
      )}
    </div>
  );
};

export default UnassignedBooking;
