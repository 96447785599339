import React from "react";
import AllIpAddress from "../../pages/IpAddress/AllIpAddress";

const IpAddressRoutes = {
  path: "/",
  children: [
    {
      path: "allipaddress",
      element: <AllIpAddress />,
    },
  ],
};
export default IpAddressRoutes;
