import React from "react";

const PriceDetails = ({ setPriceDetails, bookingData }) => {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setPriceDetails(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Price Details</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <div className="row gx-3 py-2 mt-2">
              <div className="col-md-4 text-center">Travellers</div>
              <div className="col-md-4 text-center">Price Per Person</div>
              <div className="col-md-4 text-center">Sub Total</div>
            </div>
            <div className="row gx-3 py-2">
              <div className="col-md-4 text-center">Adult : {bookingData?.adults} </div>
              <div className="col-md-4 text-center">$ {bookingData?.booking_details?.travelerPricings?.[0]?.price?.total}</div>
              <div className="col-md-4 text-center">$ { bookingData?.adults * bookingData?.booking_details?.travelerPricings?.[0]?.price?.total}</div>
            </div>
            {bookingData?.children !== "0" && (
              <div className="row gx-3 py-2">
              <div className="col-md-4 text-center">Child : {bookingData?.children}</div>
              <div className="col-md-4 text-center">$ {bookingData?.booking_details?.travelerPricings?.[1]?.price?.total}</div>
              <div className="col-md-4 text-center">$ {bookingData?.children * bookingData?.booking_details?.travelerPricings?.[1]?.price?.total}</div>
            </div>
            )}
            {bookingData?.infants !== "0" && (
            <div className="row gx-3 py-2">
              <div className="col-md-4 text-center">Infants : {bookingData?.infants}</div>
              <div className="col-md-4 text-center">$ {bookingData?.booking_details?.travelerPricings?.[2]?.price?.total}</div>
              <div className="col-md-4 text-center">$ {bookingData?.infants * bookingData?.booking_details?.travelerPricings?.[2]?.price?.total}</div>
            </div>
            )}

            <div className=" modal-footer mt-3 justify-content-end ">
              <span className="text-dark" style={{fontWeight: 600}}>Total Price : $ {bookingData?.booking_details?.price?.total}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;
