import React, { useContext, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { bookingRelatedEndPoint } from "../../api/Endpoints";
import { AuthContext } from "../../contextApi/context";
import { startApiCall } from "../../Global/globalFunctions";
import ApiLoader from "../../components/Loader/ApiLoader";
import { toast } from "react-toastify";
import ErrorMessage from "../../components/Error/ErrorMessage";

const BookingDetailsConfirmStatus = ({
  bookingid,
  bookingData,
  selectedStatus,
  handleGetBookingDetails,
  handleGetLifeCycle,
}) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [note, setNote] = useState("");

  const handleReset = () => {
    setloader(false);
    setNote("");
    seterrorMessage("");
    const closeModalDom = document.getElementById(
      "booking_details_status_update"
    );
    if (closeModalDom) closeModalDom.click();
  };

  const handleStatusChange = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      booking_id: bookingid,
      status: selectedStatus,
      actionBy: user?._id,
      isDeleted: false,
      actionOn: bookingData?.assign_to ? bookingData?.assign_to?._id : null,
      lifeCycle:
        selectedStatus === "Pending"
          ? "Booking Pending"
          : selectedStatus === "Processing"
          ? "Booking Processing"
          : selectedStatus === "Follow up"
          ? "Booking Followup"
          : selectedStatus === "Failed"
          ? "Booking Failed"
          : selectedStatus === "Confirmed"
          ? "Booking Confirmed"
          : selectedStatus === "Cancel"
          ? "Booking Cancelled"
          : "Booking Chargeback",
      comment: note,
    };
    const res = await ApiCall(
      "post",
      bookingRelatedEndPoint.bookingLifeCycle,
      data
    );
    if (res?.success) {
      handleReset();
      toast.success("Status change successfully!");
      handleGetBookingDetails();
      handleGetLifeCycle();
    } else {
      setloader(false);
      seterrorMessage(res.error)
    }
  };

  return (
    <div
      class="modal fade"
      id="booking_details_status_update"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Status</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form onSubmit={handleStatusChange}>
              <div class="form-group">
                <p className="text-center font-weight-bold">
                  Status :&nbsp;
                  <span className="text-primary">{selectedStatus}</span>
                </p>
              </div>
              <div class="form-group">
                <label htmlFor="description_area">Add a note (optional)</label>
                <textarea
                  class="form-control"
                  id="description_area"
                  rows="3"
                  placeholder="Type anything"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div class="modal-footer pb-0">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsConfirmStatus;
