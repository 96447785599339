import React from "react";
import "./PageLoaderStyle.css";
const PageLoader = () => {
  return (
    <>
      <div className="customClassPageLoader">
        <div className="page-loader">
          <div className="page-loader__bar"></div>
          <div className="page-loader__bar"></div>
          <div className="page-loader__bar"></div>
          <div className="page-loader__bar"></div>
          <div className="page-loader__bar"></div>
          <div className="page-loader__ball"></div>
        </div>
      </div>
    </>
  );
};

export default PageLoader;