import React, { useContext } from "react";
import { dateAndTimeFormatter } from "../../../Global/globalFunctions";
import { AuthContext } from "../../../contextApi/context";
import UserInfoEdit from "./UserInfoEdit";

const UserProfile = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;

  return (
    <div className="py-2">
      <div className="d-flex flex-column gap-2">
        <div className="card rounded-7">
          <div className="card-header">
            <div className="card-text font-weight-bold text-capitalize">
              Your profile info in services
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div
                  className="avatar avatar-xl mx-auto"
                  style={{ position: "relative" }}
                >
                  <img
                    src={
                      user?.image
                        ? user?.image
                        : "https://cdn.pixabay.com/photo/2013/07/13/10/44/man-157699_1280.png"
                    }
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <span
                    className="badge text-dark bg-light rounded-circle p-0 d-flex align-items-center justify-content-center"
                    style={{
                      height: "24px",
                      width: "24px",
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                    }}
                  >
                    {/* {isUploading && <span>uploading....</span>} */}
                    {/* {!isUploading && ( */}
                    <>
                      <input
                        type="file"
                        name="image"
                        id="profilePhoto"
                        className="input-file"
                        accept="image/png,image/jpeg"
                        // onChange={setFileForUpload}
                      />
                      <label
                        htmlFor="profilePhoto"
                        className="pointer text-center mb-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          className="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </label>
                    </>
                    {/* )} */}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <span className="fs-4 fw-semibold mb-0 text-dark">
                    {user?.name}
                  </span>
                  <span className="fs-4 fw-semibold mb-0 text-capitalize">
                    {user?.role}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card rounded-7">
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="card-text font-weight-bold text-capitalize">
              Personal information
            </div>
            <span
              className="btn btn-outline-secondary btn-rounded btn-sm d-flex align-items-center"
              data-toggle="modal"
              data-target="#edit_name"
              // onClick={() => {
              //   seteditName(true);
              // }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
              </svg>
              &nbsp;Edit
            </span>
          </div>
          <div className="card-body">
            <div className="d-flex col-md-9 mt-1">
              <div className="col-sm-6 fs-5">Name</div>
              <div className="col-sm-6 fs-5">Phone</div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 text-dark">{user?.name}</div>
              <div className="col-sm-6 fs-5 text-dark">
                {user?.mobile_no ? user?.mobile_no : "-"}
              </div>
            </div>
            <div className="d-flex col-md-9 mt-2">
              <div className="col-sm-6 fs-5">Role</div>
              <div className="col-sm-6 fs-5">Email Address</div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 text-dark text-capitalize">
                {user?.role}
              </div>
              <div className="col-sm-6 fs-5 text-dark overflow-auto">
                {user?.email}
              </div>
            </div>
            <div className="d-flex col-md-9 mt-2">
              <div className="col-sm-6 fs-5">Date Created</div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 text-dark text-capitalize">
                {dateAndTimeFormatter(user?.createdAt)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="edit_name"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Personal information
                </h5>
                <span className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </span>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="user_name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="user_name"
                    placeholder="Type your name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="user_contact">Phone No.</label>
                  <input
                    type="number"
                    className="form-control"
                    id="user_contact"
                    placeholder="Type your phone number"
                    maxLength={10}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="user_email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="user_email"
                    placeholder="Type your email address"
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Discard
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      <UserInfoEdit user={user} />
    </div>
  );
};

export default UserProfile;