import React, { useEffect, useState, useContext } from "react";
import OTPBox from "../../../components/OTPBox/OTPBox";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loader/ApiLoader";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { forgotPasswordEndpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { AuthContext } from "../../../contextApi/context";

const ForgotPassword = ({
  firstStep,
  setfirstStep,
  setForgotPassword,
  isLogin,
}) => {

  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [secondStep, setsecondStep] = useState(false);
  const [thirdStep, setthirdStep] = useState(false);
  const [otpString, setOtpString] = useState("");
  const [otp, setotp] = useState(new Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isShowPassowrd, setisShowPassowrd] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);

  useEffect(() => {
    if (isLogin && user?.email) {
      setEmail(user?.email);
    }
  }, [user]);

  const handleReset = () => {
    setloader(false);
    setloader2(false);
    setloader3(false);
    seterrorMessage("");
    setEmail("");
    setOtpString("");
    setotp("");
    setPassword("");
    setConfirmPassword("");
    setthirdStep(false);
    setForgotPassword(false);
  };

  const handleShowPassword = () => {
    setisShowPassowrd(!isShowPassowrd);
  };

  const completeFirstStep = () => {
    setfirstStep(false);
    setsecondStep(true);
    setthirdStep(false);
  };

  const completeSecondStep = () => {
    setfirstStep(false);
    setsecondStep(false);
    setthirdStep(true);
  };

  // Verify Email
  const handleEmailVerify = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const res = await ApiCall(
      "post",
      forgotPasswordEndpoints.sendOtpForResetPassword,
      { email }
    );
    if (res?.success) {
      toast.success("Otp sent successfully");
      setotp(new Array(6).fill(""));
      setOtpString("");
      setloader(false);
      completeFirstStep();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  // OTP Verify
  const handleOtpVerify = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    const res = await ApiCall(
      "post",
      forgotPasswordEndpoints.validateOtpForResetPassword,
      {
        otp: otpString,
      }
    );
    if (res?.success) {
      toast.success("Otp verified successfully");
      setloader2(false);
      completeSecondStep();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  // Password Change
  const handleResetPassword = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader3);
    if (password !== confirmPassword) {
      setloader3(false);
      seterrorMessage("Password and confirm password must be same.");
    } else {
      startApiCall(seterrorMessage, setloader);
      const sendData = {
        password: password,
        otp: otpString,
      };
      const res = await ApiCall(
        "post",
        forgotPasswordEndpoints.resetPassword,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Password change successfully");
      } else {
        setloader3(false);
        seterrorMessage(res.error);
      }
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setForgotPassword(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Forgot Password</h5>
              &nbsp;
            </span>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (firstStep) {
                  handleEmailVerify(e);
                } else if (secondStep) {
                  handleOtpVerify(e);
                } else if (thirdStep) {
                  handleResetPassword(e);
                }
              }}
            >
              <div className="row gx-3 px-2 mb-2">
                {firstStep && !secondStep && !thirdStep && (
                  <div className="col-sm-12 mt-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        type="text"
                        required
                        disabled={isLogin ? true : false}
                      />
                    </div>
                  </div>
                )}
                {!firstStep && secondStep && !thirdStep && (
                  <OTPBox
                    otp={otp}
                    otpString={otpString}
                    errorMessage={errorMessage}
                    loader={loader}
                    handleOtpVerify={"handleOtpVerify"}
                    setOtpString={setOtpString}
                    setotp={setotp}
                    description="Please enter the verification code we sent to your email address"
                    email={email}
                  />
                )}
                {!firstStep && !secondStep && thirdStep && (
                  <>
                    <div className="col-sm-12 mt-3">
                      <div className="form-group">
                        <label>Password</label>
                        <div className="input-group">
                          <input
                            type={isShowPassowrd ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter new Password"
                            required
                            // pattern="^(?=.[A-Za-z])(?=.\d)(?=.[@$!%#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                            // title="Password must be minimum eight characters, at least one letter, one number and one special character."
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={handleShowPassword}
                            >
                              {isShowPassowrd ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-eye-slash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                  <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-eye"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <div className="input-group">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Retype new Password"
                            required
                            // pattern="^(?=.[A-Za-z])(?=.\d)(?=.[@$!%#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                            // title="Password must be minimum eight characters, at least one letter, one number and one special character."
                            value={confirmPassword}
                            onChange={(event) =>
                              setConfirmPassword(event.target.value)
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                              {showConfirmPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-eye-slash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                  <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-eye"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button type="submit" className="btn btn-primary btn-md">
                  {firstStep ? (
                    loader ? (
                      <ApiLoader />
                    ) : (
                      "Send OTP"
                    )
                  ) : secondStep ? (
                    loader2 ? (
                      <ApiLoader />
                    ) : (
                      "Verify"
                    )
                  ) : loader3 ? (
                    <ApiLoader />
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;