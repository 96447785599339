import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { airplaneIconRelatedEndPoint } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";

const AllAirlineIcons = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [allIcons, setAllIcons] = useState([]);

  const handleGetAirplaneIcons = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {},
    };
    const res = await ApiCall(
      "post",
      airplaneIconRelatedEndPoint.getAllAirplaneIcon,
      data
    );
    if (res?.success) {
      setAllIcons(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAirplaneIcons();
  }, []);

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">All Icons</h4>
          <Link
            to="/addairlineicons"
            className="btn btn-outline-primary text-capitalize"
          >
            Add Icon
          </Link>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAirplaneIcons();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>

          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </form>
      </div>
      {!loader && allIcons?.length > 0 && (
        <div className="d-flex flex-column justify-content-between">
          <div className="table-responsive scrollbar-hide pb-4">
            <table className="table table-bordered table-striped mb-0 text-nowrap text-center">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Airline</th>
                  <th scope="col">Airline Name</th>
                  <th scope="col">Image</th>
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {allIcons?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1} .</td>
                      <td>{item?.airline_code}</td>
                      <td className="text-uppercase">{item?.airline_name}</td>
                      <td>
                        <div className="border rounded shadow">
                          <img
                            className="w-100p h-100p"
                            src={item?.image}
                            alt=""
                          />
                        </div>
                      </td>
                      {/* <td>
                        <div className="dropdown">
                          <span
                            className="dropdown-toggle"
                            type="button"
                            id="action_dropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-three-dots"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                            </svg>
                          </span>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="action_dropdown"
                            style={{ minWidth: "0" }}
                          >
                            <span
                              className="dropdown-item"
                              onClick={() => {
                                navigate("/addairlineicons", {
                                  state: { iconId: item?._id },
                                });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                            </span>
                            {/* <span className="dropdown-item">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                          </span> 
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>
      )}
      {loader && <DataLoader />}
      {!loader && allIcons?.length === 0 && <NoData />}
    </div>
  );
};

export default AllAirlineIcons;
