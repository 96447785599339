import React, { useContext, useState } from "react";
import ApiLoader from "../../components/Loader/ApiLoader";
import ErrorMessage from "../../components/Error/ErrorMessage";
import { startApiCall } from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { IpRelatedEndPoint } from "../../api/Endpoints";
import { toast } from "react-toastify";
import ToasterMessages from "../../Global/ToasterMessages";
import { AuthContext } from "../../contextApi/context";

const AddIp = ({ setAddIp, handleGetAllIp }) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [ip, setIp] = useState("");
  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setIp("");
    setAddIp(false);
  };

  const validateIp = (value) => {
    if (ipv4Regex.test(value)) {
      seterrorMessage('');
    } else {
      seterrorMessage('Invalid IP address');
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setIp(value);
    validateIp(value);
  };

  const handleAddIp = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      ip_address: ip,
      addedBy: user?._id
    };
    const res = await ApiCall("post", IpRelatedEndPoint.addIp, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
      handleGetAllIp();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setAddIp(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Add Ip</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <form onSubmit={handleAddIp}>
              <div className="row gx-3 py-2">
                <div className="col-12 d-flex justify-content-center text-capitalize mt-2">
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={ip}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer mt-3 justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger mr-3"
                  onClick={() => {
                    setAddIp(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success" disabled={errorMessage}>
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIp;
