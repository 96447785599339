import React from "react";
import Setting from "../../pages/Settings/Setting";

const SettingsRoutes = {
  path: "/",
  children: [
    {
      path: "setting",
      element: <Setting />,
    },
  ],
};
export default SettingsRoutes;
