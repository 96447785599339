import React, { useEffect, useState } from "react";
import { startApiCall } from "../../Global/globalFunctions";
import { creationEndpoint } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import Pagination from "../../components/Pagination";
import DataLoader from "../../components/Loader/DataLoader";
import NoData from "../../components/NoData/NoData";


const AllActions = () => {
  const [loader, setloader] = useState(false);
  const [allLogs, setAllLogs] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(50);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllActivityLogs = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        // searchKey: search,
      },
    };
    const res = await ApiCall("post", creationEndpoint.getActivityLogs, data);
    if (res?.success) {
      setAllLogs(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllActivityLogs();
  }, [page, pageLimit]);

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <h4 className="text-uppercase mb-0 mr-2 headline-sm">All Actions</h4>
        <form>
          <span
            className="btn btn-light mr-2"
            onClick={handleGetAllActivityLogs}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
        </form>
      </header>
      {!loader && allLogs?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-5">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Sr No</th>
                    <th scope="col">Action</th>
                    <th scope="col">Action By</th>
                    <th scope="col">Action Type</th>
                    <th scope="col">Action Module</th>
                  </tr>
                </thead>
                <tbody>
                  {allLogs?.map((data, index) => {
                    return (
                      <tr>
                        <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                        <td className="text-capitalize">{data?.action}</td>
                        <td className="text-capitalize">
                          {data?.actionBy?.name}
                        </td>
                        <td className="text-capitalize">{data?.actionType}</td>
                        <td className="text-capitalize">{data?.docModel}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allLogs?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {loader && <DataLoader />}
      {!loader && allLogs?.length === 0 && <NoData />}
    </div>
  );
};

export default AllActions;
