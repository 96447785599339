import React, { useEffect, useState } from "react";

const TravelerSection = ({travellerDetails, settravellerDetails, date, passengerSection, totalPassengers}) => {
  const adultsCount = parseInt(passengerSection.adultsCount, 10) || 0;
  const childrenCount = parseInt(passengerSection.childCount, 10) || 0;
  const infantsCount = parseInt(passengerSection.infantsCount, 10) || 0;

  const passengerLabels = [
    ...Array(adultsCount).fill("Adult"),
    ...Array(childrenCount).fill("Child"),
    ...Array(infantsCount).fill("Infant"),
  ];

  useEffect(() => {
    let temp = [];
    for (let index = 0; index < totalPassengers; index++) {
      temp = passengerLabels.map((label) => ({
        firstName: "",
        middleName: "",
        lastName: "",
        date: "",
        gender: "",
        travellerType: label,
      }));
    }
    settravellerDetails(temp);
  }, [totalPassengers]);

  const handleChangeTravellerDetails = (
    e,
    selectedIndex,
    inputType = "input"
  ) => {
    const updatedTravellerDetails = [...travellerDetails];

    if (inputType === "input" || inputType === "date") {
      const { name, value } = e.target;
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        [name]: value,
      };
    } else if (inputType === "radio") {
      const { name, value } = e.target;
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        [name.split("-")[0]]: value,
      };
    }

    settravellerDetails(updatedTravellerDetails);
  };

  return (
    <>
      <h4>Traveler Details</h4>
      <hr />
      <div className="mb-4" style={{ maxHeight: "400px", overflowX: "auto" }}>
        {travellerDetails?.map((data, key) => {
          return (
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <span
                    className="accordion-button d-flex align-items-center gap-2"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    style={{ fontSize: "16px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-person"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                    Traveler&nbsp;{key + 1}&nbsp;
                    <span className="text-primary-theme">
                      {passengerLabels[key]}
                    </span>
                  </span>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="row g-3">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="firstName"
                            style={{
                              borderRadius: "10px",
                            }}
                            value={data?.firstName}
                            onChange={(e) => {
                              handleChangeTravellerDetails(e, key);
                            }}
                            placeholder="Enter first name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label>Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="middleName"
                            style={{
                              borderRadius: "10px",
                            }}
                            value={data?.middleName}
                            onChange={(e) => {
                              handleChangeTravellerDetails(e, key);
                            }}
                            placeholder="Enter middle name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="lastName"
                            style={{
                              borderRadius: "10px",
                            }}
                            value={data?.lastName}
                            onChange={(e) => {
                              handleChangeTravellerDetails(e, key);
                            }}
                            placeholder="Enter last name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label>Date of Birth</label>
                          <input
                            className="form-control"
                            type="date"
                            name="date"
                            value={data?.date}
                            onChange={(e) => {
                              handleChangeTravellerDetails(e, key);
                            }}
                            max={date}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label>Gender</label>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ height: "37.5px" }}
                          >
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`gender-${key}`}
                                id={`male-${key}`}
                                value="Male"
                                checked={data.gender === "Male"}
                                onChange={(e) =>
                                  handleChangeTravellerDetails(e, key, "radio")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="male"
                              >
                                Male
                              </label>
                            </div>
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`gender-${key}`}
                                id={`female-${key}`}
                                value="Female"
                                checked={data.gender === "Female"}
                                onChange={(e) =>
                                  handleChangeTravellerDetails(e, key, "radio")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="female"
                              >
                                Female
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TravelerSection;
