import React, { useEffect, useState, useContext } from "react";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { NilhansbookingRelatedEndPoint, bookingRelatedEndPoint } from "../../api/Endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import AssignedEmployee from "./UnassignedBooking/AssignedEmployee";
import DataLoader from "../../components/Loader/DataLoader";
import NoData from "../../components/NoData/NoData";
import Pagination from "../../components/Pagination";
import { AuthContext } from "../../contextApi/context";

const AllBookings = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const isNilhans = (localStorage.getItem("urltype") === "Nilhans" || localStorage.getItem("urltype") === "BestAirFares") ? NilhansbookingRelatedEndPoint.getAllBookings : bookingRelatedEndPoint.getAllBookings
  const navigate = useNavigate();
  const [bookingType, setBookingType] = useState("");
  const [search, setSearch] = useState("");
  const [loader, setloader] = useState(false);
  const [allBooking, setAllBooking] = useState([]);
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [assignedEmplModal, setAssignEmpModal] = useState(false);
  const [bookingData, setBookingData] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const allStatus = [
    {
      name: "Pending",
    },
    {
      name: "Processing",
    },
    {
      name: "Follow up",
    },
    {
      name: "Failed",
    },
    {
      name: "Confirmed",
    },
    {
      name: "Cancel",
    },
    {
      name: "Chargeback",
    },
  ];

  const handleGetAllBookings = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        pnr_no: search,
        isOnline:
          bookingType === "online"
            ? true
            : bookingType === "offline"
            ? false
            : "",
        customer: location?.state ? location?.state?.custId : "",
      },
    };
    const res = await ApiCall(
      "post",
      isNilhans,
      data
    );
    if (res?.success) {
      setAllBooking(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllBookings();
  }, [bookingType, search, page, pageLimit]);

  const handleStatusChange = async (booking_id, status, emp) => {
    const data = {
      booking_id,
      status,
      actionBy: user?._id,
      actionOn: emp?.assign_to ? emp?.assign_to?._id : "",
      isDeleted: false,
      lifeCycle:
        status === "Pending"
          ? "Booking Pending"
          : status === "Processing"
          ? "Booking Processing"
          : status === "Follow up"
          ? "Booking Followup"
          : status === "Failed"
          ? "Booking Failed"
          : status === "Confirmed"
          ? "Booking Confirmed"
          : status === "Cancel"
          ? "Booking Cancelled"
          : "Booking Chargeback",
    };
    const res = await ApiCall(
      "post",
      bookingRelatedEndPoint.bookingLifeCycle,
      data
    );
    if (res?.success) {
      handleGetAllBookings();
    } else {
    }
  };

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center">
          <span
            className="btn btn-light border"
            onClick={() => {
              navigate("/customer/customerlist");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
          </span>
          <h4 className="text-uppercase mb-0 headline-sm ml-2">All Bookings</h4>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span className="btn btn-light mr-2" onClick={handleGetAllBookings}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
          />
          <div>
            <select
              className="form-control"
              style={{ width: "200px" }}
              value={bookingType}
              onChange={(e) => setBookingType(e.target.value)}
            >
              <option value="">All</option>
              <option value="online">Online</option>
              <option value="offline">Offline</option>
            </select>
          </div>
        </form>
      </header>
      {!loader && allBooking?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-10">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Pnr No.</th>
                    <th scope="col">Assign To</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Travel Date</th>
                    <th scope="col">No. of Passenger</th>
                    <th scope="col">Status</th>
                    <th scope="col">Booking Type</th>
                    <th scope="col">Action</th>
                    <th scope="col">Assign/Reassign</th>
                  </tr>
                </thead>
                <tbody>
                  {allBooking?.map((data, index) => {
                    return (
                      <tr>
                        <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                        <td>{data?.pnr_no}</td>
                        <td>
                          {data?.assign_to
                            ? data?.assign_to?.name
                            : "No assignee"}
                        </td>
                        <td
                          className="pointer text"
                          onClick={() =>
                            navigate(`/booking/bookingdetails/${data?._id}`, {
                              state: {
                                bookingDetails: data,
                                module: "allbooking",
                              },
                            })
                          }
                        >
                          {data?.billing_details?.name}
                        </td>
                        <td>{dateAndTimeFormatter(data?.updatedAt)}</td>
                        <td>
                          <div>
                            <p>Origin : {data?.originLocationCode}</p>
                            <p>Destination : {data?.destinationLocationCode}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>Departure Date: {data?.departureDate}</p>
                            <p>Return Date : {data?.returnDate}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>Adults : {data?.adults}</p>
                            <p>Childs : {data?.children}</p>
                            <p>Infants : {data?.infants}</p>
                          </div>
                        </td>
                        <td className="text-capitalize">{data?.status}</td>
                        <td className="text-capitalize">
                          {data?.isOnline === true ? "Online" : "Offline"}
                        </td>
                        <td>
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle pointer"
                              type="button"
                              id="action_dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                              </svg>
                            </span>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action_dropdown"
                              style={{ minWidth: "0" }}
                            >
                              {allStatus?.map((st) => {
                                return (
                                  <button
                                    className="dropdown-item"
                                    disabled={st.name === data.status}
                                    onClick={() =>
                                      handleStatusChange(
                                        data?._id,
                                        st.name,
                                        data
                                      )
                                    }
                                  >
                                    {st?.name}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        </td>
                        <td>
                          <span
                            className="pointer"
                            onClick={() => {
                              setAssignEmpModal(true);
                              setBookingData(data);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              fill="currentColor"
                              class="bi bi-person-fill-add"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                              <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                            </svg>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allBooking?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {assignedEmplModal && (
        <AssignedEmployee
          setAssignEmpModal={setAssignEmpModal}
          bookingData={bookingData}
          handleGetUnassignedBookings={handleGetAllBookings}
          isReassign={true}
        />
      )}
      {loader && <DataLoader />}
      {!loader && allBooking?.length === 0 && <NoData text="No Bookings" />}
    </div>
  );
};

export default AllBookings;
