import React, { useContext, useEffect, useState } from "react";
import {
  reverseDateFormatter,
  startApiCall,
} from "../../../../Global/globalFunctions";
import TravelerSection from "./TravelerSection";
import BillingSection from "./BillingSection";
import OtherApiCall from "../../../../api/OtherApiCall";
import { bookingRelatedEndPoint } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contextApi/context";
import { Buffer } from "buffer";

const BookingSection = () => {
  var date = reverseDateFormatter(new Date());
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [bookingData, setBookingData] = useState([]);
  const [loader, setloader] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [wayOfFlight, setWayOfFlight] = useState("round_trip");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [cabins, setCabins] = useState("ECONOMY");
  const [trvlStartDate, setTrvlStartDate] = useState(date);
  const defaultEndDate = new Date(trvlStartDate);
  defaultEndDate.setDate(defaultEndDate.getDate() + 1);
  const [trvlEndDate, setTrvlEndDate] = useState(
    reverseDateFormatter(defaultEndDate)
  );
  const [bookingType, setBookingType] = useState("online");
  const [amount, setAmount] = useState("");
  const [pnr, setPnr] = useState("");
  const [amaduesPnr, setAmaduesPnr] = useState("");
  const [issuedPnr, setIssuedPnr] = useState("");
  const [moc, setMoc] = useState("");
  const [transaction, setTransaction] = useState("");
  const [issuance, setIssuance] = useState("");
  const [comment, setComment] = useState("");
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [passengerSection, setpassengerSection] = useState({
    adultsCount: "1",
    childCount: "0",
    infantsCount: "0",
  });
  const [travellerDetails, settravellerDetails] = useState([]);
  const [cardDetails, setcardDetails] = useState({
    name: "",
    holderName: "",
    number: "",
    expDate: "",
    cvv: "",
  });
  const [billingDetails, setbillingDetails] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    countrycode: "",
    address: "",
    state: "",
    city: "",
    zip: "",
  });

  const isBillingDisabled = Object.values(billingDetails).some(
    (value) => value === ""
  );
  const isCardDisabled = Object.values(cardDetails).some(
    (value) => value === ""
  );

  const isTravelerDisabled = travellerDetails?.some((details) =>
    Object.entries(details).some(
      ([key, value]) => key !== "middleName" && value === ""
    )
  );

  const handleChangeAmount = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
    }
  };

  console.log(
    "passengerSection.childCount",
    passengerSection.childCount?.toString()
  );

  const handleDepartureChange = (e) => {
    const selectedDate = e.target.value;
    setTrvlStartDate(selectedDate);
    const departureDate = new Date(selectedDate);
    departureDate.setDate(departureDate.getDate() + 1);
    const formattedReturnDate = reverseDateFormatter(departureDate);
    setTrvlEndDate(formattedReturnDate);
  };

  const cardDetailsString = `${cardDetails.holderName}/${cardDetails.name}/${cardDetails.cvv}/${cardDetails.number}/${cardDetails.expDate}`;
  const encryptedCard = Buffer.from(cardDetailsString, "utf-8").toString(
    "base64"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      wayOfFlight &&
      origin &&
      destination &&
      trvlStartDate &&
      trvlEndDate &&
      cabins &&
      passengerSection.adultsCount &&
      bookingType &&
      amaduesPnr &&
      amount
    ) {
      setSecondStep(true);
      sessionStorage.setItem("adults", passengerSection.adultsCount);
      sessionStorage.setItem("children", passengerSection.childCount);
      sessionStorage.setItem("infants", passengerSection.infantsCount);
      const total =
        parseInt(passengerSection.adultsCount) +
        parseInt(passengerSection.childCount) +
        parseInt(passengerSection.infantsCount);
      setTotalPassengers(total);
    } else {
      alert("Please fill in all required fields.");
    }
  };

  const handleReset = () => {
    setloader(false);
    setbillingDetails({
      name: "",
      email: "",
      phone: "",
      country: "",
      countrycode: "",
      address: "",
      state: "",
      city: "",
      zip: "",
    });
    setcardDetails({
      name: "",
      holderName: "",
      number: "",
      expDate: "",
      cvv: "",
    });
    setpassengerSection({
      adultsCount: "1",
      childCount: "0",
      infantsCount: "0",
    });
    setTrvlStartDate(trvlStartDate);
    setTrvlEndDate(trvlEndDate);
    setSecondStep("");
    sessionStorage.removeItem("adults");
    sessionStorage.removeItem("children");
    sessionStorage.removeItem("infants");
    setWayOfFlight("round_trip");
    setOrigin("");
    setDestination("");
    setCabins("ECONOMY");
    setBookingType("online");
    setAmaduesPnr("");
    setPnr("");
    setAmount("");
    setTotalPassengers(0);
    settravellerDetails([]);
    setComment("");
  };

  useEffect(() => {
    const firstItinerary = {
      duration: "",
      segments: [
        {
          departure: {
            iataCode: "",
            at: "",
          },
          arrival: {
            iataCode: "",
            at: "",
          },
          carrierCode: "",
          number: "",
          airline: "",
          airlineImage: "",
          blacklistedInEU: false,
        },
      ],
    };
    const secondItinerary =
      wayOfFlight !== "one_way"
        ? {
            duration: "",
            segments: [
              {
                departure: {
                  iataCode: "",
                  at: "",
                },
                arrival: {
                  iataCode: "",
                  at: "",
                },
                carrierCode: "",
                number: "",
                airline: "",
                airlineImage: "",
                blacklistedInEU: false,
              },
            ],
          }
        : null;
    const transformedData = {
      itineraries: [firstItinerary, secondItinerary].filter(Boolean),
      price: {
        currency: "USD",
        total: amount,
        base: amount,
        fees: [],
        grandTotal: amount,
        discount: "",
      },
    };
    setBookingData(transformedData);
  }, [wayOfFlight, amount]);

  const handleAddBooking = async () => {
    startApiCall(null, setloader);
    let { name, email, phone, address, state, city, zip } = billingDetails;
    let { holderName, number, expDate, cvv } = cardDetails;
    const data = {
      user_id: user?._id,
      billingDetails: {
        name,
        email,
        phone,
        address,
      },
      // cardDetails: {
      //   name: cardDetails?.name,
      //   holderName,
      //   number,
      //   expDate,
      //   cvv,
      // },
      card: encryptedCard,
      travellerDetails: travellerDetails,
      flightDetails: {
        wayOfFlight,
        originLocationCode: origin,
        destinationLocationCode: destination,
        adults: passengerSection.adultsCount,
        children: passengerSection.childCount,
        infants: passengerSection.infantsCount,
        departureDate: trvlStartDate,
        returnDate: trvlEndDate,
        travelClass: cabins,
        currencyCode: "",
        other_details: bookingData,
        booking_steps: "step_one",
        checkout_title: "Billing Information",
        payment_method: "card",
        showSubscribeModal: false,
      },
      wayOfFlight,
      originLocationCode: origin,
      destinationLocationCode: destination,
      departureDate: trvlStartDate,
      returnDate: trvlEndDate,
      travelClass: cabins,
      state,
      city,
      zip,
      bookingType: bookingType,
      amadeusPnr: amaduesPnr,
      adults: passengerSection.adultsCount.toString(),
      children: passengerSection.childCount.toString(),
      infants: passengerSection.infantsCount.toString(),
      isOnline: false,
      comment,
    };
    const res = await OtherApiCall(
      "post",
      bookingRelatedEndPoint.addBooking,
      data
    );
    if (res?.data) {
      setloader(false);
      handleReset();
      toast.success("Booking created successfully!");
    } else {
      setloader(false);
    }
  };

  return (
    <div className="mb-3">
      <h4>Booking Section</h4>
      <hr />
      <div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Title</label>
              <div className="d-flex flex-column gap-2">
                <div className="form-check form-check-inline align-items-start">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="one_way"
                    value="one_way"
                    checked={wayOfFlight === "one_way"}
                    onClick={(e) => setWayOfFlight("one_way")}
                  />
                  <label className="form-check-label" htmlFor="one_way">
                    Oneway
                  </label>
                </div>
                <div className="form-check form-check-inline align-items-start">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="round_trip"
                    value="round_trip"
                    checked={wayOfFlight === "round_trip"}
                    onClick={(e) => setWayOfFlight("round_trip")}
                  />
                  <label className="form-check-label" htmlFor="round_trip">
                    Round trip
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Origin</label>
              <input
                type="text"
                className="form-control"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
                // required
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Destination</label>
              <input
                type="text"
                className="form-control"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                // required
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Departure date</label>
              <input
                type="date"
                className="form-control"
                value={trvlStartDate}
                onChange={handleDepartureChange}
                min={date}
                required
              />
            </div>
          </div>
          {wayOfFlight === "round_trip" && (
            <div className="col">
              <div className="form-group">
                <label className="text-capitalize">Return date</label>
                <input
                  type="date"
                  className="form-control"
                  value={trvlEndDate}
                  onChange={(e) => setTrvlEndDate(e.target.value)}
                  min={trvlStartDate ? trvlStartDate : date}
                  required
                />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Travel class</label>
              <select
                className="form-control"
                value={cabins}
                onChange={(e) => setCabins(e.target.value)}
                required
              >
                <option value="ECONOMY">Economy</option>
                <option value="PREMIUM_ECONOMY">Premium Economy</option>
                <option value="BUSINESS">Business</option>
                <option value="FIRST">First</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Adults (12+ years)</label>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <button
                  className="btn btn-icon border"
                  onClick={() => {
                    setpassengerSection((prevState) => {
                      const newAdultsCount =
                        parseInt(prevState.adultsCount) - 1;
                      const newInfantsCount = Math.min(
                        prevState.infantsCount,
                        newAdultsCount
                      );
                      return {
                        ...prevState,
                        adultsCount: newAdultsCount,
                        infantsCount: newInfantsCount,
                      };
                    });
                  }}
                  disabled={parseInt(passengerSection.adultsCount) === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-dash-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                    />
                  </svg>
                </button>
                <p
                  className="border-bottom text-center mb-0 w-100 rounded"
                  style={{ paddingBlock: ".375rem" }}
                >
                  {passengerSection.adultsCount}
                </p>
                <button
                  className="btn btn-icon border"
                  onClick={() => {
                    setpassengerSection((prevState) => ({
                      ...prevState,
                      adultsCount: parseInt(prevState["adultsCount"]) + 1,
                    }));
                  }}
                  disabled={
                    parseInt(passengerSection.adultsCount) +
                      parseInt(passengerSection.childCount) ===
                    9
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Children (2-11 years)</label>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <button
                  className="btn btn-icon border"
                  onClick={() => {
                    setpassengerSection((prevState) => ({
                      ...prevState,
                      childCount: parseInt(prevState["childCount"]) - 1,
                    }));
                  }}
                  disabled={parseInt(passengerSection.childCount) === 0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-dash-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                    />
                  </svg>
                </button>
                <p
                  className="border-bottom text-center mb-0 w-100 rounded"
                  style={{ paddingBlock: ".375rem" }}
                >
                  {passengerSection.childCount}
                </p>
                <button
                  className="btn btn-icon border"
                  onClick={() => {
                    setpassengerSection((prevState) => ({
                      ...prevState,
                      childCount: parseInt(prevState["childCount"]) + 1,
                    }));
                  }}
                  disabled={
                    parseInt(passengerSection.adultsCount) +
                      parseInt(passengerSection.childCount) ===
                    9
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Infants (Under 2 years)</label>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <button
                  className="btn btn-icon border"
                  onClick={() => {
                    setpassengerSection((prevState) => ({
                      ...prevState,
                      infantsCount: parseInt(prevState["infantsCount"]) - 1,
                    }));
                  }}
                  disabled={parseInt(passengerSection.infantsCount) === 0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-dash-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                    />
                  </svg>
                </button>
                <p
                  className="border-bottom text-center mb-0 w-100 rounded"
                  style={{ paddingBlock: ".375rem" }}
                >
                  {passengerSection.infantsCount}
                </p>
                <button
                  className="btn btn-icon border"
                  onClick={() => {
                    setpassengerSection((prevState) => ({
                      ...prevState,
                      infantsCount: parseInt(prevState["infantsCount"]) + 1,
                    }));
                  }}
                  disabled={
                    passengerSection.infantsCount ===
                    passengerSection.adultsCount
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Select type</label>
              <select
                className="form-control"
                value={bookingType}
                onChange={(e) => setBookingType(e.target.value)}
                required
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Amadeus PNR</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter amadeus pnr"
                value={amaduesPnr}
                onChange={(e) => setAmaduesPnr(e.target.value)}
                // required
              />
            </div>
          </div>
          {/* <div className="col">
            <div className="form-group">
              <label className="text-capitalize">PNR</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter pnr"
                value={pnr}
                onChange={(e) => setPnr(e.target.value)}
                // required
              />
            </div>
          </div> */}
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Amount</label>
              <input
                type="tel"
                inputmode="numeric"
                className="form-control"
                placeholder="Enter amount"
                value={amount}
                onChange={handleChangeAmount}
                // required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <label className="text-capitalize">Issued PNR</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter amadeus pnr"
                value={issuedPnr}
                onChange={(e) => setIssuedPnr(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="text-capitalize">MOC</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter amadeus pnr"
                value={moc}
                onChange={(e) => setMoc(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="text-capitalize">Transaction</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter amadeus pnr"
                value={transaction}
                onChange={(e) => setTransaction(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="text-capitalize">Issuance</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter amadeus pnr"
                value={issuance}
                onChange={(e) => setIssuance(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label className="text-capitalize">Comment</label>
              <textarea
                className="form-control"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div
          className="row d-flex justify-content-end"
          style={{ marginTop: -20, marginRight: 0 }}
        >
          <div className="form-group me-2">
            <label>&nbsp;</label>
            <button
              type="submit"
              className="form-control btn btn-primary"
              onClick={handleSubmit}
            >
              <div className="d-flex align-items-center justify-content-center gap-2">
                Next
              </div>
            </button>
          </div>
        </div>
      </div>
      {secondStep && (
        <>
          <TravelerSection
            travellerDetails={travellerDetails}
            settravellerDetails={settravellerDetails}
            date={date}
            passengerSection={passengerSection}
            totalPassengers={totalPassengers}
          />
          <BillingSection
            cardDetails={cardDetails}
            setcardDetails={setcardDetails}
            billingDetails={billingDetails}
            setbillingDetails={setbillingDetails}
            handleAddBooking={handleAddBooking}
            loader={loader}
            isBillingDisabled={isBillingDisabled}
            isCardDisabled={isCardDisabled}
            isTravelerDisabled={isTravelerDisabled}
          />
        </>
      )}
    </div>
  );
};

export default BookingSection;
