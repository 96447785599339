import React from "react";
import AssigneeFilter from "./AssigneeFilter";
import { DateRange } from "./DateRange";
import StatusFilter from "./StatusFilter";
import ApiLoader from "../../../components/Loader/ApiLoader";

const Filters = ({
  selectedEmpId,
  setSelectedEmpId,
  selectedFilterType,
  startDate,
  setstartDate,
  endDate,
  setendDate,
  setStatus,
  status,
  allStatus,
  handleGetAssignedBookings,
  filterLoader,
  handleResetFilters
}) => {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3" style={{ overflow: "visible" }}>
          <div className="modal-body pb-0">
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">
                Filter By {selectedFilterType}
              </h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <form onSubmit={handleGetAssignedBookings}>
              <>
                {selectedFilterType === "Assignee" && (
                  <AssigneeFilter
                    selectedEmpId={selectedEmpId}
                    setSelectedEmpId={setSelectedEmpId}
                  />
                )}

                {selectedFilterType === "Date Range" && (
                  <DateRange
                    startDate={startDate}
                    setstartDate={setstartDate}
                    endDate={endDate}
                    setendDate={setendDate}
                  />
                )}
                {selectedFilterType === "Status" && (
                  <StatusFilter
                    setStatus={setStatus}
                    status={status}
                    allStatus={allStatus}
                  />
                )}

                <div className="modal-footer mt-3 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-danger mr-3"
                    onClick={() => {
                      handleResetFilters(selectedFilterType, false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={selectedFilterType === "Assignee" ? selectedEmpId === "" : selectedFilterType === "Date Range" ? !startDate && !endDate : ""}
                  >
                    {filterLoader ? <ApiLoader /> : "Save"} 
                  </button>
                </div>
              </>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
