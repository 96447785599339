import React from "react";
import UnassignedBooking from "../../pages/Booking/UnassignedBooking/UnassignedBooking";

const UnassignedBookingRoutes = {
  path: "/booking",
  children: [
    {
      path: "unassignedbooking",
      element: <UnassignedBooking />,
    },
  ],
};

export default UnassignedBookingRoutes;
