import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import FileUpload from "../../../components/FileUpload/FileUpload";
import ApiCall from "../../../api/ApiCall";
import { MailRelatedEndPoint } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ToasterMessages from "../../../Global/ToasterMessages";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loader/ApiLoader";
import { AuthContext } from "../../../contextApi/context";

const AddMail = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setFromEmail("");
    setEmail("");
    setMessage("");
    setSubject("");
    setSelectedFile("");
  };

  const handleAddMail = async (e) => {
    e.preventDefault();
    const data = {
      from_email: fromEmail,
      email,
      subject,
      message,
      created_by: user?._id,
    };
    const res = await ApiCall("post", MailRelatedEndPoint.addMail, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div className="container pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex align-items-center gap-3 mb-3">
            <Link to="/mailbox/allmails" className="btn btn-light border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </Link>
            <h4 className="text-uppercase mb-0">Add mail</h4>
          </div>
          <form onSubmit={handleAddMail}>
            <div className="form-group">
              <label>Select email</label>
              <input
                type="text"
                className="form-control"
                value={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Or type email</label>
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input
                type="text"
                className="form-control"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="7"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
                <FileUpload />
              </div>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <hr className="mb-4" />
            <div className="d-flex justify-content-end mt-3">
              <button
                type="reset"
                className="btn btn-danger mr-3 px-5"
                onClick={handleReset}
              >
                Reset
              </button>
              <button type="submit" className="btn btn-success px-5">
                {loader ? <ApiLoader /> : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMail;
