import React from "react";
import CreateBooking from "../../pages/Booking/CreateBooking/CreateBooking";

const AddBookingRoutes = {
  path: "/booking",
  children: [
    {
      path: "createbooking",
      element: <CreateBooking />,
    },
  ],
};

export default AddBookingRoutes;
