import React from "react";
import AddCustomer from "../../pages/Customer/AddCustomer/AddCustomer";

const AddCustomerRoutes = {
  path: "/customer",
  children: [
    {
      path: "addcustomer",
      element: <AddCustomer />,
    },
  ],
};

export default AddCustomerRoutes;
