import React from "react";
import BookingSection from "./BookingSection/BookingSection";

const CreateBooking = () => {
  return (
    <div className="p-5 pt-2 pb-4">
      <h4 className="text-uppercase text-center">Create Booking</h4>
      <BookingSection />
    </div>
  );
};

export default CreateBooking;
