import React, { useEffect, useState } from "react";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { MailRelatedEndPoint } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";

const Outbox = () => {
  const [loader, setloader] = useState("");
  const [allMails, setAllMails] = useState([]);

  const handleGetAllMails = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {},
    };
    const res = await ApiCall("post", MailRelatedEndPoint.getAllMails, data);
    if (res?.success) {
      setAllMails(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllMails();
  }, []);

  return (
    <div className="container pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="text-uppercase mb-0 mr-2 headline-sm">Outbox</h4>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllMails();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </form>
      </div>
      {!loader && allMails?.length > 0 && (
        <div className="d-flex flex-column justify-content-between">
          <div className="table-responsive scrollbar-hide pb-4" >
            <table className="table table-striped mb-0 text-center text-nowrap">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">To email</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Message</th>
                </tr>
              </thead>
              <tbody>
                {allMails?.map((data, index) => {
                  return (
                    <tr>
                      <td>{index + 1} .</td>
                      <td>{data?.email}</td>
                      <td>{data?.subject}</td>
                      <td>{data?.message}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>
      )}
      {loader && <DataLoader />}
      {!loader && allMails?.length === 0 && <NoData />}
    </div>
  );
};

export default Outbox;
