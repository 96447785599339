import React from "react";
import CategoryList from "../../pages/Category/CategoryList/CategoryList";
import AddCategory from "../../pages/Category/AddCategory/AddCategory";

const CategoryRoutes = {
  path: "/blog",
  children: [
    {
      path: "addcategory",
      element: <AddCategory />,
    },
    {
      path: "categorylist",
      element: <CategoryList />,
    },
  ],
};

export default CategoryRoutes;
