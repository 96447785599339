import React from "react";
import Home from "../../pages/Home/Home";

const HomeRoutes = {
  path: "/",
  children: [
    {
      path: "",
      element: <Home />,
    },
    {
      path: "home",
      element: <Home />,
    },
    // {
    //   path: "profile",
    //   element: <Profile />,
    // },
  ],
};
export default HomeRoutes;
