import React, { useState } from "react";

const FileUpload = () => {
  const [preview, setPreview] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setFileType(file.type);
    }
  };

  const renderPreview = () => {
    if (fileType && fileType.startsWith("image")) {
      return (
        <div
          className="file-upload__preview"
          style={{ backgroundImage: `url(${preview})` }}
        ></div>
      );
    } else {
      return (
        <div className="file-upload__preview-placeholder">
          <i className="fa fa-file fa-lg fa-fw" aria-hidden="true"></i>
          <span className="text-center">
            {fileType ? `Selected file (${fileType})` : "Select file"}
          </span>
        </div>
      );
    }
  };

  return (
    <div className="file-upload">
      <label className="file-upload__label" htmlFor="fileField">
        {renderPreview()}
      </label>
      <input
        name="file"
        type="file"
        id="fileField"
        className="file-upload__input"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileUpload;
