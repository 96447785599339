import React from "react";
import AddStaff from "../../pages/Staff/AddStaff/AddStaff";

const StaffRoutes = {
  path: "/staff",
  children: [
    {
      path: "addstaff",
      element: <AddStaff />,
    },
  ],
};

export default StaffRoutes;
