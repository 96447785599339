import React, { useEffect, useState } from "react";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loader/ApiLoader";
import { startApiCall } from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { NilhansbookingRelatedEndPoint } from "../../api/Endpoints";
import { toast } from "react-toastify";

const EditBookig = ({ setIsEdit, editBooking, handleRefresh, isEdit }) => {
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [issuedPnr, setIssuedPnr] = useState("");
  const [moc, setMoc] = useState("");
  const [transaction, setTransaction] = useState("");
  const [issuance, setIssuance] = useState("");

  const handleReset = () => {
    setIsEdit(false);
    setloader(false);
    seterrorMessage("");
    setIssuedPnr("");
    setMoc("");
    setTransaction("");
    setIssuance("");
  };

  const haandleEditBooking = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      bookingId: editBooking?._id,
      mco: moc,
      issuance,
      transaction,
      issuedPnr,
    };
    const res = await ApiCall(
      "post",
      NilhansbookingRelatedEndPoint.editBooking,
      data
    );
    if (res?.success) {
      toast.success("Edit booking successfully!");
      handleReset();
      handleRefresh();
    } else {
      setloader(false);
      seterrorMessage(res?.error);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIssuedPnr(editBooking?.issuedPnr);
      setMoc(editBooking?.mco);
      setIssuance(editBooking?.issuance);
      setTransaction(editBooking?.transaction);
    }
  }, [isEdit]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Edit Booking</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <form onSubmit={haandleEditBooking}>
              <div className="row gx-3 py-2">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text-capitalize">Issued Pnr</label>
                    <input
                      className="form-control"
                      type="text"
                      value={issuedPnr}
                      onChange={(e) => setIssuedPnr(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text-capitalize">MOC</label>
                    <input
                      className="form-control"
                      type="text"
                      value={moc}
                      onChange={(e) => setMoc(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text-capitalize">Transaction</label>
                    <input
                      className="form-control"
                      type="text"
                      value={transaction}
                      onChange={(e) => setTransaction(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text-capitalize">Issuance</label>
                    <input
                      className="form-control"
                      type="text"
                      value={issuance}
                      onChange={(e) => setIssuance(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer mt-3 justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger mr-3"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBookig;
