import React from "react";
import ManagerList from "../../pages/Staff/ManagerList/ManagerList";

const ManagerRoutes = {
  path: "/staff",
  children: [
    {
      path: "managerlist",
      element: <ManagerList />,
    },
  ],
};

export default ManagerRoutes;
