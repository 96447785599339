import React, { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import {
  NilhansbookingRelatedEndPoint,
  bookingRelatedEndPoint,
} from "../../api/Endpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../Global/globalFunctions";
import DataLoader from "../../components/Loader/DataLoader";
import NoData from "../../components/NoData/NoData";
import from_to from "../../assets/from_to.jpg";
import moment from "moment";
import PriceDetails from "./PriceBreakout";
import ConvertToContact from "../Customer/ConvertToContact";
import BookingDetailsConfirmStatus from "./BookingDetailsConfirmStatus";

const BookingDetails = () => {
  const isNilhans =
    (localStorage.getItem("urltype") === "Nilhans" || localStorage.getItem("urltype") === "BestAirFares")
      ? NilhansbookingRelatedEndPoint.getBookingById
      : bookingRelatedEndPoint.getBookingById;
  const navigate = useNavigate();
  const { bookingid } = useParams();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [bookingLifecycle, setBookingLifecycle] = useState([]);
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [priceDetails, setPriceDetails] = useState(false);
  const [toggleDetails, setToggleDetails] = useState("customer");
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleGetLifeCycle = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: { booking_id: bookingid },
    };
    const res = await ApiCall(
      "post",
      bookingRelatedEndPoint.getBookingLifeCycle,
      data
    );
    if (res.success) {
      setBookingLifecycle(res.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetBookingDetails = async () => {
    startApiCall(null, setloader2);
    const data = {
      bookingId: bookingid,
    };
    const res = await ApiCall("post", isNilhans, data);
    if (res?.success) {
      setBookingData(res?.result);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetBookingDetails();
    handleGetLifeCycle();
  }, []);

  const allStatus = [
    {
      name: "Pending",
    },
    {
      name: "Processing",
    },
    {
      name: "Follow up",
    },
    {
      name: "Failed",
    },
    {
      name: "Confirmed",
    },
    {
      name: "Cancel",
    },
    {
      name: "Chargeback",
    },
  ];

  // carousel
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + bookingData?.travellerDetails?.length) %
        bookingData?.travellerDetails?.length
    );
  };
  const nextSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % bookingData?.travellerDetails?.length
    );
  };

  return (
    <div className="container pt-3 pb-5">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div className="d-flex align-items-center gap-2">
          <span
            className="btn btn-light border"
            onClick={() => {
              if (locationData?.module === "allbooking") {
                navigate("/booking/allbooking", {
                  state: { custId: bookingData?.customer },
                });
              } else if (locationData?.module === "assigned") {
                navigate("/booking/assignedbooking");
              } else if (locationData?.module === "employee") {
                navigate("/booking/assignedemployee", {
                  state: { empId: bookingData?.assign_to?._id },
                });
              } else {
                navigate("/booking/unassignedbooking");
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
          </span>
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">
            Booking Details
            <span
              className="btn btn-light ml-2"
              onClick={() => {
                handleGetBookingDetails();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
              </svg>
            </span>
          </h4>
        </div>
      </header>

      {!loader2 && (
        <>
          <div className="row g-3">
            <div className="col-md-6 pb-3">
              <div className="card card-border px-4 py-3 h-100">
                <div className="d-flex align-items-center justify-content-between gap-3">
                  <div className="d-flex align-items-center justify-content-between gap-2 w-200p">
                    <p className="text-dark font-4 font-weight-bold mb-0">
                      {toggleDetails === "customer"
                        ? "Customer"
                        : toggleDetails === "card"
                        ? "Card"
                        : null}
                      &nbsp;Details
                    </p>
                    <span
                      className="btn btn-icon-2 btn-light p-0"
                      onClick={() =>
                        setToggleDetails(
                          toggleDetails === "customer" ? "card" : "customer"
                        )
                      }
                    >
                      {toggleDetails === "customer" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-credit-card"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                          <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                        </svg>
                      ) : toggleDetails === "card" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                        </svg>
                      ) : null}
                    </span>
                  </div>
                  {!bookingData?.isCustomer && (
                    <button
                      className="btn btn-primary btn-sm d-flex align-items-center"
                      onClick={() => setConfirmModal(true)}
                    >
                      Convert&nbsp;<span className="d-none-sm">to Contact</span>
                    </button>
                  )}
                </div>
                {toggleDetails === "customer" ? (
                  <div className="row g-3 mt-3">
                    <div className="col-md-6">
                      <span>Name : {bookingData?.billing_details?.name}</span>
                    </div>
                    <div className="col-12">
                      <span>Email : {bookingData?.billing_details?.email}</span>
                    </div>
                    <div className="col-md-6">
                      <span>Phone : {bookingData?.billing_details?.phone}</span>
                    </div>
                    <div className="col-md-6">Pincode : {bookingData?.billInfo?.zip}</div>
                    <div className="col-md-6">State : {bookingData?.billInfo?.state}</div>
                    <div className="col-md-6">City : {bookingData?.billInfo?.city}</div>
                    <div className="col-12">Address : {bookingData?.billInfo?.billingAddress?.address}</div>
                  </div>
                ) : toggleDetails === "card" ? (
                  <div className="row g-3 mt-3">
                    <div className="col-12">
                      Card holder : {bookingData?.billInfo?.card_holder_name}
                    </div>
                    <div className="col-md-6">
                      <span>Card name : {bookingData?.billInfo?.card_name}</span>
                    </div>
                    <div className="col-12">
                      <span>Card number : {bookingData?.billInfo?.card_number}</span>
                    </div>
                    <div className="col-md-6">
                      <span>Expiry date : {bookingData?.billInfo?.card_exp_date}</span>
                    </div>
                    <div className="col-md-6">
                      <span>CVV : {bookingData?.billInfo?.card_cvv}</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 pb-3">
              {bookingData?.travellerDetails?.length > 0 ? (
                <div className="card card-border px-4 pb-3 h-100">
                  <div className="d-flex align-items-end justify-content-between gap-3">
                    <span className="mt-3 text-dark font-4 font-weight-bold">
                      Traveler details
                    </span>
                    <span>
                      Total travelers :&nbsp;
                      {bookingData?.travellerDetails?.length}
                    </span>
                  </div>

                  <div className="custom-carousel mt-1">
                    <div
                      className="custom-carousel-inner w-100"
                      style={{
                        display: "flex",
                        transform: `translateX(-${currentIndex * 100}%)`,
                        transition: "transform 0.5s ease-in-out",
                      }}
                    >
                      {bookingData?.travellerDetails?.map((traveler, index) => (
                        <div
                          key={index}
                          className={`custom-carousel-item ${
                            index === currentIndex ? "active" : ""
                          }`}
                          style={{
                            flex: "0 0 100%",
                            paddingInline: "2rem",
                          }}
                        >
                          <div className="border bg-light d-flex flex-column gap-1 p-2 px-3 rounded">
                            <span className="text-nowrap">
                              Name : {traveler?.firstName} {traveler?.lastName}
                            </span>
                            <span className="text-nowrap">
                              DOB :{" "}
                              {moment(traveler?.date).format("DD/MM/YYYY")}
                            </span>
                            <span className="text-nowrap">
                              Gender : {traveler?.gender}
                            </span>
                            <span className="text-nowrap">
                              Type : {traveler?.travellerType}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    {currentIndex > 0 && (
                      <span
                        className="custom-carousel-control-prev"
                        onClick={prevSlide}
                      >
                        <span
                          className="custom-carousel-control-prev-icon d-flex align-items-center justify-content-center"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            className="bi bi-chevron-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                            />
                          </svg>
                        </span>
                      </span>
                    )}
                    {currentIndex !==
                      bookingData?.travellerDetails?.length - 1 && (
                      <span
                        className="custom-carousel-control-next"
                        onClick={nextSlide}
                      >
                        <span
                          className="custom-carousel-control-next-icon d-flex align-items-center justify-content-center"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            className="bi bi-chevron-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4 gap-2">
            <div className="col-md-12 px-0">
              <div className="d-flex align-items-center gap-3 justify-content-between">
                <div className="d-flex mt-2">
                  <span className="text-primary mt-1 mr-2">Assigned To : </span>
                  <span className="text-dark mt-1">
                    {bookingData?.assign_to
                      ? bookingData?.assign_to?.name
                      : "No Employee Assigned"}
                  </span>

                  {bookingData?.status && (
                    <>
                      <span className="text-primary mt-1 ml-2">Status : </span>
                      <div className="dropdown">
                        <span
                          className="dropdown-toggle pointer border p-1 bg-primary text-white px-3 ml-2"
                          type="button"
                          id="action_dropdown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {bookingData?.status}
                        </span>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="action_dropdown"
                          style={{ minWidth: "0" }}
                        >
                          {allStatus?.map((st) => {
                            return (
                              <button
                                className="dropdown-item"
                                disabled={st.name === bookingData.status}
                                onClick={() => setSelectedStatus(st?.name)}
                                data-toggle="modal"
                                data-target="#booking_details_status_update"
                              >
                                {st?.name}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <span>
                  Total Price :&nbsp;
                  <span className="text-primary mr-3 fw-bold ">
                    {bookingData?.booking_details?.price?.total}
                    {bookingData?.isOnline && (
                      <span
                        className="ml-2 mr-3 pointer"
                        onClick={() => setPriceDetails(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle mt-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                      </span>
                    )}
                  </span>
                  PNR No. :{" "}
                  <span className="text-primary">{bookingData?.pnr_no}</span>
                </span>
              </div>
              <div
                className={`col-12 mt-3 ${
                  !bookingData?.isOnline ? "ml-5" : ""
                }`}
              >
                <div
                  className={`card shadow px-5 py-2 mb-3 ${
                    !bookingData?.isOnline ? "w-300p" : ""
                  }`}
                >
                  <div className="d-flex align-items-center justify-content-between gap-1rem">
                    {bookingData?.isOnline && (
                      <>
                        <div className="d-flex flex-column gap-1">
                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                            src={
                              bookingData?.booking_details?.itineraries?.[0]
                                ?.segments?.[0]?.airlineImage
                            }
                            alt="logo"
                          />
                          {bookingData?.wayOfFlight === "round_trip" && (
                            <img
                              style={{
                                width: "80px",
                                height: "80px",
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                              src={
                                bookingData?.booking_details?.itineraries?.[1]
                                  ?.segments?.[0]?.airlineImage
                              }
                              alt="logo"
                            />
                          )}
                        </div>

                        <div className="d-flex flex-column gap-1">
                          <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                            <div className="d-flex flex-column align-items-end">
                              <b>
                                {
                                  bookingData?.booking_details?.itineraries?.[0]
                                    ?.segments?.[0]?.departure?.iataCode?.[0]
                                }
                              </b>
                              <p className="mb-0">
                                {moment(
                                  bookingData?.booking_details?.itineraries?.[0]
                                    ?.segments?.[0]?.departure?.at
                                ).format("DD/MM/YYYY - h:mm A")}
                              </p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <span>
                                {
                                  bookingData?.booking_details?.itineraries?.[0]
                                    ?.duration
                                }
                              </span>
                              <img
                                style={{ minWidth: "80", maxWidth: "220px" }}
                                src={from_to}
                                alt=""
                              />
                              <p className="mb-0 text-secondary fs-12p">
                                {bookingData?.booking_details?.itineraries?.[0]
                                  ?.segments?.length === 1 ? (
                                  "Direct"
                                ) : (
                                  <span className="pointer">
                                    {bookingData?.booking_details
                                      ?.itineraries?.[0]?.segments?.length - 1}
                                    &nbsp;Stop
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="d-flex flex-column align-items-start">
                              <b>
                                {
                                  bookingData?.booking_details?.itineraries?.[0]
                                    ?.segments[
                                    bookingData?.booking_details
                                      ?.itineraries?.[0]?.segments?.length - 1
                                  ]?.arrival?.iataCode?.[0]
                                }
                              </b>
                              <p className="mb-0">
                                {moment(
                                  bookingData?.booking_details?.itineraries?.[0]
                                    ?.segments[
                                    bookingData?.booking_details
                                      ?.itineraries?.[0]?.segments?.length - 1
                                  ]?.arrival?.at
                                ).format("DD/MM/YYYY - h:mm A")}
                              </p>
                            </div>
                          </div>

                          {bookingData?.wayOfFlight === "round_trip" && (
                            <div className="d-flex gap-12p align-items-center mb-0 border-dashed px-4 py-1 rounded border-secondary">
                              <div className="d-flex flex-column align-items-end">
                                <b>
                                  {
                                    bookingData?.booking_details
                                      ?.itineraries?.[1]?.segments?.[0]
                                      ?.departure?.iataCode?.[0]
                                  }
                                </b>
                                <p className="mb-0">
                                  {moment(
                                    bookingData?.booking_details
                                      ?.itineraries?.[1]?.segments?.[0]
                                      ?.departure?.at
                                  ).format("DD/MM/YYYY - h:mm A")}
                                </p>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <span>
                                  {
                                    bookingData?.booking_details
                                      ?.itineraries?.[1]?.duration
                                  }
                                </span>
                                <img
                                  style={{ minWidth: "80", maxWidth: "220px" }}
                                  src={from_to}
                                  alt=""
                                />
                                <p className="mb-0 text-secondary fs-12p">
                                  {bookingData?.booking_details
                                    ?.itineraries?.[1]?.segments?.length ===
                                  1 ? (
                                    "Direct"
                                  ) : (
                                    <span className="pointer">
                                      {bookingData?.booking_details
                                        ?.itineraries?.[1]?.segments?.length -
                                        1}
                                      &nbsp;Stop
                                    </span>
                                  )}
                                </p>
                              </div>
                              <div className="d-flex flex-column align-items-start">
                                <b>
                                  {
                                    bookingData?.booking_details
                                      ?.itineraries?.[1]?.segments[
                                      bookingData?.booking_details
                                        ?.itineraries?.[0]?.segments?.length - 1
                                    ]?.arrival?.iataCode?.[0]
                                  }
                                </b>
                                <p className="mb-0">
                                  {moment(
                                    bookingData?.booking_details
                                      ?.itineraries?.[1]?.segments[
                                      bookingData?.booking_details
                                        ?.itineraries?.[1]?.segments?.length - 1
                                    ]?.arrival?.at
                                  ).format("DD/MM/YYYY - h:mm A")}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    <div className="d-flex flex-column mt-2">
                      <span className="d-flex align-items-center mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffa500"
                          class="bi bi-check-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                        </svg>
                        &nbsp;<b>Class</b>&nbsp;:&nbsp;
                        {bookingData?.travelClass}
                      </span>

                      <span className="d-flex align-items-center mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffa500"
                          class="bi bi-airplane-engines-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0" />
                        </svg>
                        &nbsp;<b>Trip type</b>&nbsp;:&nbsp;
                        {bookingData?.wayOfFlight === "one_way"
                          ? "One Way"
                          : "Round Trip"}
                      </span>

                      <span className="d-flex align-items-center mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffa500"
                          class="bi bi-check-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                        </svg>
                        &nbsp;<b>Travelers</b>&nbsp;:&nbsp;Adult:{" "}
                        {bookingData?.adults},
                        {bookingData?.children !== "0" && (
                          <>Child: {bookingData?.children},</>
                        )}
                        {bookingData?.infants !== "0" && (
                          <>Infant: {bookingData?.infants}</>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {loader2 && <DataLoader />}
      <div className="card card-border px-3 py-2 mnh-450p mt-4 overflow-auto col-sm-12">
        {!loader && (
          <>
            <div className="d-flex justify-content-start gap-2">
              <p className="text-primary font-4">
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.7057 10.3333C29.7057 11.7542 28.5432 12.9167 27.1224 12.9167C26.8899 12.9167 26.6703 12.8908 26.4636 12.8263L21.8653 17.4117C21.9299 17.6183 21.9557 17.8508 21.9557 18.0833C21.9557 19.5042 20.7932 20.6667 19.3724 20.6667C17.9516 20.6667 16.7891 19.5042 16.7891 18.0833C16.7891 17.8508 16.8149 17.6183 16.8795 17.4117L13.5857 14.1179C13.3791 14.1825 13.1466 14.2083 12.9141 14.2083C12.6816 14.2083 12.4491 14.1825 12.2424 14.1179L6.36531 20.0079C6.4299 20.2146 6.45573 20.4342 6.45573 20.6667C6.45573 22.0875 5.29323 23.25 3.8724 23.25C2.45156 23.25 1.28906 22.0875 1.28906 20.6667C1.28906 19.2458 2.45156 18.0833 3.8724 18.0833C4.1049 18.0833 4.32448 18.1092 4.53115 18.1737L10.4211 12.2967C10.3566 12.09 10.3307 11.8575 10.3307 11.625C10.3307 10.2042 11.4932 9.04167 12.9141 9.04167C14.3349 9.04167 15.4974 10.2042 15.4974 11.625C15.4974 11.8575 15.4716 12.09 15.407 12.2967L18.7007 15.5904C18.9074 15.5258 19.1399 15.5 19.3724 15.5C19.6049 15.5 19.8374 15.5258 20.0441 15.5904L24.6295 10.9921C24.5649 10.7854 24.5391 10.5658 24.5391 10.3333C24.5391 8.9125 25.7016 7.75 27.1224 7.75C28.5432 7.75 29.7057 8.9125 29.7057 10.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              <span className="text-primary font-4">Lifecycle</span>
              {bookingLifecycle?.length > 0 && (
                <p className="text-primary font-4">
                  <span className="badge badge-primary">
                    {bookingLifecycle?.length}
                  </span>
                </p>
              )}
              <span
                className="btn btn-light mr-2 pointer"
                style={{
                  background: "transparent",
                  outline: "none",
                  border: "none",
                }}
                onClick={() => {
                  handleGetLifeCycle();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                </svg>
              </span>
            </div>

            <table className="table table-striped mb-0 text-center">
              <thead>
                <tr>
                  <th scope="col">Sr no </th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Action By</th>
                  <th scope="col">Action On</th>
                  <th scope="col">Status</th>
                  <th scope="col">Note</th>
                </tr>
              </thead>
              <tbody>
                {bookingLifecycle
                  ?.slice()
                  ?.reverse()
                  ?.map((data, index) => {
                    return (
                      <tr>
                        <td>{bookingLifecycle?.length - index} .</td>
                        <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                        <td>{data?.actionBy?.name}</td>
                        <td>{data?.actionOn ? data?.actionOn?.name : "-"}</td>
                        <td>{data?.lifeCycle}</td>
                        <td>{data?.comment ? data?.comment : "-"}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
        {loader && <DataLoader />}
        {!loader && bookingLifecycle?.length === 0 && (
          <NoData text="No Lifecycle created" />
        )}
      </div>

      {confirmModal && (
        <ConvertToContact
          setConfirmModal={setConfirmModal}
          bookingId={bookingid}
          handleGetBookingDetails={handleGetBookingDetails}
        />
      )}
      {priceDetails && (
        <PriceDetails
          setPriceDetails={setPriceDetails}
          bookingData={bookingData}
        />
      )}

      <BookingDetailsConfirmStatus
        bookingid={bookingid}
        bookingData={bookingData}
        selectedStatus={selectedStatus}
        handleGetBookingDetails={handleGetBookingDetails}
        handleGetLifeCycle={handleGetLifeCycle}
      />
    </div>
  );
};

export default BookingDetails;
