import React, { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { creationEndpoint } from "../../api/Endpoints";
import { startApiCall } from "../../Global/globalFunctions";
import NoData from "../../components/NoData/NoData";
import DataLoader from "../../components/Loader/DataLoader";
import moment from "moment";
import Pagination from "../../components/Pagination";

const AllLoginProfile = () => {
  const [loader, setloader] = useState(false);
  const [allProfiles, setAllProfiles] = useState([]);
  const [filterType, setFilterType] = useState("staff");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllLogInProfiles = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        role: filterType
      },
    };
    const res = await ApiCall("post", creationEndpoint.getAccessRecords, data);
    if (res?.success) {
      setAllProfiles(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllLogInProfiles();
  }, [page, pageLimit,filterType]);

  return (
    <div className="p-3">
      <header className="d-flex align-items-center justify-content-between mb-3 sticky-header">
        <div>
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">
            Logged In Profiles
          </h4>
        </div>

        <div className="d-flex ms-auto gap-2">
          <div>
            <select
              className="form-control"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="staff">Staff</option>
              <option value="customer">Customer</option>
            </select>
          </div>
          <span
            className="btn btn-light mr-2"
            onClick={handleGetAllLogInProfiles}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
        </div>
      </header>
      {!loader && allProfiles?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-between mb-5">
            <div className="table-responsive scrollbar-hide pb-4">
              <table className="table table-striped mb-0 text-center text-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Sr No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Access Time</th>
                    <th scope="col">Ip Address</th>
                    <th scope="col">Access Type</th>
                  </tr>
                </thead>
                <tbody>
                  {allProfiles?.map((data, index) => {
                    return (
                      <tr>
                        <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                        <td className="text-capitalize">
                          {data?.userId?.name}
                        </td>
                        <td>{data?.userId?.email}</td>
                        <td>
                          {moment(data?.dateAndTime).format(
                            "DD/MM/YYYY, HH:mm"
                          )}
                        </td>
                        <td>{data?.ipAddress ? data?.ipAddress : "-"}</td>
                        <td className="text-capitalize">{data?.accessType}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="fixed-bottom p-2 bg-white border-top"
            style={{
              zIndex: 1,
            }}
          >
            <Pagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allProfiles?.length}
              align={"end"}
            />
          </div>
        </>
      )}
      {loader && <DataLoader />}
      {!loader && allProfiles?.length === 0 && <NoData />}
    </div>
  );
};

export default AllLoginProfile;
