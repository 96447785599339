import React, { useEffect, useState } from "react";
import AddIp from "./AddIp";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../Global/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { IpRelatedEndPoint } from "../../api/Endpoints";
import DataLoader from "../../components/Loader/DataLoader";
import NoData from "../../components/NoData/NoData";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";

const AllIpAddress = () => {
  const [allIps, setAllIps] = useState([]);
  const [loader, setloader] = useState(false);
  const [addIp, setAddIp] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editIp, setEditIp] = useState("");
  const [loader2, setloader2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const handleReset = () => {
    setloader2(false);
    seterrorMessage("");
    setConfirmModal(false);
    setEditIp("");
  };

  const handleGetAllIp = async () => {
    startApiCall(null, setloader);
    const data = {};
    const res = await ApiCall("post", IpRelatedEndPoint.getAllIp, data);
    if (res?.success) {
      setAllIps(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllIp();
  }, []);

  const handleDeleteIp = async () => {
    startApiCall(seterrorMessage, setloader2);
    const data = {
      ip_address: editIp?.ip_address,
    };
    const res = await ApiCall("post", IpRelatedEndPoint.deleteIp, data);
    if (res?.success) {
      handleReset();
      handleGetAllIp();
      toast.success("Delete Ip address successfully!");
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div className="container pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <h4 className="text-uppercase mb-0 mr-2 headline-sm">
            All Ip Address
          </h4>
          <span
            className="btn btn-outline-primary text-capitalize"
            onClick={() => setAddIp(true)}
          >
            Add Ip
          </span>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span
            className="btn btn-light mr-2"
            onClick={() => {
              handleGetAllIp();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
        </form>
      </div>

      {!loader && allIps?.length > 0 && (
        <div className="d-flex flex-column justify-content-between">
          <div className="table-responsive scrollbar-hide pb-4">
            <table className="table table-striped mb-0 text-center text-nowrap">
              <thead>
                <tr>
                  <th scope="col">Sr no.</th>
                  <th scope="col">Ip Address</th>
                  <th scope="col">Added By</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {allIps?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1} .</td>
                      <td>{data?.ip_address}</td>
                      <td>{data?.addedBy?.name}</td>
                      <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                      <td>
                        <span
                          className=" pointer"
                          onClick={() => {
                            setConfirmModal(true);
                            setEditIp(data);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash text-danger"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {loader && <DataLoader />}
      {!loader && allIps?.length === 0 && <NoData />}
      {addIp && <AddIp setAddIp={setAddIp} handleGetAllIp={handleGetAllIp} />}
      {confirmModal && (
        <ConfirmModal
          setConfirmModal={setConfirmModal}
          text="Are You Sure You Want to Delete this Ip ?"
          handleSubmit={handleDeleteIp}
          loader={loader2}
          errorMessage={errorMessage}
          handleReset={handleReset}
        />
      )}
    </div>
  );
};

export default AllIpAddress;
