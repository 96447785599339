import React from "react";
import BlogList from "../../pages/Blog/BlogList/BlogList";
import AddBlog from "../../pages/Blog/AddBlog/AddBlog";

const BlogRoutes = {
  path: "/blog",
  children: [
    {
      path: "addblog",
      element: <AddBlog />,
    },
    {
      path: "bloglist",
      element: <BlogList />,
    },
  ],
};

export default BlogRoutes;
