import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { getCurrentLogo, startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { creationEndpoint } from "../../../api/Endpoints";
import ToasterMessages from "../../../Global/ToasterMessages";
import { toast } from "react-toastify";
import ApiLoader from "../../../components/Loader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { LOGINUSER } from "../../../contextApi/actions/auth.actions";
import { AuthContext } from "../../../contextApi/context";
import { publicIpv4 } from "public-ip";
import SwitchingRoute from "../SwitchingRoute/SwitchingRoute";

const Login = () => {
  const { authDispatch } = useContext(AuthContext);
  const [loader, setloader] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [frogotPassword, setForgotPassword] = useState(false);
  const [firstStep, setfirstStep] = useState(true);
  const ip = sessionStorage.getItem("ipaddress");
  const [ipAddress, setIpAddress] = useState("");
  const [swtichingRoute, setSwitchingRoute] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("urltype") === null) {
      localStorage.setItem("urltype", "Ogule");
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      email,
      password,
      isCustomer: false,
    };
    const customHeaders = {
      operation: "login",
    };
    const res = await ApiCall("post", creationEndpoint.userLogin, data, customHeaders);
    if (res?.success) {
      setloader(false);
      toast.success(ToasterMessages.LOGINMESSGAE);
      authDispatch({
        type: LOGINUSER,
        payload: res.result,
      });
      // sessionStorage.setItem("ipaddress", res.result.user.ip_address);
      window.location.href = "/";
    } else {
      setloader(false);
      seterrorMessage(res?.error);
    }
  };

  return (
    <>
      <div className="login-bg d-flex">
        <div
          className="col-md-8 d-flex flex-column justify-content-center m-auto p-3 pb-4 position-relative"
          style={{
            maxWidth: "700px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            borderRadius: "10px",
            background: "linear-gradient(135deg, #e6e9f0, #eef1f5)",
          }}
        >
          <img
            className="position-absolute"
            style={{
              width: "260px",
              objectFit: "contain",
              objectPosition: "center",
              top: "-70px",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            src={getCurrentLogo()}
            alt=""
          />

          <div className="d-flex justify-content-center">
            <h4 className="text-uppercase text-center mb-3">
              Login With&nbsp;
              <span
                className={`font-weight-bold ${
                  localStorage.getItem("urltype") === "Ogule"
                    ? "text-primary"
                    : "text-success"
                }`}
              >
                {localStorage.getItem("urltype")}
              </span>
            </h4>
            <span
              className="d-flex ml-3 mt-1 text-primary pointer"
              style={{ textDecoration: "underline" }}
              onClick={() => setSwitchingRoute(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-arrow-repeat mt-1"
                viewBox="0 0 16 16"
              >
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
                />
              </svg>
              <p>Switch</p>
            </span>
          </div>

          <form onSubmit={handleLogin}>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="mail"
                    className="form-control"
                    name="fullName"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-slash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                            <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="d-flex justify-content-end mb-2">
                  <span
                    className="text-secondary pointer"
                    onClick={() => {
                      setForgotPassword(true);
                      setfirstStep(true);
                    }}
                  >
                    Forgot Password ?
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="p-xs mt-2 text-center">
                    <Link to="/signup">
                      <u>Create new account</u>
                    </Link>
                  </p>
                  <button type="submit" className="btn btn-primary btn-lg px-4">
                    {loader ? <ApiLoader /> : "Login"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {frogotPassword && (
        <ForgotPassword
          setForgotPassword={setForgotPassword}
          firstStep={firstStep}
          setfirstStep={setfirstStep}
        />
      )}

      {swtichingRoute && (
        <SwitchingRoute setSwitchingRoute={setSwitchingRoute} />
      )}
    </>
  );
};

export default Login;
