import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ToasterMessages from "../../../Global/ToasterMessages";
import { toast } from "react-toastify";
import { PaymentRelatedEndPoint } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiLoader from "../../../components/Loader/ApiLoader";
import DataLoader from "../../../components/Loader/DataLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { AuthContext } from "../../../contextApi/context";

const AddPayment = () => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [loader, setloader] = useState(false);
  const [paymentLoader, setpaymentLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("pending");
  const [note, setNote] = useState("");

  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [paymentDetails, setpaymentDetails] = useState([]);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setBookingId("");
    setAmount("");
    setNote("");
    setStatus("pending");
  };

  const handleAddPayment = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      booking_id: bookingId,
      amount: amount,
      note: note,
      status: status,
      // created_by: "",
    };
    const res = await ApiCall("post", PaymentRelatedEndPoint.addPayment, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditPayment = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      transactionId: locationData?.trnId,
      booking_id: bookingId,
      amount: amount,
      note: note,
      status: status,
      // created_by: "",
    };
    const res = await ApiCall("post", PaymentRelatedEndPoint.editPayment, data);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetOfferDetails = async () => {
    startApiCall(null, setpaymentLoader);
    const data = {
      transactionId: locationData?.trnId,
    };
    const res = await ApiCall(
      "post",
      PaymentRelatedEndPoint.getPaymnetById,
      data
    );
    if (res?.success) {
      setpaymentDetails(res?.result);
      setpaymentLoader(false);
    } else {
      setpaymentLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.trnId) {
      handleGetOfferDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.trnId) {
      setBookingId(paymentDetails?.booking_id);
      setAmount(paymentDetails?.amount);
      setNote(paymentDetails?.note);
      setStatus(paymentDetails?.status);
    }
  }, [locationData, paymentDetails]);

  return (
    <div className="container pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex align-items-center gap-3 mb-3">
            <Link to="/allpayments" className="btn btn-light border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>
            </Link>
            <h4 className="text-uppercase mb-0">
              {locationData?.trnId ? "Edit" : "Add"} payment
            </h4>
          </div>
          {!paymentLoader && (
            <form
              onSubmit={(e) => {
                if (locationData?.trnId) {
                  handleEditPayment(e);
                } else {
                  handleAddPayment(e);
                }
              }}
            >
              <div className="form-group">
                <label>Booking ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={bookingId}
                  onChange={(e) => setBookingId(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Status</label>
                <select
                  class="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option>Pending</option>
                  <option>Done</option>
                </select>
              </div>
              <div className="form-group">
                <label>Note</label>
                <textarea
                  class="form-control"
                  rows="3"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <hr className="mb-4" />
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-danger mr-3 px-5"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-success px-5">
                  {loader ? (
                    <ApiLoader />
                  ) : locationData?.trnId ? (
                    "Edit"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      {paymentLoader && <DataLoader />}
    </div>
  );
};

export default AddPayment;
