import ApiCall from "../api/ApiCall";
import { AWS_BUCKET_NAME } from "../api/base";
import { AwsS3Routes } from "../api/Endpoints";
import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY } from "../aws-s3";
import AWS from "aws-sdk";

//******* START API CALL ********/
export const startApiCall = (seterrorMessage, setLoader) => {
  {
    seterrorMessage !== null && seterrorMessage("");
  }
  setLoader(true);
  setTimeout(() => {
    setLoader(false);
  }, 50000);
};

export const userLogoutHard = async (data) => {
  localStorage.clear();
  window.location.href = "/login";
};

//********* REVERSE DATE FORMATER ***********/
export const reverseDateFormatter = (isDate) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = new Date(isDate).toLocaleDateString("en-IN", options);
  const [day, month, year] = formattedDate.split("/");

  return `${year}-${month}-${day}`;
};

//********* DATE FORMATER ***********/
export const dateFormatter = (isDate) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return new Date(isDate).toLocaleDateString("en-IN", options);
};

//********* DATE & TIME FORMATER ***********/
export const dateAndTimeFormatter = (isDate) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Date(isDate).toLocaleDateString("en-IN", options);
};

export const handlePreviewFileInLocal = (event) => {
  if (event?.target?.files && event.target.files.length > 0) {
    let img = URL.createObjectURL(event.target.files[0]);
    return img;
  }
};

export const getCurrentLogo = () => {
  let nilhansLogo = "https://ogule-website.s3.ap-south-1.amazonaws.com/7.png";
  let oguleLogo =
    "https://ogule-website.s3.ap-south-1.amazonaws.com/oguleNewLogo.png";
  let bestAirFaresLogo =
    "https://ogule-website.s3.ap-south-1.amazonaws.com/bestAirfaresLogo.png";
  return localStorage.getItem("urltype") === "Nilhans"
    ? nilhansLogo
    : localStorage.getItem("urltype") === "BestAirFares"
    ? bestAirFaresLogo
    : oguleLogo;
};

// Updated Image Function
export const handleGeneratePreSignedURLAWSS3 = async (image) => {
  let params = new URLSearchParams({
    fileType: image?.type,
    fileName: image?.name,
  });
  let res = await ApiCall(
    "get",
    `${AwsS3Routes.getPreSignedURL}?${params.toString()}`
  );
  return res?.directory || null;
};

export const handleUploadImgFile = async (file) => {
  try {
    let mainFile = file.target.files[0];
    const { presignedUrl, fileUrl } = await handleGeneratePreSignedURLAWSS3(
      mainFile
    );
    const uploadResponse = await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": mainFile.type,
      },
      body: mainFile,
    });

    if (uploadResponse.ok) {
      console.log("File uploaded successfully");
      return fileUrl;
    } else {
      console.error(
        "File upload failed:",
        uploadResponse.status,
        uploadResponse.statusText
      );
      return null;
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
};