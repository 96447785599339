import React from "react";
import TagList from "../../pages/Tag/TagList/TagList";
import AddTag from "../../pages/Tag/AddTag/AddTag";

const TagRoutes = {
  path: "/blog",
  children: [
    {
      path: "addtag",
      element: <AddTag />,
    },
    {
      path: "taglist",
      element: <TagList />,
    },
  ],
};

export default TagRoutes;
