import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { startApiCall } from "../../../Global/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  NilhansUserCardRelatedEndPoint,
  UserCardRelatedEndPoint,
} from "../../../api/Endpoints";
import DataLoader from "../../../components/Loader/DataLoader";
import NoData from "../../../components/NoData/NoData";

const UserCardList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setloader] = useState("");
  const [allCards, setAllCards] = useState([]);
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const isNilhans =
    (localStorage.getItem("urltype") === "Nilhans" || localStorage.getItem("urltype") === "BestAirFares")
      ? NilhansUserCardRelatedEndPoint.getAllCards
      : UserCardRelatedEndPoint.getAllCards;

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleGetAllCards = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        user_id: id,
      },
    };
    const res = await ApiCall("post", isNilhans, data);
    if (res?.success) {
      setAllCards(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllCards();
  }, []);

  return (
    <div className="container pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <span
            className="btn btn-light border"
            onClick={() => navigate("/customer/customerlist")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>
          </span>
          <h4 className="text-uppercase mb-0 ml-2 mr-2 headline-sm">
            User Card List
          </h4>
          <span
            className="btn btn-outline-primary text-capitalize"
            onClick={() =>
              navigate(`/customer/addusercard/${id}`, {
                state: {
                  userId: locationData?.userId,
                },
              })
            }
          >
            Add new card
          </span>
        </div>
        <form className="d-flex ms-auto gap-2">
          <span className="btn btn-light mr-2" onClick={handleGetAllCards}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
            </svg>
          </span>
          <input
            className="form-control mr-sm-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </form>
      </div>
      {!loader && allCards?.length > 0 && (
        <div className="d-flex flex-column justify-content-between">
          <div className="table-responsive scrollbar-hide pb-4">
            <table className="table table-striped mb-0 text-center text-nowrap">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Card Name</th>
                  <th scope="col">Card No.</th>
                  <th scope="col">Expire Date</th>
                  <th scope="col">CVV No.</th>
                  <th scope="col">Name On Card</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {allCards?.map((data, index) => {
                  return (
                    <tr>
                      <td>{index + 1} .</td>
                      <td>{data?.card_name}</td>
                      <td>{data?.card_no}</td>
                      <td>{data?.card_expiry_date}</td>
                      <td>{data?.card_cvv}</td>
                      <td>{data?.name_on_card}</td>
                      <td>
                        <div className="dropdown">
                          <span
                            className="dropdown-toggle"
                            type="button"
                            id="action_dropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-three-dots"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
                            </svg>
                          </span>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="action_dropdown"
                            style={{ minWidth: "0" }}
                          >
                            <span
                              className="dropdown-item"
                              onClick={() =>
                                navigate(`/customer/addusercard/${id}`, {
                                  state: {
                                    userId: locationData?.userId,
                                    cardId: data?._id,
                                  },
                                })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <Pagination /> */}
        </div>
      )}
      {loader && <DataLoader />}

      {!loader && allCards?.length === 0 && <NoData />}
    </div>
  );
};

export default UserCardList;
