import React from "react";
import FlightSearchRecord from "../../pages/SearchRecord/FlightSearchRecord";

const SearchRecordRoutes = {
  path: "/",
  children: [
    {
      path: "flightsearchrecord",
      element: <FlightSearchRecord />,
    },
  ],
};
export default SearchRecordRoutes;
