import React from "react";
import AddOfferPage from "../../pages/OfferPage/AddOfferPage/AddOfferPage";

const AddOfferPageRoutes = {
  path: "/offerpage",
  children: [
    {
      path: "addofferpage",
      element: <AddOfferPage />,
    },
  ],
};

export default AddOfferPageRoutes;
