import React, { useContext, useEffect, useState } from "react";
import { startApiCall } from "../../../Global/globalFunctions";
import { creationEndpoint } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import { GETUSER } from "../../../contextApi/actions/auth.actions";
import { AuthContext } from "../../../contextApi/context";
import ApiLoader from "../../../components/Loader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";

const UserInfoEdit = ({ user }) => {
  const { authDispatch } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user?.name);
      setPhone(user?.mobile_no);
    }
  }, []);

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setName("");
    setPhone("");
    const closeModalDom = document.getElementById("edit_name");
    if (closeModalDom) closeModalDom.click();
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      staffId: user?._id,
      name,
      mobile_no: phone,
      created_by: user?._id,
    };
    const res = await ApiCall("post", creationEndpoint.editUser, data);
    if (res.success) {
      handleReset();
      authDispatch({
        type: GETUSER,
        payload: res.result,
      });
      toast.success("User data updated successfully!");
    } else {
      setloader(false);
      seterrorMessage("");
    }
  };

  return (
    <div
      className="modal fade"
      id="edit_name"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleUpdateUser}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Personal information
              </h5>
              <span className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </span>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="user_name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type your name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="user_contact">Phone No.</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Type your phone number"
                  maxLength={10}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Discard
              </button>
              <button type="submit" className="btn btn-primary">
                {loader ? <ApiLoader /> :"Save changes"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserInfoEdit;
