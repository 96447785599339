import React, { useContext, useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { startApiCall } from "../../Global/globalFunctions";
import { creationEndpoint } from "../../api/Endpoints";
import ToasterMessages from "../../Global/ToasterMessages";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import ApiLoader from "../Loader/ApiLoader";
import DataLoader from "../Loader/DataLoader";
import ErrorMessage from "../Error/ErrorMessage";
import { AuthContext } from "../../contextApi/context";

const CreateNewForm = ({ title, showRoleSection, onSubmit, type }) => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [formData, setFormData] = useState({
    fullName: "",
    contactNumber: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    role: location?.state?.type === "admin" ? "admin" : location?.state?.type === "manager" ? "manager" : "employee",
  });
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [staffDetails, setStaffDetails] = useState([]);
  const [staffLoader, setStaffLoader] = useState(false);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "contactNumber"){
      const formattedValue = value.replace(/[^\d]/g, "");
      setFormData({
        ...formData,
        contactNumber: formattedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleReset = () => {
    setloader(false);
    seterrorMessage("");
    setFormData({
      fullName: "",
      contactNumber: "",
      emailAddress: "",
      password: "",
      confirmPassword: "",
      role: "",
    });
  };

  const confirmPasswordCheck = () => {
    return formData?.password === formData?.confirmPassword ? false : true;
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    let { fullName, contactNumber, emailAddress, password, role } = formData;
    const sendData = {
      name: fullName,
      registered_by: "email",
      mobile_country_code: "+91",
      mobile_no: contactNumber,
      role: title === "Add Customer" ? "customer" : role,
      is_active: true,
      email: emailAddress,
      created_by: user?._id,
      password,
    };
    const res = await ApiCall("post", creationEndpoint.addUser, sendData);
    if (res?.success) {
      toast.success(ToasterMessages.ADDMESSAGE);
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    let { fullName, contactNumber, emailAddress, password } = formData;
    const data = {
      staffId: locationData?.staffId,
      name: fullName,
      registered_by: "email",
      mobile_country_code: "+91",
      mobile_no: contactNumber,
      role: staffDetails?.role,
      created_by: user?._id,
      is_active: true,
      email: emailAddress,
      password: password,
    };
    const res = await ApiCall("post", creationEndpoint.editUser, data);
    if (res?.success) {
      handleReset();
      toast.success(ToasterMessages.ADDMESSAGE);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetStaffDetails = async () => {
    startApiCall(null, setStaffLoader);
    const data = {
      staffId: locationData?.staffId,
    };
    const res = await ApiCall("post", creationEndpoint.getStaffById, data);
    if (res?.success) {
      setStaffDetails(res?.result);
      setStaffLoader(false);
    } else {
      setStaffLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.staffId) {
      handleGetStaffDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.staffId) {
      setFormData({
        fullName: staffDetails?.name,
        contactNumber: staffDetails?.mobile_no,
        emailAddress: staffDetails?.email,
        role: staffDetails?.role,
      });
    }
  }, [locationData, staffDetails]);

  return (
    <div className="container pt-3 pb-5">
      <h4 className="text-uppercase text-center">
        {locationData?.staffId && type === "customer"
          ? "Edit Customer"
          : locationData?.staffId
          ? "Edit Staff"
          : title}
      </h4>
      {!staffLoader && (
        <form
          onSubmit={(e) => {
            if (locationData?.staffId) {
              handleEditData(e);
            } else {
              handleAddSubmit(e);
            }
          }}
        >
          <div className="row justify-content-center ">
            <div className="col-md-8">
              <div className="form-group">
                <label>Full name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fullName"
                  required
                  value={formData.fullName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Contact number</label>
                <input
                  type="tel"
                  className="form-control"
                  name="contactNumber"
                  required
                  value={formData.contactNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Email address</label>
                <input
                  type="email"
                  className="form-control"
                  name="emailAddress"
                  required
                  aria-describedby="emailHelp"
                  value={formData.emailAddress}
                  onChange={handleChange}
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              {!locationData?.staffId && (
                <>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      required
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm password</label>
                    <input
                      type="password"
                      className="form-control mb-2"
                      name="confirmPassword"
                      required
                      value={formData.confirmPassword}
                      onChange={handleChange}
                    />
                    {formData.confirmPassword && (
                      <>
                        {confirmPasswordCheck() &&
                        formData?.confirmPassword !== "" ? (
                          <span className="text-danger">
                            Password & Confirm Password Should be same.
                          </span>
                        ) : (
                          <span className="text-success">Password Matched</span>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}

              <div className="form-group">
                {showRoleSection && (
                  <div className="form-group">
                    <label>Role</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        id="admin"
                        value="admin"
                        required
                        checked={formData.role === "admin" || locationData?.type === "admin"}
                        onChange={handleChange}
                        disabled={locationData?.staffId  || locationData?.type}
                      />
                      <label className="form-check-label" htmlFor="admin">
                        Admin
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        required
                        id="manager"
                        value="manager"
                        checked={formData.role === "manager" || locationData?.type === "manager"}
                        onChange={handleChange}
                        disabled={locationData?.staffId || locationData?.type}
                      />
                      <label className="form-check-label" htmlFor="manager">
                        Manager
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        id="employee"
                        value="employee"
                        required
                        checked={formData.role === "employee" || locationData?.type === "employee"}
                        onChange={handleChange}
                        disabled={locationData?.staffId || locationData?.type}
                      />
                      <label className="form-check-label" htmlFor="employee">
                        Employee
                      </label>
                    </div>
                  </div>
                )}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-danger mr-3 px-5"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-success px-5">
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      {staffLoader && <DataLoader />}
    </div>
  );
};

export default CreateNewForm;
